import React from "react";
import { DocumentData, DocumentReference, DocumentSnapshot, FieldValue } from "@atgof-firebase/firebase";
import { ProgressBar } from "react-native-paper";
import { UserContext } from "../../data/userContext";
import {
  AnnotationLayerKey, AnnotationLayerProps, getAnnotationLayer, strokeWidth
} from "../../common/script";
import { Platform, ViewStyle } from "react-native";
import { AnnotationCanvas, AnnotationCanvasLayer, AnnotationCanvasRef } from "./AnnotationCanvas";
import { useRefFor } from "./useRefFor";
import { useDocument } from "../../data/useDocument";
import { TramlineEndKind } from "./Tramlines";

function useAnnotationLayers(
  script: DocumentSnapshot, viewableAnnotationLayers: AnnotationLayerKey[]
) {
  const { user } = React.useContext(UserContext);
  const userAnnotationsDoc =
    useDocument(
      viewableAnnotationLayers.includes('user') ?
        script.ref.collection('userAnnotations').doc(user.id) :
        undefined
    );
  const paAnnotationsDoc =
    useDocument(viewableAnnotationLayers.includes('pa') ?
      script.ref.collection('userAnnotations').doc('pa') :
      undefined
    );
  return React.useMemo<AnnotationLayerProps[] | null>(
    () => {
      const userLayer = getAnnotationLayer(
        'user', userAnnotationsDoc, '#0000ff'
      );
      const paLayer = getAnnotationLayer(
        'pa', paAnnotationsDoc, '#0000ff'
      );
      const scriptLayer = getAnnotationLayer(
        'public', script, '#000000'
      );
      const layers = viewableAnnotationLayers
        .map(k =>
          k === 'user' ? userLayer : (k === 'pa' ? paLayer : scriptLayer)
        );
      if (!layers.every(layer => layer !== undefined)) return null;
      return layers as AnnotationLayerProps[];
    },
    [script, viewableAnnotationLayers, userAnnotationsDoc, paAnnotationsDoc]
  );
}

type PersistenceContext = {
  version: number,
  userRef: DocumentReference,
  scriptRef: DocumentReference,
  annotationLayers: AnnotationLayerProps[] | null
};

export type EditingMode =
  (
    ({ pencil: AnnotationLayerKey } | { eraser: AnnotationLayerKey[] }) &
    { available: AnnotationLayerKey[] }
  ) |
  { adjustScriptLoc: TramlineEndKind } |
  undefined;

async function persistLayers(
  { version, userRef, scriptRef, annotationLayers }: PersistenceContext,
  canvasLayers: AnnotationCanvasLayer[],
  editingMode: EditingMode
) {
  let data: DocumentData = {
    lastModifiedBy: userRef,
    lastModifiedAt: FieldValue.serverTimestamp()
  };
  if (!(editingMode && 'available' in editingMode)) return;
  for (const { key, paths } of canvasLayers) {
    const layer = annotationLayers?.find(layer => layer.key === key);
    if (!(layer && editingMode.available.includes(layer.key))) continue;
    const m: DocumentData = {
      ...data,
      annotations: paths.map(svg => ({
        svg, version, os: Platform.OS
      }))
    };
    if (layer.key === 'public') scriptRef.update(m);
    else {
      const ref = scriptRef.collection('userAnnotations').doc(
        layer.key === 'pa' ? 'pa' : userRef.id
      );
      const doc = await ref.get();
      if (doc.exists) await ref.update(m);
      else if (paths.length) await ref.set({
        deleted: false,
        createdAt: FieldValue.serverTimestamp(),
        createdBy: userRef,
        writtenOnScript: scriptRef,
        ...m
      });
    }
  }
}

export function ScriptAnnotationLayers(
  {
    layoutVersion, viewableAnnotationLayers, editingMode,
    style, script
  }:
    {
      layoutVersion: number;
      viewableAnnotationLayers: AnnotationLayerKey[];
      editingMode: EditingMode;
      style: ViewStyle;
      script: DocumentSnapshot;
    }
) {
  const { user } = React.useContext(UserContext);
  const ref = React.useRef<AnnotationCanvasRef>(null);
  const annotationLayers = useAnnotationLayers(script, viewableAnnotationLayers);
  const persistPathsContextRef = useRefFor({
    version: layoutVersion,
    userRef: user.ref,
    scriptRef: script.ref,
    annotationLayers
  }, [layoutVersion, user, script, annotationLayers]);
  React.useEffect(() => {
    ref.current?.setLayers(
      annotationLayers?.map(
        ({ key, colour, paths }): AnnotationCanvasLayer => ({
          key,
          color: colour,
          paths: paths.map(({ svg }) => svg),
          readonly: !(
            editingMode && (
              ('pencil' in editingMode && key === editingMode.pencil) ||
              ('eraser' in editingMode && editingMode.eraser.includes(key))
            )
          )
        })
      ) ?? []
    );
  }, [editingMode, annotationLayers]);
  React.useEffect(() => {
    ref.current?.setEraseMode(editingMode && 'eraser' in editingMode ? true : false);
    return () => {
      const canvas = ref.current;
      if (!canvas) return;
      const context = persistPathsContextRef.current;
      if (!context) return;
      persistLayers(context, canvas.getLayers(), editingMode);
    };
  }, [editingMode]);
  if (!annotationLayers) return <ProgressBar indeterminate />;
  return (
    <AnnotationCanvas
      ref={ref}
      strokeWidth={strokeWidth}
      style={style}
      width="100%" height="100%"
    />
  );
}
