import { Entity, Spec } from './Spec';
import { PhraseKey } from '../phrases';

export abstract class Displayable<T> extends Spec<T> {
  constructor(
    readonly pluralPhraseKey: PhraseKey,
    readonly singlePhraseKey: PhraseKey
  ) {
    super();
  }

  getKey(entity: Entity<T>): string { return entity.id }
  abstract getLabel(entity: Entity<T>): string;
  labelFromTextInput(s: string): string | undefined { return; }
  getSearchTokens(entity: Entity<T>): string[] {
    return [];
  }
  abstract compare(a: Entity<T>, b: Entity<T>): number;
}
