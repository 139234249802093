import { z } from 'zod';
import { deletable } from "./deletable";
import { PhraseKey } from '../phrases';
import { Displayable } from './displayable';
import { Entity } from './Spec';

const entityWithDisplayNameSchema = deletable.extend({
  displayName: z.string()
});

export type EntityWithDisplayName = z.infer<typeof entityWithDisplayNameSchema>

export class SpecForEntityWithDisplayName<T extends EntityWithDisplayName> extends Displayable<T> {
  constructor(
    readonly _shape: z.ZodRawShape,
    { pluralPhraseKey, singlePhraseKey }: {
      pluralPhraseKey: PhraseKey, singlePhraseKey: PhraseKey
    }
  ) {
    super(
      pluralPhraseKey, singlePhraseKey
    );
  }

  validator() { return entityWithDisplayNameSchema.extend(this._shape) }
  getKey(entity: Entity<T>) { return entity.displayName; }
  getLabel(entity: Entity<T>) { return entity.displayName; }
  compare(a: Entity<T>, b: Entity<T>) {
    return a.displayName.localeCompare(b.displayName);
  }
}
