import { SegmentedButtons } from "react-native-paper";
import { Language, languages } from "../common/phrases";
import { LanguageContext } from "../common/language";
import React from "react";

export default function LanguagePicker({
  language, setLanguage
}: {
  language?: Language,
  setLanguage?: (language: Language) => void
}) {
  const languageContext = React.useContext(LanguageContext);
  return (
    <SegmentedButtons
      value={language ?? languageContext.language}
      onValueChange={setLanguage ?? languageContext.setLanguage}
      buttons={
        languages.map(({ lang, label }) => ({ value: lang, label }))
      }
      style={{ maxWidth: 200 }}
    />
  );
};
