import { LanguageContext } from "@atgof-common/language";
import { PhraseFunction, PhraseKey } from "@atgof-common/phrases";
import React from "react";
import {
  ActivityIndicator, TouchableOpacity, View, ViewStyle
} from "react-native";
import { Button, IconButton, Modal, Portal, Text, useTheme } from "react-native-paper";
import { ProgressEntry } from "./ProgressEntry";
import { UserContext } from "../data/userContext";
import {
  storageManager, useDownloads, useHasPendingWrites, useUploads
} from "../data/backend";
import { useNetInfo } from '@react-native-community/netinfo';

function FileProgressEntry(
  { phraseK, count, isActive }: {
    phraseK: PhraseKey; count: number; isActive: boolean
  }
) {
  const { ph } = React.useContext(LanguageContext);
  if (!count) return null;
  return (
    <ProgressEntry isActive={isActive}>
      <View style={{ flexDirection: 'row' }}>
        <Text>{ph(phraseK) as string} </Text>
        <Text style={{ fontWeight: 'bold' }}>{count} </Text>
        <Text>
          {(ph('count-file') as PhraseFunction)({ count }) +
            (isActive ? '…' : '')}
        </Text>
      </View>
    </ProgressEntry>
  );
}

export type SyncStatus = {
  noInternet: boolean;
  hasPendingWrites: boolean;
  active: boolean;
  failedUploadsCount: number;
  activeUploadCount: number;
  downloadCount: number;
  queuedCount: number;
  retryFailedUploads: () => void
}

export function useSyncStatus(): SyncStatus {
  const { uploader } = storageManager;
  const { user } = React.useContext(UserContext);
  const { isInternetReachable } = useNetInfo();
  const hasPendingWrites = useHasPendingWrites();
  const uploads = useUploads();
  const { downloadCount, queuedCount } = useDownloads();
  const failedUploads = uploads.filter(status => status.error);
  const activeUploadCount = uploads.length - failedUploads.length;
  const active =
    (hasPendingWrites || activeUploadCount || downloadCount || queuedCount) ?
      true : false;
  const retryFailedUploads = React.useCallback(
    () => {
      if (failedUploads.length) uploader.retryUploads(user, failedUploads, true);
    },
    [failedUploads, user]
  );
  return {
    noInternet: !isInternetReachable,
    hasPendingWrites,
    active,
    failedUploadsCount: failedUploads.length,
    activeUploadCount,
    downloadCount,
    queuedCount,
    retryFailedUploads
  };
}

export function SyncModal(
  {
    visible, onDismiss, style
  }: {
    visible: boolean;
    onDismiss: () => void;
    style: ViewStyle
  }
) {
  const { colors, roundness } = useTheme();
  const { ph } = React.useContext(LanguageContext);
  const {
    noInternet, hasPendingWrites, active, failedUploadsCount, activeUploadCount,
    downloadCount, queuedCount, retryFailedUploads
  } = useSyncStatus();
  const showActivity = !noInternet;
  return (
    <Portal>
      <Modal
        visible={visible && (noInternet || active || failedUploadsCount > 0)}
        onDismiss={onDismiss}
        style={{ justifyContent: "flex-start", ...style }}
        contentContainerStyle={{
          borderRadius: roundness,
          backgroundColor: colors.background,
          alignSelf: "flex-end",
        }}
      >
        <View style={{ padding: 16 }}>
          <Text variant="titleMedium">
            {ph(
              noInternet ? 'no-internet-connection' : 'synchronising'
            ) as string}
          </Text>
          <View style={{ gap: 8, marginTop: 8 }}>
            <ProgressEntry isActive={showActivity}>
              {hasPendingWrites &&
                <Text>
                  {ph(noInternet ?
                    'waiting-to-synchronise' : 'synchronising-your-changes'
                  ) as string}
                </Text>
              }
            </ProgressEntry>
            <FileProgressEntry
              isActive={showActivity}
              phraseK={noInternet ? 'waiting-to-upload' : 'uploading'}
              count={activeUploadCount + (noInternet ? failedUploadsCount : 0)}
            />
            <FileProgressEntry
              isActive={showActivity}
              phraseK={noInternet ? 'waiting-to-download' : 'downloading'}
              count={downloadCount + queuedCount} />
            {noInternet || !failedUploadsCount ? null :
              <View style={{ gap: 4 }}>
                <FileProgressEntry
                  isActive={false}
                  phraseK="failed-to-upload"
                  count={failedUploadsCount}
                />
                <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
                  <Button onPress={retryFailedUploads}>
                    {ph('retry') as string}
                  </Button>
                </View>
              </View>}
          </View>
        </View>
      </Modal>
    </Portal>
  );
}

export function SyncIndicator({ onPress }: { onPress: () => void }) {
  const { noInternet, active, failedUploadsCount } = useSyncStatus();
  if (!(noInternet || active || failedUploadsCount)) return null;
  return (
    <React.Fragment>
      {
        noInternet ?
          <IconButton onPress={onPress} icon="wifi-alert" /> :
          (
            !active && failedUploadsCount ?
              <IconButton onPress={onPress} icon="alert" /> :
              <TouchableOpacity onPress={onPress}>
                <ActivityIndicator />
              </TouchableOpacity>
          )
      }
    </React.Fragment>
  );
}
