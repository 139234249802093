import { useNavigation } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React from "react";
import MembershipDetailPage, { NotificationPrefs } from "../components/pages/MembershipDetailPage";
import { useProject } from "../data/useProject";
import { UserContext } from "../data/userContext";
import { RootStackParamList } from "../types";
import { DarkCompatibleColour, MembershipRoles } from "../common/model/project/membership";
import { useTransact } from "../data/backend";

export default function NewInvitationScreen({ }: NativeStackScreenProps<RootStackParamList, 'newInvitation'>) {
  const { goBack } = useNavigation();
  const project = useProject();
  const { user } = React.useContext(UserContext);
  const { latestAction, transact } = useTransact();
  const [inProgress, setInProgress] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [inkColour, setInkColour] = React.useState<DarkCompatibleColour>();
  const [notifications, setNotifications] = React.useState<NotificationPrefs>();
  const [roles, setRoles] = React.useState({} as MembershipRoles);
  function submit() {
    setInProgress(true);
    transact(
      'create-invitation', () =>
      project!.collection("invitations").doc(email).set(
        {
          email, roles, lastModifiedBy: user.ref,
          ...(inkColour ? { inkColour } : {}),
          ...(notifications ? { notifications } : {})
        })
    ).then(goBack).finally(() => setInProgress(false));
  }
  const onSubmit = email.length > 0 && Object.keys(roles).length > 0 ? submit : undefined;
  return (
    <MembershipDetailPage
      isInvitation isNew
      email={email} onEmailChanged={setEmail}
      inkColour={inkColour} onInkColourChanged={setInkColour}
      notificationPrefs={notifications}
      onNotificationPrefsChanged={setNotifications}
      roles={roles} onRolesChanged={setRoles}
      onSubmit={onSubmit} submitInProgress={inProgress}
      latestAction={latestAction}
    />
  );
}
