import { z } from 'zod';
import { deletable } from '../deletable';
import { emailAddressSchema } from '../contact/emailAddress';
import { userReference } from '../user';
import { SHEET_CATEGORIES } from '../../sheet';

export const MEMBERSHIP_CATEGORIES = [
  ...SHEET_CATEGORIES, 'postProduction', 'admin', 'cast'
] as const;

export type MembershipCategory = typeof MEMBERSHIP_CATEGORIES[number]

const roleSchema = z.object({
  user: z.boolean().optional(),
  admin: z.boolean().optional(),
  characters: z.array(z.string()).optional() // TODO This is just for the 'cast' role
});

const rolesSchema = z.record(z.enum(MEMBERSHIP_CATEGORIES), roleSchema);

export type MembershipRoles = z.infer<typeof rolesSchema>;

const darkCompatibleColourSchema = z.object({
  dark: z.string(),
  light: z.string()
});

const colourSchema = z.union([
  z.string(),
  darkCompatibleColourSchema
]);

export type DarkCompatibleColour = z.infer<typeof darkCompatibleColourSchema>;
export type Colour = z.infer<typeof colourSchema>;

export function normaliseColour(colour: Colour): DarkCompatibleColour {
  return typeof colour === 'string' ? { dark: colour, light: colour } : colour;
}

export const membershipSchema = deletable.extend({
  user: userReference,
  displayName: z.string(),
  inkColour: colourSchema.optional(),
  email: emailAddressSchema,
  lastModifiedBy: userReference,
  roles: rolesSchema,
});
