import {
  DocumentReference, DocumentSnapshot
} from '@atgof-firebase/firebase';
import {
  SceneScript, ScriptElement, onLatestScripts, onScriptElements
} from '../common/script';
import { useSnapshot } from './useSnapshot';
import { getScript } from './backend';

export function useLatestScripts(sceneRefs: DocumentReference[] | undefined) {
  return useSnapshot<SceneScript[] | undefined>(
    sceneRefs ? (onNext => onLatestScripts(sceneRefs, onNext)) : undefined,
    [sceneRefs]
  );
}

export function useScriptElements(
  script: DocumentSnapshot | undefined,
  sceneRef: DocumentReference
) {
  const scriptPath: string | undefined = script?.get('scriptPath');
  return useSnapshot<ScriptElement[]>(
    onNext => onScriptElements(
      scriptPath,
      storagePath => getScript(sceneRef, storagePath),
      onNext
    ),
    [scriptPath, sceneRef.path]
  );
}
