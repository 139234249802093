import { DocumentReference } from "@atgof-firebase/firebase";
import Handlers from "./handlers";
import { getScenePath } from "../../common/scene";
import _ from 'lodash';
import { getStorageURL } from "../../config/firebase";
import { Unsubscribe } from "@atgof-common/onSnapshot";
import { episodePathForLook, subjectRefForLook } from "@atgof-common/look";
import { kindOfSubject, projectRefForSubject } from "@atgof-common/subject";
import { ReferenceData } from "@atgof-common/image";
import { Uploader } from "./uploader";

export const downloadKinds = ['scripts', 'images'] as const;

export type DownloadKind = typeof downloadKinds[number];

type DownloadStatus = {
  path: string;
  kind: DownloadKind;
  referencePath: string | undefined;
  priority: number;
  failureCount: number;
  lastAttemptAt?: Date
  remoteURI: string;
  error?: any
};

type Downloads = { [path: string]: DownloadStatus }

type QueuedDownload = Omit<DownloadStatus, 'remoteURI'>

export const scenesKey = 'scenes';
export const looksKey = 'looks';

export type Reference = {
  cachedURI: string;
  cachedDate: string
};

export type ReferenceWithKey = Reference & { referenceKey: string };

type SheetId = string

export function referencePathForScene(
  sceneRef: DocumentReference | undefined,
  suffix: SheetId | 'scripts' | undefined
) {
  const { project, season, episode, scene } = getScenePath(sceneRef);
  if (!(project && season && episode && scene && suffix)) return;
  return [scenesKey, project, season, episode, scene, suffix].join('/');
}

export function referencePathForLook(
  lookRef: DocumentReference | undefined
) {
  if (!lookRef) return;
  const subjectRef = subjectRefForLook(lookRef);
  if (!subjectRef) return;
  const projectRef = projectRefForSubject(subjectRef);
  if (!projectRef) return;
  const episodePath = episodePathForLook(lookRef);
  return [
    looksKey,
    projectRef.id,
    kindOfSubject(subjectRef),
    subjectRef.id,
    ...(episodePath ? [episodePath.season, episodePath.episode] : []),
    lookRef.id
  ].join('/');
}

export type Downloaded = {
  cachedURI: string;
  referenceKey?: string;
  cachedDate?: string
} & ReferenceData;

function dataFromReference(referenceKey: string | undefined): ReferenceData {
  const parts = referenceKey?.split('/');
  if (!parts?.length) return {};
  const kind = parts[0];
  return {
    kind,
    project: parts[1],
    ...(kind === scenesKey ?
      {
        season: parts[2],
        episode: parts[3],
        scene: parts[4],
        ...(
          parts[5] === 'scripts' ?
            { isScript: true } : { sheetId: parts[5] }
        )
      }
      : {
        subjectKind: parts[2],
        subjectId: parts[3],
        ...(parts.length > 5 ?
          {
            season: parts[4],
            episode: parts[5],
            lookId: parts[6]
          } : {
            lookId: parts[4]
          })
      })
  };
}

type QueueItemInProgress = {
  item: QueuedDownload;
  controller: AbortController
}

function compareQueuedDownloads(a: QueuedDownload, b: QueuedDownload) {
  if (a.failureCount > 2 && b.failureCount <= 2) return -1;
  if (a.failureCount <= 2 && b.failureCount > 2) return 1;
  if (a.priority < b.priority) return -1;
  if (a.priority > b.priority) return 1;
  if (a.failureCount < b.failureCount) return -1;
  if (a.failureCount > b.failureCount) return 1;
  return 0;
}

export type DownloadsInfo = {
  downloadCount: number;
  queuedCount: number
}

export abstract class Downloader {
  protected downloads: Downloads;
  private queue: QueuedDownload[];
  protected downloadingQueuedItems: boolean = false;
  protected handlers = new Handlers<DownloadsInfo>("download");
  protected queueItemInProgress: QueueItemInProgress | undefined = undefined;

  protected constructor() {
    this.downloads = {};
    this.queue = [];
  }

  protected enqueue(item: QueuedDownload) {
    if (item.path in this.downloads) {
      this.downloads[item.path].priority = Math.max(
        this.downloads[item.path].priority,
        item.priority
      );
      return;
    }
    const i = this.queue.findIndex(({ path }) => path === item.path);
    if (
      this.queueItemInProgress?.item &&
      this.queueItemInProgress.item.priority < item.priority
    ) {
      this.queueItemInProgress.controller.abort();
    }
    if (i === -1) this.queue.push(item);
    else {
      this.queue[i].priority = Math.max(
        this.queue[i].priority,
        item.priority
      );
    }
    this.queue.sort(compareQueuedDownloads);
    this.startQueueIfNeeded();
  }

  protected startQueueIfNeeded() {
    if (this.downloadingQueuedItems) return;
    this.downloadingQueuedItems = true;
    setTimeout(() => {
      const item = this.queue.pop();
      if (!item) {
        this.downloadingQueuedItems = false;
        return;
      }
      const controller = new AbortController();
      this.queueItemInProgress = {
        item,
        controller
      };
      const { path, kind, referencePath, priority } = item;
      const inProgressPriority = Math.max(
        ...Object.values(this.downloads).map(({ priority }) => priority)
      );
      if (inProgressPriority > priority) {
        this.queueItemInProgress = undefined;
        this.downloadingQueuedItems = false;
        this.enqueue(item);
      }
      else {
        this.getURI(path, kind, referencePath, {
          downloadImages: true,
          signal: controller.signal
        }).finally(
          () => {
            this.queueItemInProgress = undefined;
            this.downloadingQueuedItems = false;
            this.startQueueIfNeeded();
          }
        );
      }
      // TODO Handle failures with a back-off retry. These won't be thrown to here by getURI yet though
    }, 1000);
  }

  public prefetch(
    kind: DownloadKind,
    referencePath: string | undefined,
    paths: (string | undefined)[] | undefined,
    uploader: Uploader
  ): Unsubscribe {
    let interrupted = false;
    const self = this;
    (async function() {
      for (const path of (paths ?? [])) {
        if (interrupted) break;
        if (!path) continue;
        const remoteURI = await getStorageURL(path);
        if (interrupted) break;
        const shouldPrefetch = !(
          await self.getCachedURI(remoteURI, path, kind) ||
          await uploader.getUriForStoragePath(path)
        );
        if (interrupted) break;
        if (!shouldPrefetch) continue;
        self.enqueue({ path, kind, referencePath, priority: 0, failureCount: 0 });
      }
    })();
    return () => { interrupted = true; };
  }

  protected async tryGetCachedURI(
    remoteURI: string, path: string, kind: DownloadKind
  ) {
    try {
      return await this.getCachedURI(remoteURI, path, kind);
    }
    catch (err) { console.error("Error in cache lookup", err); }
  }

  public async getURI(
    path: string, kind: DownloadKind,
    referencePath: string | undefined,
    {
      downloadImages = false,
      priority = 1,
      failureCount = 0,
      signal
    }: {
      downloadImages?: boolean;
      priority?: number;
      failureCount?: number;
      signal?: AbortSignal
    } = {}
  ) {
    const remoteURI = await getStorageURL(path);
    let cachedURI = await this.tryGetCachedURI(remoteURI, path, kind);
    const shouldDownload = !cachedURI && (downloadImages || kind !== 'images');
    this.registerDownload({
      path, remoteURI, kind, priority, referencePath, failureCount
    }, shouldDownload);
    if (shouldDownload) {
      try {
        const { status, uri } = await this.doDownload(
          remoteURI, path, kind, signal
        );
        if (status != 0 && (status < 200 || status >= 300)) {
          throw new Error(`Status ${status}`);
        }
        cachedURI = uri;
      }
      catch (err) {
        this.registerFailure(path, err, shouldDownload);
      }
    }
    if (cachedURI) {
      this.registerAsCached(referencePath, path, cachedURI)
        .finally(() =>
          this.registerCompletion(path, shouldDownload)
        );
      return cachedURI;
    }
    return remoteURI;
  }

  public async registerAsCached(
    referencePath: string | undefined,
    path: string,
    cachedURI: string
  ) {
    if (!referencePath) return
    const i = path.lastIndexOf('/');
    const referenceKey = referencePath + '/' + path.substring(i + 1);
    await this.putReference(referenceKey, {
      cachedURI,
      cachedDate: new Date().toISOString()
    }).catch(err => console.error(
      `Unable to create reference for ${path} at ${referencePath} `, err
    ));
  }

  public async retrieveAsString(
    path: string, kind: DownloadKind,
    referencePath: string | undefined
  ) {
    return this.readAsString(await this.getURI(path, kind, referencePath));
  }

  private downloadsInfo(): DownloadsInfo {
    return {
      downloadCount: Object.keys(this.downloads).length,
      queuedCount: this.queue.length
    };
  }

  public onDownloads(handler: (info: DownloadsInfo) => void) {
    return this.handlers.add(handler, this.downloadsInfo());
  }

  public async allDownloaded(): Promise<Downloaded[]> {
    const entries = await this.getAllReferenceEntries();
    const cacheKeys = await this.getAllCacheKeys();
    return cacheKeys.map(cacheKey => {
      const entry = entries.find(entry => entry.cachedURI === cacheKey);
      return {
        cacheKey,
        cachedURI: cacheKey,
        ...(entry ?? {}),
        ...dataFromReference(entry?.referenceKey)
      };
    });
  }

  public abstract getFreeStorageEstimate(): Promise<number | undefined>;

  protected abstract getCachedURI(
    remoteURI: string, path: string, kind: DownloadKind
  ): Promise<string | undefined>;

  protected abstract doDownload(
    remoteURI: string,
    path: string,
    kind: DownloadKind,
    signal: AbortSignal | undefined
  ): Promise<{ status: number, uri: string }>;

  protected abstract readAsString(uri: string): Promise<string>;

  protected abstract putReference(
    referenceKey: string, reference: Reference
  ): Promise<void>;

  protected abstract getAllCacheKeys(): Promise<string[]>;

  protected abstract getAllReferenceEntries(): Promise<ReferenceWithKey[]>;

  protected registerDownload(download: DownloadStatus, notify: boolean) {
    this.downloads[download.path] = download;
    if (notify) {
      this.handlers.handle(this.downloadsInfo());
    }
  }

  public registerCompletion(path: string, notify: boolean = true) {
    delete this.downloads[path];
    if (notify) {
      this.handlers.handle(this.downloadsInfo());
    }
  }

  public registerFailure(path: string, error: any, notify: boolean = true) {
    if (path in this.downloads) {
      const entry = this.downloads[path];
      this.enqueue({
        ...entry,
        failureCount: entry.failureCount + 1,
        lastAttemptAt: new Date(),
        error
      });
      delete this.downloads[path];
    }
    if (notify) {
      this.handlers.handle(this.downloadsInfo());
    }
  }
}
