import { LanguageContext } from "../common/language";
import * as Linking from "expo-linking";
import React from "react";
import { Banner, Text } from "react-native-paper";
import { Platform, View } from "react-native";
import * as Application from "expo-application";
import { useCollectionReference } from "../data/firestore";
import { storageManager } from "../data/backend";

export default function SoftwareUpdatePrompt() {
  const { ph } = React.useContext(LanguageContext);
  const [updateAvailable, setUpdateAvailable] = React.useState<boolean>(false);
  React.useEffect(
    () => storageManager.onUpdateAvailable(setUpdateAvailable),
    []
  );
  const releases = useCollectionReference('releases');
  const [url, setUrl] = React.useState<string>();
  React.useEffect(
    () => {
      if (Platform.OS !== 'android') {
        setUrl(undefined);
        return;
      }
      try {
        return releases
          .where('platform', '==', Platform.OS)
          .where('version', '>', parseInt(Application.nativeBuildVersion!))
          .orderBy('version', 'desc').limit(1)
          .onSnapshot(snapshot => {
            if (snapshot.empty) setUrl(undefined);
            else setUrl(snapshot.docs[0].get('url'));
          });
      }
      catch (err) {
        console.error("Unable to determine version", err);
        setUrl(undefined);
      }
    },
    [releases]);
  const hasUrl = url !== undefined;
  const actions = hasUrl ?
    [{
      label: ph('download') as string,
      onPress: () => Linking.openURL(url!)
        .catch(err => console.error("Unable to open link", err))
    }] : [];
  return (
    <Banner
      style={{}}
      visible={hasUrl || updateAvailable} icon="download-circle-outline"
      actions={actions}
    >
      <View style={{ gap: 8 }}>
        <Text variant="bodyLarge">{ph('software-update-prompt') as string}</Text>
        {hasUrl ? null :
          <Text variant="bodyMedium">{ph('reopen-app-prompt') as string}</Text>}
      </View>
    </Banner>
  );
}
