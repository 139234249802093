import { DocumentSnapshot } from "@atgof-firebase/firebase";
import React, { ReactNode } from "react";
import { LanguageContext } from "../common/language";
import FieldsRow from "./fields/FieldsRow";
import { View } from "react-native";
import FixtureLabel from "../common/FixtureLabel";
import { isCharacterCategory, SheetCategory } from "../common/sheet";
import { LooksSelector } from "./LooksSelector";
import { useFieldSpecs } from "../data/useFieldSpecs";
import { UserInkColour } from "@atgof-common/membership";
import ErrorBoundary from "./ErrorBoundary";


export function SheetSection({ title, children, small }: { title: string; children: ReactNode; small?: boolean }) {
  if (!children) return null;
  return (
    <View style={{ marginBottom: small ? 0 : 16 }}>
      <FixtureLabel style={{ marginBottom: small ? 0 : 4, fontSize: small ? 16 : 20 }} text={title} />
      {children}
    </View>
  );
}

export function SheetSections(
  { doc, category, readonly, docIsLook, inkColours }: {
    doc: DocumentSnapshot | undefined;
    category: SheetCategory | undefined;
    readonly?: boolean;
    docIsLook?: boolean;
    inkColours?: UserInkColour[]
  }
) {
  const { ph } = React.useContext(LanguageContext);
  const fieldSpecs = useFieldSpecs();
  if (!(category && doc)) return null;
  const sections = fieldSpecs[category];
  const sectionKs = Object.keys(sections || {});
  const concise = readonly;
  return (
    <View style={{ gap: 8 }}>
      {(category === 'arts' || isCharacterCategory(category)) && doc &&
        <View style={{ margin: 4 }}>
          <ErrorBoundary>
            <LooksSelector doc={doc} readonly={readonly} docIsLook={docIsLook} />
          </ErrorBoundary>
        </View>}
      {concise && sectionKs.length == 1 ?
        <FieldsRow
          category={category}
          fields={sections![sectionKs[0]]}
          doc={doc}
          readonly={readonly}
          inkColours={inkColours}
        /> :
        sectionKs.map(section => (
          <SheetSection key={section} title={ph(section) as string} small={concise}>
            <FieldsRow category={category}
              fields={sections![section]}
              doc={doc}
              readonly={readonly}
              inkColours={inkColours}
            />
          </SheetSection>
        ))}
    </View>
  );
}
