import React from "react";
import { DocumentSnapshot } from "@atgof-firebase/firebase";
import { Take } from "../../common/model/project/sheet/take";
import { SceneScript } from "../../common/script";
import { useProject } from "../../data/useProject";
import { useSnapshot } from "../../data/useSnapshot";
import { TramlineProps, onAllTramlines, tramlineForTake } from "../../common/take";

export type GetPosResult = { top: number; height: number; sceneId: string; paragraphIndex: number } | undefined;
export type GetPos = (index: number) => GetPosResult;

function useAllTramlinesForScripts(scripts: SceneScript[] | undefined) {
  const project = useProject();
  return useSnapshot<TramlineProps[] | undefined>(
    onNext => onAllTramlines(project, scripts, onNext),
    [project, scripts]
  );
}

const xSpacing = 60;
export const xOffset = 60;

export function useTramlines(
  scripts: SceneScript[] | undefined,
  hasTake: boolean,
  take: DocumentSnapshot | undefined,
  sheet: DocumentSnapshot | undefined,
  getPos: GetPos,
  canModify: boolean
) {
  const allTramlines = useAllTramlinesForScripts(hasTake ? undefined : scripts);
  const tramlines = React.useMemo<TramlineProps[] | undefined>(
    () => take ?
      (scripts && take.get('startScriptLoc') !== undefined ?
        [tramlineForTake(take, sheet, scripts)] : undefined) :
      allTramlines,
    [take, sheet, scripts, allTramlines]
  );
  React.useEffect(
    () => {
      let nextX: number | undefined;
      for (const tramline of (tramlines ?? [])) {
        const { takeRef, startScriptLoc, endScriptLoc, xPos } = tramline;
        let changes: Pick<Take, 'startScriptLoc' | 'endScriptLoc' | 'xPos'> = {};
        if (startScriptLoc !== undefined &&
          (typeof startScriptLoc === 'number' || startScriptLoc.yPos == null)
        ) {
          const paragraphIndex = typeof startScriptLoc === 'number' ?
            startScriptLoc : startScriptLoc.paragraphIndex;
          const pos = getPos(paragraphIndex);
          if (pos) {
            changes.startScriptLoc = {
              sceneId: pos.sceneId,
              paragraphIndex: pos.paragraphIndex,
              yPos: pos.top
            };
          }
        }
        if (endScriptLoc !== undefined &&
          (typeof endScriptLoc === 'number' || endScriptLoc.yPos == null)
        ) {
          const paragraphIndex = typeof endScriptLoc === 'number' ?
            endScriptLoc : endScriptLoc.paragraphIndex;
          const pos = getPos(paragraphIndex);
          if (pos) {
            changes.endScriptLoc = {
              sceneId: pos.sceneId,
              paragraphIndex: pos.paragraphIndex,
              yPos: pos.top + pos.height
            };
          }
        }
        if (allTramlines && !hasTake && xPos === undefined) {
          if (nextX === undefined) {
            nextX =
              Math.max(...[xOffset, ...allTramlines.map(t => t.xPos ?? 0)]) +
              xSpacing;
          }
          changes.xPos = nextX;
          nextX += xSpacing;
        }
        if (canModify && Object.keys(changes).length) takeRef.update(changes);
      }
    },
    [allTramlines, hasTake, tramlines, getPos]
  );
  return tramlines;
}
