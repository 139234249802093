import { DocumentReference, DocumentSnapshot, Timestamp } from "@atgof-firebase/types";
import { HasScripts } from "./has-scripts";
import { compareSceneIds } from "./scene";

export type EpisodePath = {
  project?: string;
  season?: string;
  episode?: string
}

export function parseEpisodePath(path: string | undefined): EpisodePath {
  // path is of the form projects/PROJECT_ID/seasons/SEASON_ID/episodes/EPISODE_ID
  const parts = path?.split('/');
  return parts && parts?.length >= 6 ? {
    project: parts[1],
    season: parts[3],
    episode: parts[5]
  } : {};
}

export interface Episode extends HasScripts {
  ref: DocumentReference;
  sceneCount?: number;
  txDate?: Timestamp;
  director?: string;
  writer?: string;
  isComplete?: boolean;
  pendingActionsForScenes?: DocumentReference[]
}

export function toEpisode(doc: DocumentSnapshot) {
  return {
    ...doc.data(),
    id: doc.id,
    ref: doc.ref,
    path: doc.ref.path // TODO Refactor with toScene
  } as Episode;
}

export function episodeForScene(sceneRef: DocumentReference | null | undefined) {
  return sceneRef?.parent.parent;
}

export function getEpisodePath(episode: DocumentReference | null | undefined) {
  return parseEpisodePath(episode?.path);
}

export function perEpisodeRef(parent: DocumentReference | undefined, episodePath: EpisodePath) {
  const { season, episode } = episodePath;
  if ((season && episode)) {
    return parent?.collection('seasons').doc(season).collection('episodes').doc(episode);
  }
  return;
}

export const compareEpisodeIds = compareSceneIds;
