import { DocumentSnapshot, DocumentReference, FieldValue, QuerySnapshot } from "@atgof-firebase/firebase";
import { LanguageContext } from "../../common/language";
import { defaultValue, Field, keysForField, normaliseAttrs } from "../../common/helpers/fields";
import DateField from "./DateField";
import FixtureLabel from "../../common/FixtureLabel";
import ReferenceField from "./ReferenceField";
import Select from "../Select";
import { useNavigation } from "@react-navigation/native";
import { PhraseKey } from "../../common/phrases";
import { ActivityIndicator, Platform, View, ViewStyle } from "react-native";
import { TextInput, TextInputEvent, textForEvent } from "./TextInput";
import { UserContext } from "../../data/userContext";
import { AnnotatedFields, AnnotatedText, getAnnotatedText, removeSameUserDeletions } from "../../data/transactions";
import { default as _ } from "lodash";
import { Card, Checkbox, IconButton, Switch, Text, useTheme } from "react-native-paper";
import React from "react";
import { UserInkColour } from "@atgof-common/membership";
import { UndoBanner } from "../UndoBanner";
import { DeleteButton } from "../DeleteButton";

type BaseParams = {
  doc: DocumentSnapshot | undefined;
  annotatedFields?: AnnotatedFields | null | undefined;
  create?: (() => Promise<DocumentReference>) | undefined;
  readonly?: boolean;
  category?: string | undefined;
  inkColours?: UserInkColour[]
}

type FieldComponentParams = BaseParams & ValueGetterAndSetter & {
  field: Field;
  cell?: React.FunctionComponent<any>
}

type ValueGetterAndSetter = {
  getValue: (k: string | undefined) => any;
  setValue: (k: string, v: any) => void;
  keypath: any[]
}

type ValueAndUpdate = {
  v: any;
  update: ((v: any) => void) | undefined;
}

function valueGetter(doc: DocumentSnapshot) {
  return function getValue(k: string | undefined) { return k && doc.get(k); };
}

function valueSetter(user: DocumentSnapshot, doc: DocumentSnapshot) {
  return function setValue(k: string, v: any) {
    doc.ref.update({
      [k]: v === undefined ? FieldValue.delete() : v,
      lastModifiedBy: user.ref,
      lastModifiedAt: FieldValue.serverTimestamp()
    });
  };
}

function getNoValue(_: string | undefined) { }

function creatingValueSetter(user: DocumentSnapshot, create: (() => Promise<DocumentReference>) | undefined) {
  if (!create) return () => { };
  return function setValue(k: string, v: any) {
    create().then(ref => ref.update({ // TODO This is inefficient compared to getting a creation map and doing it in one
      [k]: v === undefined ? FieldValue.delete() : v,
      lastModifiedBy: user.ref,
      lastModifiedAt: FieldValue.serverTimestamp()
    }));
  };
}

function AnnotatedField(
  { plain, annotated, includeDeletions = false }:
    { plain: any; annotated: AnnotatedText | undefined; includeDeletions: boolean }
) {
  const withoutDeletions = annotated?.filter(({ deletedBy }) => !deletedBy);
  const reconstructedPlain = withoutDeletions?.map(({ text }) => text).join('');
  if (reconstructedPlain !== plain)
    console.error("Reconstructed", reconstructedPlain, "differs from", plain);
  return (
    // TODO Make this native-compatible, rather than just web 
    Platform.OS === 'web' && reconstructedPlain === plain ?
      <div>
        {removeSameUserDeletions(includeDeletions ? annotated : withoutDeletions)?.map(
          ({ createdBy, deletedBy, text }, i) =>
            <span key={i}
              style={{
                color: deletedBy || createdBy,
                whiteSpace: 'pre-wrap',
                textDecoration: deletedBy ? 'line-through' : undefined
              }} >
              {text}
            </span>
        )
        }
      </div> :
      <Text>{plain}</Text> // TODO Better warning here
  );
}

function FieldComponent({ v, getValue, setValue, field, doc, ...props }
  : FieldComponentParams & ValueAndUpdate) {
  const { ph } = React.useContext(LanguageContext);
  const { dark, colors } = useTheme();
  const { readonly } = props;
  const kind = field.kind || 'text';
  const update = props.update ?
    (
      field.onChange ?
        (newV: any) => { props.update!(newV); field.onChange!(newV, v, doc); } :
        props.update
    ) : undefined;
  if (kind === 'text' || kind === 'shortText' || kind === 'longText' || kind === 'paragraph') {
    const isMultiline = kind === 'paragraph';
    function updateText(evt: TextInputEvent) {
      const text = textForEvent(evt);
      if (update && typeof text === 'string') update(text);
    }
    if (readonly) {
      const maxWidth = props.cell ? 200 : undefined;
      const annotatedText = getAnnotatedText(props.annotatedFields, props.keypath);
      const { inkColours } = props;
      const lastModifiedByUserId: string | undefined =
        inkColours && props.keypath.length ?
          doc?.get(`latestModifications.${props.keypath[0]}.u`) :
          undefined;
      const inkColour = inkColours?.find(
        ({ userId }) => userId === lastModifiedByUserId
      )?.inkColour;
      return (
        annotatedText ?
          <View style={{ maxWidth }}>
            <AnnotatedField plain={v} annotated={annotatedText} includeDeletions={false} />
          </View> :
          <Text style={{
            maxWidth,
            color: inkColour ? (dark ? inkColour.dark : inkColour.light) : colors.onBackground
          }}>
            {v?.trim()}
          </Text>
      );
    }
    return <TextInput
      style={{
        ...(props.cell ? { fontSize: 14 } : (
          Platform.OS === 'web' ? {
            fontSize: 16, padding: 5
          } : {}
        )),
        fontSize: props.cell ? 14 : 16,
        minWidth: kind === 'shortText' ? 100 : (props.cell ? 200 : 338),
      }}
      {...(
        update ?
          { defaultValue: v, onEndEditing: updateText, onBlur: updateText } :
          { value: v, editable: false }
      )}
      {...(
        isMultiline ?
          { multiline: true, textAlignVertical: 'top' } :
          {}
      )}
    />;
  }
  if (kind === 'date') return update ? <DateField value={v} onChange={update} /> : null;
  if ('reference' in kind) {
    return update ? (
      <ReferenceField {...props} item={v} onItemChange={update}
        collection={kind.reference.collection}
      />
    ) : null;
  }
  if ('composite' in kind) {
    return (
      <Card mode="outlined">
        <Card.Content>
          <FieldsRow_ {...props} getValue={getValue} setValue={setValue}
            fields={kind.composite} doc={doc} />
        </Card.Content>
      </Card>
    );
  }
  if ('select' in kind) {
    return update ? (
      readonly ?
        <Text>{v && ph(v.labelKey || v.key)}</Text> :
        <Select entries={normaliseAttrs(kind.select)}
          entryKey={({ key }) => key as PhraseKey}
          entryLabel={({ key, labelKey }) => ph((labelKey || key) as PhraseKey) as string}
          selectedEntry={v}
          onEntryChange={update}
        />
    ) : null;
  }
  if ('stringSelect' in kind) {
    return update ? (
      <Select entries={kind.stringSelect}
        entryKey={s => s}
        entryLabel={s => s}
        selectedEntry={v}
        onEntryChange={update}
        autoselect
      />
    ) : null;
  }
  if ('flags' in kind) {
    return (
      <View style={{ flexDirection: 'row', gap: 8, flexWrap: 'wrap', alignSelf: "center" }}>
        {normaliseAttrs(kind.flags).map(({ key, labelKey, labelSuffix }, i) => {
          const val = key && getValue(key);
          const label = labelKey === '' ? '' : // TODO Not great
            ph((labelKey || key) as PhraseKey) as string;
          return (
            label.length ?
              <Checkbox.Item
                key={i} label={label}
                status={key && val ? 'checked' : 'unchecked'}
                disabled={!key}
                onPress={key ? (() => setValue(key, !val)) : undefined}
              /> :
              <Switch key={i} value={key && val} style={{ alignSelf: "center" }}
                onValueChange={key ? (v => setValue(key, v)) : undefined} />
          );
        })}
      </View>
    );
  }
  return null;
}

function MultiComponent({ field, v, update, doc, ...params }: FieldComponentParams & ValueAndUpdate) {
  const { readonly } = params;
  const navigation = useNavigation();
  const { user } = React.useContext(UserContext);
  const spec = field.multi;
  const isCollection = spec?.collection !== undefined;
  const [toRestore, setToRestore] = React.useState<DocumentSnapshot>();
  const undo = toRestore ?
    () => {
      valueSetter(user, toRestore)('deleted', false);
      setToRestore(undefined);
    } :
    undefined
  const [collection, setCollection] = React.useState(undefined as undefined | QuerySnapshot);
  React.useEffect(
    () => {
      if (!spec?.collection || !doc) return;
      const { ref, addToQuery, indexField } = spec.collection(doc.ref);
      return (addToQuery ? addToQuery(ref) : ref).where('deleted', '==', false)
        .orderBy(indexField, 'asc').onSnapshot(setCollection);
    },
    [spec?.collection, doc?.ref.path]);
  const arr = isCollection ? (doc ? collection?.docs : []) : (v || []);
  if (!arr) return <ActivityIndicator />;
  return (
    <View style={{ gap: readonly ? 0 : 16 }}>
      {
        [...arr, ...(spec?.explicitAdd ? [] : [undefined])].map((v_, i, all) => {
          const remove = isCollection ?
            () => {
              valueSetter(user, v_)('deleted', FieldValue.serverTimestamp());
              setToRestore(v_);
            } :
            (update && (() => {
              const obj = [...arr];
              obj.splice(i, 1);
              update(obj);
            }));
          function getValue_(k_: string | undefined) {
            return k_ && v_ && v_[k_];
          }
          const update_ = update && ((v_: any) => {
            const obj = [...arr];
            obj[i] = v_;
            update(obj);
          });
          function setValue_(k_: string, subV: any) {
            if (!update_) return;
            const obj = { ...(v_ || defaultValue(field)) };
            if (subV === undefined) delete obj[k_];
            else obj[k_] = subV;
            update_(obj);
          }
          return (
            <View key={i} style={{ flexDirection: "row", flexWrap: "wrap", gap: readonly || params.cell ? 0 : 8 }}>
              <View>
                {spec?.numbering &&
                  <Text style={{
                    alignSelf: "flex-end", marginRight: 8, marginTop: 16, marginBottom: 16,
                    fontWeight: "bold"
                  }}
                  >
                    {spec?.numbering(v_, i + 1)}
                  </Text>}
              </View>
              {isCollection ?
                <FieldEntry
                  field={{ ...(field as any), multi: undefined, key: undefined, labelKey: undefined }}
                  keypath={[]}
                  doc={v_} setValue={valueSetter(user, v_)} getValue={valueGetter(v_)} create={undefined}
                  readonly={readonly}
                /> :
                <FieldComponent
                  {...params} readonly={readonly}
                  field={field}
                  keypath={[...params.keypath, i]}
                  v={v_} getValue={getValue_} setValue={setValue_} update={update_} doc={doc}
                />
              }
              {doc && spec?.extra ? spec.extra(navigation, doc, v_, i) : <View />}
              {(spec?.explicitAdd || i < all.length - 1) && remove && !readonly ?
                <DeleteButton style={{ alignSelf: "flex-end" }} onPress={remove} /> :
                null
              }
            </View>
          );
        })
      }
      <View style={{ flexDirection: "row", gap: 8 }}>
        {
          spec?.explicitAdd && update && !readonly ?
            [
              { icon: "plus", data: {} },
              ...(spec?.explicitAdd.extra ?
                [spec?.explicitAdd.extra] : [])
            ].map(({ icon, data }, i) =>
              <IconButton
                key={i}
                icon={icon}
                onPress={() => {
                  const item = { ...defaultValue(field), ...(spec.explicitAdd?.data || {}), ...data };
                  (params.create ? params.create().then(ref => ref.get()) : Promise.resolve(doc)).then(doc => {
                    if (!doc) return;
                    if (spec.collection) {
                      const { ref, indexField } = spec.collection(doc.ref);
                      const highestIndex = arr.length > 0 ?
                        (arr[arr.length - 1].get(indexField) as number | undefined) : undefined;
                      const newDocRef = ref.doc();
                      newDocRef.set({
                        ...item,
                        [indexField]: (highestIndex === undefined ? 0 : highestIndex + 1),
                        deleted: false,
                        createdBy: user.ref,
                        createdAt: FieldValue.serverTimestamp(),
                        lastModifiedBy: user.ref,
                        lastModifiedAt: FieldValue.serverTimestamp(),
                      });
                      if (spec?.onAdd) spec.onAdd(navigation, doc, newDocRef, arr.length)
                    }
                    else {
                      update([...arr, item]);
                      if (spec?.onAdd) spec.onAdd(navigation, doc, item, arr.length);
                    }
                  });
                }}
              />) :
            null
        }
        <UndoBanner undo={undo} />
      </View>
    </View>
  );
}

function FieldEntry({ category, keypath, unlabelled, ...params }: FieldComponentParams & { unlabelled?: boolean }) {
  const { ph } = React.useContext(LanguageContext);
  const { field, getValue, setValue, readonly } = params;
  const { k, labelK } = keysForField(field, category);
  const labelSuffix = 'labelSuffix' in field ? field.labelSuffix : undefined;
  const v = getValue(k);
  if (readonly && !(typeof field.kind === 'object' && 'composite' in field.kind) && _.isEmpty(v))
    return null;
  const elt = React.createElement(field.multi ? MultiComponent : FieldComponent,
    {
      ...params,
      keypath: [...keypath, k],
      v, update: k ? ((v: any) => setValue(k, v)) : undefined,
    }
  );
  if (unlabelled) return elt;
  return (
    <View style={{ marginLeft: 6, marginRight: 6, marginTop: readonly ? 0 : 16, marginBottom: readonly ? 0 : 16, gap: readonly ? 0 : 8 }}>
      {labelK && <FixtureLabel text={ph(labelK) + (labelSuffix ?? '')} />}
      {elt}
    </View>
  );
}

export type FieldsRowParams = BaseParams & {
  fields: Field[];
  style?: ViewStyle;
  cell?: React.FunctionComponent<any> | undefined
}

function FieldsRow_({ fields, style, ...params }: FieldsRowParams & ValueGetterAndSetter) {
  const fieldElts = fields.map((field, i) => <FieldEntry key={'key' in field ? field.key : i} field={field} {...params} />);
  const { cell } = params;
  if (cell) {
    const cells = fields.reduce(
      (cells: Field[][], field: Field) => {
        const cellIdx = cells.findIndex(fields => fields.find(field_ => field_.tableColumn === field.tableColumn));
        if (cellIdx != -1) {
          cells[cellIdx].push(field);
          return cells;
        }
        return [...cells, [field]];
      },
      []);
    return (
      <React.Fragment>
        {cells.map((fields, i) =>
          React.createElement(
            cell, { key: i },
            fields.map(
              (field, i_) =>
                <FieldEntry key={i_} field={field}
                  {...params} unlabelled={i_ == 0 || fields.length == 1} />
            )
          ))
        }
      </React.Fragment>
    );
  }
  return (
    <View style={{ flexDirection: "row", flexWrap: "wrap", gap: 8, ...style }}>
      {fieldElts}
    </View>
  );
}

export default function FieldsRow({ doc, ...params }: FieldsRowParams) {
  const { user } = React.useContext(UserContext);
  const [getValue, setValue] =
    doc ? [valueGetter(doc), valueSetter(user, doc)] :
      [getNoValue, creatingValueSetter(user, params.create)];
  return <FieldsRow_ {...params} getValue={getValue} setValue={setValue} keypath={[]} doc={doc} />
}
