import { StyleProp, ViewStyle } from "react-native";
import { IconButton, useTheme } from "react-native-paper";

export function DeleteButton({ style, onPress }: {
  style?: StyleProp<ViewStyle>
  onPress: () => void
}) {
  const { colors } = useTheme();
  return (
    <IconButton
      style={style}
      iconColor={colors.error}
      icon="delete"
      onPress={onPress}
    />
  );
}
