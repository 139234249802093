import React from "react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../types";
import CollectionList, { CollectionListSpec, useCollectionItems } from "../components/CollectionList";
import { useProject } from "../data/useProject";
import { sheetSubjectCollection } from "../data/sheet";
import { DocumentSnapshot, FieldValue, Query, QueryDocumentSnapshot } from "@atgof-firebase/firebase";
import { UserContext } from "../data/userContext";
import { useNavigation } from "@react-navigation/native";
import { lookTitle } from "./LookScreen";
import { LanguageContext } from "../common/language";
import { Phrase, PhraseKey } from "../common/phrases";
import ListItem from "../components/ListItem";
import NewSheetOrLookButton from "../components/NewSheetOrLookButton";
import SectionHeader from "../components/SectionHeader";
import { propTitle } from "./PropScreen";
import NavPage from "../components/pages/NavPage";
import { episodeLabel } from "../common/util";
import { notDeleted } from "../common/general";
import { IconButton } from "react-native-paper";
import { ProjectContext } from "../data/projectContext";
import { SheetCategory } from "../common/sheet";
import { SubjectSpec, hasSubject, idForSubjectName } from "../common/subject";

type LookData = { path: string; title: string }

function lookData(ph: (k: PhraseKey) => Phrase, look: DocumentSnapshot): LookData {
  return {
    path: look.ref.path,
    title: lookTitle(ph, look)
  };
}

type PropData = { path: string; title: string }

function propData(ph: (k: PhraseKey) => Phrase, prop: DocumentSnapshot): PropData {
  return {
    path: prop.ref.path,
    title: propTitle(ph, prop)
  };
}

type EpisodeData = { path: string; title: string }

function episodeData(ph: (k: PhraseKey) => Phrase, episode: DocumentSnapshot): EpisodeData {
  const path = episode.ref.path;
  return {
    path, title: episodeLabel(ph, path)
  };
}

export default function SheetSubjectDetailScreen({ route }: NativeStackScreenProps<RootStackParamList, 'sheetSubjectDetail'>) {
  const { subjectKind, subjectName } = route.params;
  const project = useProject();
  const { categories } = React.useContext(ProjectContext);
  const { user } = React.useContext(UserContext);
  const { ph } = React.useContext(LanguageContext);
  const { navigate } = useNavigation();
  const seasonsCollSpec = React.useMemo(
    () => project ? {
      collection: project.collection('seasons'),
      addConstraints: (q: Query) => q.orderBy("wrapDate", "desc"),
      process: (items: DocumentSnapshot[]) => items.map(item => item.id)
    } : undefined,
    [project]);
  const seasonIds = useCollectionItems<string>(seasonsCollSpec);
  const [collections, setCollections] = React.useState<CollectionListSpec<any, any>[] | undefined>();
  React.useEffect(
    () => {
      if (!project) {
        setCollections(undefined);
        return;
      }
      const subjDoc = sheetSubjectCollection(project, subjectKind)
        .doc(idForSubjectName(subjectName));
      const lookCollection = subjDoc.collection('looks');
      const propsCollection = categories.includes('arts') && subjDoc.collection('props');
      const subject: SubjectSpec = { subjectKind, subjectName };
      function newLookAction(category: SheetCategory) {
        return () => {
          const newDoc = lookCollection.doc();
          newDoc.set({
            createdBy: user.ref,
            createdAt: FieldValue.serverTimestamp(),
            lastModifiedBy: user.ref,
            lastModifiedAt: FieldValue.serverTimestamp(),
            order: -1, // TODO
            deleted: false,
            category,
            ...(subjectKind === 'character' ? { look: 'day' } : {})
          });
          navigate('look', { isNew: 'true', path: newDoc.path, subjectKind, subjectName });
        }
      }
      function newProp() {
        if (!propsCollection) return;
        const newDoc = propsCollection.doc();
        newDoc.set({
          createdBy: user.ref,
          createdAt: FieldValue.serverTimestamp(),
          lastModifiedBy: user.ref,
          lastModifiedAt: FieldValue.serverTimestamp(),
          deleted: false
        });
        navigate('prop', { isNew: 'true', path: newDoc.path, subjectKind, subjectName });

      }
      setCollections(
        [
          {
            collection: lookCollection,
            addConstraints: q => notDeleted(
              q.where('category', 'in', categories)
            ),
            process: (items: QueryDocumentSnapshot[]) => items.map(item => lookData(ph, item)),
            renderHeader: () =>
              <SectionHeader title={ph("looks") as string}>
                <NewSheetOrLookButton
                  kind="look"
                  restrictToCategories={subjectKind === 'character' ? undefined : ['arts']}
                  actionForCategory={newLookAction} />
              </SectionHeader>,
            renderItem: ({ item }: { item: LookData }) =>
              <ListItem
                label={item.title}
                onPress={
                  () => navigate('look', { path: item.path, subjectKind, subjectName })
                }
              />,
            searchTokens: (item: LookData) => [item.title]
          },
          ...(
            propsCollection ?
              [{
                collection: propsCollection,
                addConstraints: notDeleted,
                process: (items: QueryDocumentSnapshot[]) => items.map(item => propData(ph, item)),
                renderHeader: () =>
                  <SectionHeader title={ph("props") as string}>
                    <IconButton icon="plus" onPress={newProp} />
                  </SectionHeader>,
                renderItem: ({ item }: { item: PropData }) =>
                  <ListItem
                    label={item.title}
                    onPress={
                      () => navigate('prop', { path: item.path, subjectKind, subjectName })
                    }
                  />,
                searchTokens: (item: PropData) => [item.title]
              }]
              :
              []
          ),
          ...(
            project ?
              (seasonIds || []).map(seasonId => ({
                collection: project.collection('seasons').doc(seasonId)
                  .collection('episodes'),
                addConstraints: (q: Query) => hasSubject(notDeleted(q), subject),
                process: (items: QueryDocumentSnapshot[]) => items.reverse().map(item => episodeData(ph, item)),
                renderHeader: () =>
                  <SectionHeader title={ph("season") + ' ' + seasonId}>
                  </SectionHeader>,
                renderItem: ({ item }: { item: EpisodeData }) =>
                  <ListItem
                    label={item.title}
                    onPress={
                      () => {
                        navigate('episodeBreakdown', {
                          path: item.path,
                          subjectKind,
                          subjectName
                        })
                      }
                    }
                  />,
                searchTokens: (item: EpisodeData) => [
                  item.title,
                  ...item.path.split('/').reverse().slice(0, 1) // TODO Do this properly
                ]
              }))
              :
              []
          )
        ]
      );
    },
    [subjectKind, subjectName, project?.path, user.id, categories, ph, seasonIds]);
  return (
    <NavPage fullScreen isLoading={!collections}
      newSheetContext={{ subject: { subjectName: subjectName, subjectKind: subjectKind } }}>
      {collections && <CollectionList collections={collections} estimatedItemSize={85} />}
    </NavPage>
  );
}
