import { DocumentSnapshot } from "@atgof-firebase/firebase";
import React from "react";
import { LanguageContext } from "../common/language";
import { View } from "react-native";
import FixtureLabel from "../common/FixtureLabel";
import { Checkbox, ProgressBar } from "react-native-paper";
import Select from "./Select";

export function TeamsSelector({
  availableTeams, selectedTeamPaths, setSelectedTeamPaths, single = false
}: {
  availableTeams: DocumentSnapshot[] | undefined;
  selectedTeamPaths: string[] | null;
  setSelectedTeamPaths: (update: (teams: string[] | null) => string[]) => void;
  single: boolean
}) {
  const { ph } = React.useContext(LanguageContext);
  return (
    <View style={{ flexDirection: single ? "column" : "row", gap: 4 }}>
      <FixtureLabel text={ph(single ? 'team' : 'teams') as string} />
      {availableTeams ?
        (single ?
          <Select
            entries={availableTeams}
            selectedEntry={
              availableTeams.find(doc => selectedTeamPaths?.includes(doc.ref.path))
            }
            entryKey={doc => doc.id}
            entryLabel={doc => doc.id}
            onEntryChange={doc => setSelectedTeamPaths(() => doc ? [doc.ref.path] : [])}
          /> :
          availableTeams?.map(doc =>
            <Checkbox.Item
              key={doc.id}
              label={doc.id}
              status={selectedTeamPaths?.find(path => path === doc.ref.path) ? 'checked' : 'unchecked'}
              color={doc.get('backgroundColour')}
              onPress={() => setSelectedTeamPaths(paths => {
                const path = doc.ref.path;
                const idx = paths?.indexOf(path) ?? -1;
                return idx == -1 ?
                  [...(paths ?? []), path] :
                  [...(paths?.slice(0, idx) ?? []), ...(paths?.slice(idx + 1) ?? [])];
              })}
            />
          )) :
        <ProgressBar indeterminate />}
    </View>
  );
}

