export const FILE_TYPES = {
  'fdx': { label: "Final Draft" },
  'msd': { label: "Movie Magic Scheduling" },
  'pdf': { label: "PDF" }
};

export type FileType = keyof typeof FILE_TYPES;

export function fileType(filename: string): FileType | undefined {
  const match = filename?.match(/\.([^\.]+)$/);
  if (!match) return;
  const t = match[1];
  if (!(t in FILE_TYPES)) return;
  return t as FileType;
}
