import { EntityWithDisplayName, SpecForEntityWithDisplayName } from "./entityWithDisplayName";

export function normaliseSubjectName(name: string | undefined) {
  // TODO Look for occurences of toLocaleUpperCase to refactor existing usages to this
  const s = name?.trim().toLocaleUpperCase();
  return s?.length ? s : undefined;
}

export class SpecForSubject<T extends EntityWithDisplayName> extends SpecForEntityWithDisplayName<T> {
  labelFromTextInput(s: string) {
    return normaliseSubjectName(s);
  }
}

export type Subject = EntityWithDisplayName
