import React from "react";
import { useDocument } from "./useDocument";
import { useProject } from "./useProject";
import { FIELD_SPECS, FieldSpecs } from "../common/sheet";

export function useFieldSpecs() {
  const projectDoc = useDocument(useProject());
  return React.useMemo<FieldSpecs>(
    () => projectDoc?.get('fieldSpecs') ?? FIELD_SPECS,
    [projectDoc]
  );
}
