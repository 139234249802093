import React from "react";
import { ProjectContext } from "./projectContext";
import { DocumentReference } from "@atgof-firebase/types";
import { useEntities } from "./entity";
import { characterSpec } from "../common/model/project/character";
import { setSpec } from "../common/model/project/set";
import { Subject } from "../common/model/subject";

export function useProject() {
  const { project } = React.useContext(ProjectContext);
  return project;
}

export function useSubjects(
  project: DocumentReference | undefined,
  kind: 'characters' | 'sets'
) {
  const spec = kind === 'characters' ? characterSpec : setSpec;
  return useEntities<Subject>(
    spec,
    project?.collection(kind)
  )?.sort(spec.compare);
}

