import { View } from "react-native";
import { Checkbox, Chip } from "react-native-paper";

export type ChipSelectorEntry = {
  id: string;
  label: string;
}

// TODO Rename this to reflect that it's checkboxes not chips
export function ChipSelector({ entries, selectedEntryIds, onSelectionChanged }: {
  entries: ChipSelectorEntry[];
  selectedEntryIds: string[];
  onSelectionChanged: ((selectedIds: string[]) => void) | undefined;
}) {
  const items = onSelectionChanged ?
    entries.map(({ id, label }) =>
      <Checkbox.Item key={id} status={selectedEntryIds.includes(id) ? 'checked' : 'unchecked'}
        label={label}
        onPress={() => onSelectionChanged(
          selectedEntryIds.includes(id) ?
            selectedEntryIds.filter(x => x !== id) : [...selectedEntryIds, id]
        )} />
    )
    :
    entries
      .filter(({ id }) => selectedEntryIds.includes(id))
      .map(({ id, label }) => <Chip key={id}>{label}</Chip>);
  return (
    <View style={{ flexDirection: "row", gap: 4, flexWrap: "wrap" }}>
      {items}
    </View>
  );
}
