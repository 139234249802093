import dayjs from "dayjs";
import { Scene, sceneLabel } from "../common/scene";
import React from "react";
import { LanguageContext } from "../common/language";
import { Text, useTheme } from "react-native-paper";

type ScenePathAndTeam = {
  scenePath: string;
  team: string
}

export type Crossing = {
  from: ScenePathAndTeam;
  to: ScenePathAndTeam
}

export function crossingsFor(character: string, scenes: Scene[]) {
  return scenes.filter(scene => scene.cast?.includes(character))
    .reduce(
      ({ crossings, prev }, scene) => {
        const to: ScenePathAndTeam | undefined = scene.team ? {
          scenePath: scene.path,
          team: scene.team
        } : undefined;
        return {
          crossings: prev && to && to.team !== prev.team ?
            [...crossings, { from: prev, to }] : crossings,
          prev: to
        };
      },
      { crossings: [], prev: undefined } as {
        crossings: Crossing[]; prev: ScenePathAndTeam | undefined
      }
    ).crossings;
}


const crossingSeparator = "➙";

function CrossingElement({
  crossings, direction, scene
}: {
  crossings: Crossing[], direction: 'to' | 'from', scene: Scene
}) {
  const entries = crossings.filter(crossing => crossing[direction].scenePath === scene.path);
  if (!entries.length) return null;
  const { team } = entries[0][direction === 'to' ? 'from' : 'to'];
  return (
    <Text>
      {direction === 'from' ?
        crossingSeparator + ' ' + team :
        team + ' ' + crossingSeparator}
    </Text>
  );
}

function TimelineDuration({ color, labelColor, label }: {
  color: string;
  labelColor?: string;
  label?: string
}) {
  const { colors } = useTheme();
  return (
    <div style={{
      backgroundColor: color,
      borderColor: colors.onBackground,
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 8,
      margin: 2,
      flexGrow: 1
    }}>
      <div style={{ textAlign: "center", color: labelColor ?? "black", padding: 2 }}>
        {label ?? ""}
      </div>
    </div>
  );
}

function SceneCharacterTimelineEntry(
  { character, scene, crossings }: {
    character: string;
    prevScene: Scene;
    scene: Scene;
    nextScene: Scene;
    crossings: Crossing[]
  }
) {
  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: 2, alignItems: "center" }}>
      <CrossingElement direction="to" crossings={crossings} scene={scene} />
      {scene.cast?.includes(character) ?
        <TimelineDuration
          color="#dddddd"
          labelColor="#333333"
          label={scene.sets?.join(', ')}
        /> : null}
      <CrossingElement direction="from" crossings={crossings} scene={scene} />
    </div>
  );
}

export function Timeline({ cast, crossings = {}, scenes, day }: {
  cast: string[];
  crossings?: Record<string, Crossing[]>;
  scenes: Scene[];
  day?: dayjs.Dayjs
}) {
  const { ph } = React.useContext(LanguageContext);
  const { fonts, colors } = useTheme();
  return (
    <table style={{
      ...fonts.bodyMedium,
      lineHeight: undefined,
      color: colors.onBackground,
      borderSpacing: 0
    }}>
      <thead><tr>
        <th />
        {scenes.map(scene => <th key={scene.path}>{sceneLabel(scene.path)}</th>)}
      </tr></thead>
      <tbody>
        {cast.map(character =>
          <tr key={character} >
            <td>{character}</td>
            {scenes.map((scene, i) =>
              <td key={scene.path}>
                <SceneCharacterTimelineEntry
                  character={character}
                  prevScene={scenes[i - 1]} scene={scene} nextScene={scenes[i + 1]}
                  crossings={crossings[character] ?? []}
                />
              </td>)}
          </tr>)}
      </tbody>
    </table>
  );
}
