import { v4 as uuidv4 } from "uuid";
import { CollectionReference, DocumentData, DocumentReference, DocumentSnapshot, Query } from "@atgof-firebase/types";
import { FileType } from "../data/imports";
import { useSnapshot } from "../data/useSnapshot";
import { notDeleted } from "@atgof-common/general";
import { Platform, View } from "react-native";
import { Button, List, ProgressBar, Text } from "react-native-paper";
import { UndoBanner } from "./UndoBanner";
import { FileDropArea } from "./FileDropArea";
import React from "react";
import { LanguageContext } from "@atgof-common/language";
import { useUndoableDocumentDeleter } from "../data/undo";
import { storageManager, uploadFile, useUploads } from "../data/backend";
import { useProject } from "../data/useProject";
import { UserContext } from "../data/userContext";
import { DeleteButton } from "./DeleteButton";
import { formatDate } from "../common/util";
import { UploadStatus } from "../data/backend/uploader";

function UploadEntry(
  { doc, retryUpload, deleteEntry, completedUploadElement }:
    {
      doc: DocumentSnapshot;
      retryUpload: (upload: UploadStatus) => void;
      deleteEntry: (docRef: DocumentReference) => void;
      completedUploadElement?: (doc: DocumentSnapshot) => React.ReactElement
    }
) {
  const { ph } = React.useContext(LanguageContext);
  const uploads = useUploads();
  const upload = uploads.find(({ docRefPath }) => docRefPath === doc.ref.path);
  const metadata = doc.get('originalFileMetadata');
  const lastModified = metadata?.lastModified;
  const uploading = !doc?.get('uploadCompletedAt');
  const importing = doc?.get('importRequestAt') !== undefined;
  const inProgress = !upload?.error && (uploading || importing);
  const complete = !(upload?.error || inProgress);
  const onPress = upload?.error ? () => retryUpload(upload) : undefined;
  const actionPhrase = upload?.error ? 'retry' :
    (uploading ? 'uploading' : (importing ? 'importing' : undefined));
  return (
    <List.Item title={metadata?.name}
      description={upload?.error ? upload.error.name // TODO And error.message
        : formatDate(new Date(lastModified))}
      onPress={onPress}
      right={
        () =>
          <View style={{ flexDirection: "row", gap: 4, alignItems: "center" }}>
            {complete ?
              (completedUploadElement ? completedUploadElement(doc) : null) :
              (actionPhrase ?
                <Button
                  onPress={onPress}
                  loading={inProgress}
                >
                  {ph(actionPhrase) as string}
                </Button> : null)
            }
            <DeleteButton onPress={() => deleteEntry(doc.ref)} />
          </View>
      }
    />
  );
}

export function FileUploads({
  collectionRef,
  accept,
  extraInitialData,
  restrict = (q => q),
  completedUploadElement
}: {
  collectionRef: CollectionReference | undefined;
  accept: FileType[];
  extraInitialData?: DocumentData;
  restrict?: (q: Query) => Query;
  completedUploadElement?: (doc: DocumentSnapshot) => React.ReactElement
}) {
  const { ph } = React.useContext(LanguageContext);
  const { user } = React.useContext(UserContext);
  const project = useProject();
  const { undo, undoableDelete } = useUndoableDocumentDeleter();
  const items = useSnapshot<DocumentSnapshot[] | undefined>(
    onNext => collectionRef ? notDeleted(restrict(collectionRef))
      .orderBy('createdAt', 'asc')
      .onSnapshot(snapshot => onNext(snapshot.docs)) :
      onNext(undefined),
    [collectionRef?.path]
  );
  function onFiles(files: File[]) {
    if (!(project && collectionRef)) return;
    for (const file of files) {
      uploadFile(
        project, 'admin', user,
        {
          uploadIdentifier: uuidv4(),
          collectionRef
        },
        file,
        {
          deleted: false,
          originalFileMetadata: {
            name: file.name, size: file.size, lastModified: file.lastModified
          },
          ...(extraInitialData ?? {})
        }
      );
    }
  }
  function retryUpload(upload: UploadStatus) {
    storageManager.uploader.retryUploads(user, [upload], true);
  }
  return (
    Platform.OS === "web" ?
      <View>
        <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
          <UndoBanner undo={undo} />
        </View>
        <View style={{ marginBottom: 16, gap: 8 }}>
          {items?.map(item =>
            <UploadEntry key={item.id}
              doc={item}
              retryUpload={retryUpload}
              deleteEntry={undoableDelete}
              completedUploadElement={completedUploadElement}
            />) ??
            <ProgressBar indeterminate />}
        </View>
        <FileDropArea
          accept={accept}
          onFiles={onFiles}
        />
      </View>
      :
      <Text variant="titleMedium" style={{ alignSelf: "center" }}>
        {ph('uploads-not-possible') as string}
      </Text>
  );
}
