import { DocumentReference, DocumentSnapshot, Firestore } from "@atgof-firebase/firebase";
import { UploadStatus, Uploader } from "./uploader";

const cacheDbSpec = {
  dbName: "upload_files",
  version: 1,
  objectStore: {
    name: "entries"
  }
};

export class UploaderImpl extends Uploader {
  private hasServiceWorker: boolean;
  private statuses = new Map<string, UploadStatus>();
  private cacheDb: IDBDatabase | undefined = undefined;

  public constructor(
    uploadUri: string,
    initialServiceWorkerStatuses: Promise<UploadStatus[]> | undefined
  ) {
    super(uploadUri);
    this.getCacheDb();
    this.hasServiceWorker = initialServiceWorkerStatuses !== undefined;
    this.setInitialStatuses(initialServiceWorkerStatuses);
  }

  private async setInitialStatuses(
    initialServiceWorkerStatuses: Promise<UploadStatus[]> | undefined
  ) {
    if (!initialServiceWorkerStatuses) return;
    try {
      const statuses = await initialServiceWorkerStatuses;
      for (const status of statuses) {
        this.statuses.set(status.storagePath, status);
      }
      this.handleUpdate([...this.statuses.values()]);
    }
    catch (error) {
      console.error("Unable to get upload statuses from service worker", error)
    }
  }

  public onServiceWorkerUploadStart(upload: UploadStatus) {
    const { storagePath } = upload;
    this.statuses.set(storagePath, upload);
    this.handleUpdate([...this.statuses.values()]);
  }

  public onServiceWorkerUploadSuccess(upload: UploadStatus) {
    const { storagePath } = upload;
    this.statuses.delete(storagePath);
    this.handleUpdate([...this.statuses.values()]);
  }

  public onServiceWorkerUploadFailure(upload: UploadStatus) {
    const { storagePath } = upload;
    this.statuses.set(storagePath, upload);
    this.handleUpdate([...this.statuses.values()]);
  }

  private async clearCompletedUploads(db: Firestore) {
    let statusesHaveChanged = false;
    for (const storagePath of [...this.statuses.keys()]) {
      const status = this.statuses.get(storagePath);
      if (!status) continue;
      const { docRefPath } = status;
      const uploaded = !!(await db.doc(docRefPath).get()).get('uploadCompletedAt');
      if (uploaded) {
        this.statuses.delete(storagePath); // TODO How do we clear this from the underlying set of service worker upload statuses too?
        statusesHaveChanged = true;
      }
    }
    return statusesHaveChanged;
  }

  private async getUncompletedUploads(
    userRef: DocumentReference,
    collectionId: string
  ) {
    const { objectStore } = cacheDbSpec;
    const { docs } = (
      await userRef.firestore.collectionGroup(collectionId)
        .where('createdBy', '==', userRef)
        .where('deleted', '==', false)
        .where('uploadCompletedAt', '==', false)
        .get()
    );
    const uncompleted = new Map<string, UploadStatus>();
    for (const doc of docs) {
      try {
        const storagePath: string | undefined = doc.get('path');
        if (!storagePath || this.statuses.has(storagePath)) continue;
        const transaction = (await this.getCacheDb())!!.transaction(
          [objectStore.name], "readonly"
        );
        const store = transaction.objectStore(objectStore.name);
        const exists = await new Promise<boolean>(
          (resolve, reject) => {
            const req = store.openCursor(storagePath);
            req.onsuccess = _ => resolve(req.result ? true : false);
            req.onerror = _ => reject(req.error);
          }
        );
        if (!exists) continue;
        uncompleted.set(storagePath, {
          docRefPath: doc.ref.path,
          storagePath,
          mimeType: doc.get('mimeType')
        });
      }
      catch (err) {
        console.error(
          `Unable to determine re-upload information for ${doc.ref.path}`,
          err
        );
      }
    }
    return uncompleted.values();
  }

  public override async onUserConnected(user: DocumentSnapshot) {
    try {
      if (await this.clearCompletedUploads(user.ref.firestore)) {
        this.handleUpdate([...this.statuses.values()]);
      }
    }
    catch (err) {
      console.error("Unable to clear completed uploads", err);
    }
    let uncompleted: UploadStatus[] = [];
    try {
      uncompleted = [...await this.getUncompletedUploads(user.ref, 'images')];
    }
    catch (err) {
      console.error("Unable to add uncompleted uploads", err);
    }
    // TODO Make service worker respond to change of user
    const toRetry = this.hasServiceWorker ?
      [...this.statuses.values()].filter(status => status.error) :
      [...this.statuses.values()];
    this.retryUploads(user, [...toRetry, ...uncompleted], false);
  }

  protected override getUploadStatuses() {
    return [...this.statuses.values()];
  }
  protected override getUploadStatus(storagePath: string) {
    return this.statuses.get(storagePath);
  }
  protected override setUploadStatus(storagePath: string, status: UploadStatus) {
    if (!this.hasServiceWorker) {
      this.statuses.set(storagePath, status);
      this.handleUpdate([...this.statuses.values()]);
    }
  }
  protected override async setFinished(storagePath: string) {
    if (!this.hasServiceWorker) {
      this.statuses.delete(storagePath);
      this.handleUpdate([...this.statuses.values()]);
    }
  }

  protected override async copyTemporaryContentToCache(
    storagePath: string, dataURI: string
  ) {
    const { objectStore } = cacheDbSpec;
    const data = await new Promise<Blob>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => resolve(xhr.response);
      xhr.onerror = () => reject(new Error("Conversion of data URL to Blob failed"));
      xhr.responseType = "blob";
      xhr.open("GET", dataURI, true);
      xhr.send(null);
    });
    const transaction = (await this.getCacheDb())!!.transaction(
      [objectStore.name], "readwrite"
    );
    return new Promise<string>(
      (resolve, reject) => {
        const req = transaction
          .objectStore(objectStore.name).put(data, storagePath);
        req.onsuccess = _ => resolve(storagePath);
        req.onerror = _ => reject(req.error);
      }
    );
  }

  protected async getDataForCacheKey(storagePath: string) {
    const { objectStore } = cacheDbSpec;
    const transaction = (await this.getCacheDb())!!.transaction(
      [objectStore.name], "readonly"
    );
    const store = transaction.objectStore(objectStore.name);
    const exists = await new Promise<boolean>(
      (resolve, reject) => {
        const req = store.openCursor(storagePath);
        req.onsuccess = _ => resolve(req.result ? true : false);
        req.onerror = _ => reject(req.error);
      }
    );
    if (!exists) return;
    return new Promise<Blob>(
      (resolve, reject) => {
        const req = store.get(storagePath);
        req.onsuccess = _ => resolve(req.result);
        req.onerror = _ => reject(req.error);
      }
    );
  }

  override cacheKeyForStoragePath(storagePath: string) {
    return storagePath;
  }

  public override async getUriForStoragePath(storagePath: string) {
    const data = await this.getDataForCacheKey(this.cacheKeyForStoragePath(storagePath));
    if (!data) return;
    return URL.createObjectURL(data);
  }

  protected override async uploadFromLocalFile(upload: UploadStatus) {
    const { storagePath, cacheKey } = upload;
    const form = new FormData();
    const blob = await this.getDataForCacheKey(cacheKey!!);
    const mimeType = blob?.type ?? upload.mimeType;
    if (!mimeType) throw Error("Invalid data URL for file");
    if (!blob) throw Error(`No blob in cache DB for ${cacheKey}`);
    form.append(storagePath, new File([blob], "file", { type: mimeType }));
    await this.uploadForm(upload, form);
  }

  protected override isUploadingInBackground(storagePath: string) {
    const status = this.statuses.get(storagePath);
    return !!status && !status.error;
  }

  protected override shouldIncludeStatusHeader() { return this.hasServiceWorker }

  private async getCacheDb() { // TODO Refactor what this has in common with downloader.getRefsDb()
    if (this.cacheDb) return this.cacheDb;
    const { dbName, version } = cacheDbSpec;
    try {
      const req = indexedDB.open(dbName, version);
      this.cacheDb = await new Promise((resolve, reject) => {
        req.onsuccess = _ => resolve(req.result);
        req.onerror = _ => reject(req.error);
        req.onupgradeneeded = _ => {
          const { name } = cacheDbSpec.objectStore;
          req.result.createObjectStore(name);
        };
      });
    }
    catch (err) {
      console.error(
        `Unable to open file references database ${dbName} version ${version}`
      );
    }
    return this.cacheDb;
  }
}
