import { DocumentData, QueryDocumentSnapshot } from '@atgof-firebase/firebase';
import React from 'react';
import { useEffect } from 'react';
import { nativeApplicationVersion } from 'expo-application';

import { AlertBox, AlertBoxProps } from '../components/AlertBox';
import SessionPreferences from '../components/SessionPreferences';
import UserPreferences from '../components/UserPreferences';
import { LanguageContext } from '../common/language';
import { UserContext } from '../data/userContext';
import { projectOfMembership } from '../data/project';
import { useProject } from "../data/useProject";
import { RootTabScreenProps } from '../types';
import { MembershipResults } from '../common/membership';
import ErrorDetail from '../components/ErrorDetail';
import { storageManager, TransactFunction, useTransact } from '../data/backend';
import NavPage from '../components/pages/NavPage';
import { PhraseFunction } from '../common/phrases';
import { Platform, View } from 'react-native';
import { Button, Card, Text } from 'react-native-paper';
import LoadingView from '../components/LoadingView';
import { getDocumentReference } from '../common/firestore';

type InvitationsProps = { invitations: MembershipResults }

function InvitationsAlert({ children, ...props }: { children: React.ReactElement[] } & AlertBoxProps) {
  return (
    <AlertBox {...props}>
      <View style={{ marginTop: 8, gap: 8 }}>
        {children}
      </View>
    </AlertBox>
  );
}

function Invitation({ invitation, transact }: {
  invitation: QueryDocumentSnapshot<DocumentData>
  transact: TransactFunction<void>
}) {
  const { ph } = React.useContext(LanguageContext);
  const { user } = React.useContext(UserContext);

  const projectRef = projectOfMembership(invitation);

  const [projectName, setProjectName] = React.useState<string>();
  const [inProgress, setInProgress] = React.useState(false);

  useEffect(
    () =>
      projectRef ?
        projectRef.onSnapshot(doc => setProjectName(doc.get('displayName'))) :
        setProjectName(undefined),
    [projectRef]);

  function acceptInvitation() {
    if (!user || !projectRef) return;
    setInProgress(true);
    const inkColour = invitation?.get('inkColour');
    const notifications = invitation?.get('notifications');
    transact('accept-invitation', () =>
      projectRef.collection("memberships").doc(user.id).set({
        deleted: false,
        displayName: user.get('displayName') || '',
        email: user.get('email') || '',
        user: getDocumentReference(user, `users/${user.id}`),
        roles: invitation!.get('roles'),
        ...(inkColour ? { inkColour } : {}),
        ...(notifications ? { notifications } : {}),
        lastModifiedBy: user.ref,
      })
    ).then(() => projectRef.collection("invitations").doc(user.get('email')).delete())
      .catch(() => { })
      .finally(() => setInProgress(false));
  }

  return (
    <AlertBox status="info" title={ph('new-invitation') as string}>
      <View style={{ flexDirection: "row" }}>
        <Text variant="bodyMedium">{ph('invited-to-join') + ' '}</Text>
        {
          projectName ?
            <Text variant="bodyMedium" style={{ fontWeight: "bold" }}>{projectName}</Text> :
            <Text variant="bodyMedium">{ph('a-project') as string}</Text>
        }
      </View>
      <Button onPress={() => acceptInvitation()} loading={inProgress} mode="outlined">
        {ph('accept') as string}
      </Button>
    </AlertBox>
  );
}

function Invitations({
  invitations, transact
}: InvitationsProps & { transact: TransactFunction<void> }) {
  const { ph } = React.useContext(LanguageContext);
  const { user } = React.useContext(UserContext);
  const project = useProject();

  if (!invitations) return <LoadingView />;
  if ('error' in invitations) {
    return (
      <InvitationsAlert status="error">
        <Text>{ph('invitations-failed-with-reason') as string}</Text>
        <ErrorDetail>{invitations.error.message}</ErrorDetail>
      </InvitationsAlert>
    )
  }

  return invitations.snapshot.empty && !project ?
    <InvitationsAlert status="info" title={ph('join-a-project') as string}>
      <Text>
        {(ph('no-invitations-explanation') as PhraseFunction)({ email: user?.get('email') }) as string}
      </Text>
      <Text>{ph('no-invitations-next-step') as string}</Text>
    </InvitationsAlert>
    :
    <View style={{ gap: 8 }}>
      {invitations.snapshot.docs.map(
        (invitation, i) =>
          <Invitation key={i} invitation={invitation} transact={transact} />
      )}
    </View>;
}

function VersionInfo() {
  const { ph } = React.useContext(LanguageContext);
  const [checking, setChecking] = React.useState(false);
  const checkForUpdates = React.useCallback(
    () => {
      setChecking(true);
      storageManager.checkForUpdates().then(() => setChecking(false));
    },
    [setChecking]
  );
  const version = nativeApplicationVersion ?? process.env.EXPO_PUBLIC_APP_VERSION;
  return (
    <Card style={{ margin: 2 }}>
      <Card.Title
        title={ph('app-version') as string}
        subtitle={`${version ?? ''} ${Platform.OS}`}
      />
      <Card.Actions>
        <Button mode="text" loading={checking} onPress={checkForUpdates}>
          {ph('check-for-updates') as string}
        </Button>
      </Card.Actions>
    </Card>
  );
}

export default function SettingsScreen({ invitations }: RootTabScreenProps<'settings'> & InvitationsProps) {
  const { latestAction, transact } = useTransact<void>();

  return (
    <NavPage latestAction={latestAction}>
      <View style={{ maxWidth: 480, gap: 10 }}>
        <SessionPreferences />
        <Invitations invitations={invitations} transact={transact} />
        <UserPreferences />
        <VersionInfo />
      </View>
    </NavPage>
  );
}
