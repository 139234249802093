import NavPage from "../components/pages/NavPage";
import { RootTabScreenProps } from "../types";
import React from "react";
import { useProject } from "../data/useProject";
import { View } from "react-native";
import { ProgressBar, Text } from "react-native-paper";
import { LanguageContext } from "../common/language";
import { compareSceneIds } from "../common/scene";
import {
  PhraseFunction, PhraseKey, PhraseLookupFunction
} from "../common/phrases";
import { formatDate, formatTime } from "../common/util";
import { storageManager, useDownloads } from "../data/backend";
import { Downloaded } from "../data/backend/downloader";
import { FlashList } from "@shopify/flash-list";
import _ from "lodash";
import {
  VariantProp
} from "react-native-paper/lib/typescript/components/Typography/types";
import { compareSeasonIds } from "@atgof-common/season";
import { compareEpisodeIds } from "@atgof-common/episode";
import { lookReference } from "@atgof-common/look";
import { subjectNameForId, subjectSpecForSheet } from "@atgof-common/subject";
import { useDocument } from "../data/useDocument";

const levels: VariantProp<never>[] = [
  "headlineLarge",
  "headlineMedium",
  "headlineSmall",
  "bodyMedium"
];

type DownloadEntry = Omit<Downloaded, 'lookId'> & {
  lookLabel?: string
  subjectName?: string
}

type ListHeadingProps = { text: string }

type ListDownloadsProps = { entries: DownloadEntry[] }

type ListEntry = {
  level: number
} & (ListHeadingProps | ListDownloadsProps)

function ListDownloads({ entries, marginLeft }: ListDownloadsProps & {
  marginLeft: number
}) {
  const { ph } = React.useContext(LanguageContext);
  const project = useProject();
  const latestEntry = entries.sort(compareDownloads)[0];
  const lookLabel = useDocument(
    lookReference(project, latestEntry)
  )?.get('displayName');
  const latestDate = latestEntry.cachedDate?.length ?
    new Date(latestEntry.cachedDate) : undefined;
  const { isScript, subjectName, subjectId, sheetId } = latestEntry;
  const subjectSpec = subjectSpecForSheet(
    useDocument(
      sheetId ? project?.collection('sheets').doc(sheetId) : undefined
    )
  );
  const uris = entries.map(({ cachedURI }) => cachedURI);
  const thumbnailCount = uris.filter(s => s.endsWith('_sm')).length;
  const fullSizeCount = uris.length - thumbnailCount;
  return (
    <View style={{
      flexDirection: "row", gap: 8, alignItems: "center",
      marginLeft
    }}>
      <Text variant="bodyMedium">
        {
          latestDate ?
            (formatDate(latestDate) + ' ' + formatTime(latestDate)) :
            ''
        }
      </Text>
      {
        isScript ?
          <Text variant="titleMedium">
            {ph('script') as string}
          </Text> :
          <React.Fragment>
            <View style={{ gap: 2 }}>
              <Text>
                {`${thumbnailCount} ` +
                  (ph(
                    'count-thumbnail'
                  ) as PhraseFunction)(
                    { count: thumbnailCount }
                  )}
              </Text>
              <Text>
                {`${fullSizeCount} ${ph('full-size')}`}
              </Text>
            </View>
            <View style={{ gap: 2 }}>
              <Text variant="titleMedium">
                {
                  subjectSpec?.subjectName ?? subjectName ??
                  subjectNameForId(subjectId) ?? ''
                }
              </Text>
              <Text>
                {lookLabel ?? ''}
              </Text>
            </View>
          </React.Fragment>
      }
    </View>
  );
}

function compareDownloads(a: DownloadEntry, b: DownloadEntry) {
  return (b.cachedDate ?? '').localeCompare(a.cachedDate ?? '');
}

function fileCountEntries(
  ph: PhraseLookupFunction, headingPh: PhraseKey, n: number
): ListEntry[] {
  return [
    {
      text: ph(headingPh) as string,
      level: 0
    },
    {
      text: `${n} ${(ph('count-file') as PhraseFunction)(n)}`,
      level: 2
    }
  ];
}

function Downloads({ }) {
  const { ph } = React.useContext(LanguageContext);
  const project = useProject();
  const { downloader } = storageManager;
  const { downloadCount, queuedCount } = useDownloads();
  const downloads = downloadCount + queuedCount;
  const [listData, setListData] = React.useState<ListEntry[]>();
  const [availableStorageDesc, setAvailableStorageDesc] = React.useState<string>();
  React.useEffect(
    () => {
      setAvailableStorageDesc(undefined);
      downloader.getFreeStorageEstimate().then(
        bytes => {
          if (bytes == null) return;
          const units = [
            { suffix: "GB", inBytes: 1000000000 },
            { suffix: "MB", inBytes: 1000000 },
            { suffix: "kB", inBytes: 1000 },
          ];
          const { inBytes, suffix } =
            units.find(({ inBytes }) => bytes >= inBytes)!;
          const value = bytes / inBytes;
          setAvailableStorageDesc(
            value.toFixed(1) + ' ' + suffix + ' ' + ph('available')
          );
        }
      )
    },
    [downloads, ph]
  );
  React.useEffect(() => {
    let cancelled = false;
    (async function() {
      setListData(undefined);
      try {
        let list: ListEntry[] = [];
        const allDownloaded = (
          await downloader.allDownloaded()
        ).map(entry => ({ ...entry, }));
        if (cancelled) return;
        const { thisProject, otherProjects, unknownProject } =
          _.groupBy(allDownloaded,
            entry => entry.project === project?.id ?
              'thisProject' : (entry.project ? 'otherProjects' : 'unknownProject')
          );
        const bySeason = _.groupBy(thisProject, ({ season }) => season ?? null);
        for (const season of Object.keys(bySeason).sort(compareSeasonIds)) {
          const entries = bySeason[season];
          if (season && entries.length) {
            list.push({
              text: season ? `${ph('season')} ${season}` : (ph('looks') as string),
              level: 0
            });
          }
          const byIsEpisodeBased = _.groupBy(
            entries,
            ({ episode }) => episode ? 'episodeBased' : ''
          );
          for (const episodeBased of ['', 'episodeBased']) {
            const isEpisodeBased = episodeBased === 'episodeBased';
            const byEpisodeOrSubject = _.groupBy(
              byIsEpisodeBased[episodeBased],
              ({ episode, subjectId }) => episode ?? subjectNameForId(subjectId)
            );
            const episodesOrSubjects = Object.keys(byEpisodeOrSubject);
            for (
              const episodeOrSubject of (
                isEpisodeBased ?
                  episodesOrSubjects.sort(compareEpisodeIds).reverse() :
                  episodesOrSubjects.sort()
              )
            ) {
              list.push({
                text: isEpisodeBased ?
                  `${ph('episode')} ${episodeOrSubject}` :
                  (episodeOrSubject ?? (ph('unknown') as string)),
                level: 1
              });
              const byIsSceneBased = _.groupBy(
                byEpisodeOrSubject[episodeOrSubject],
                ({ scene }) => scene ? 'sceneBased' : ''
              );
              for (const sceneBased of ['', 'sceneBased']) {
                const isSceneBased = sceneBased === 'sceneBased';
                const bySceneOrSubject = _.groupBy(
                  byIsSceneBased[sceneBased],
                  ({ scene, subjectId }) => scene ?? subjectId
                );
                const scenesOrSubjects = Object.keys(bySceneOrSubject);
                if (isSceneBased) {
                  for (const scene of scenesOrSubjects.sort(compareSceneIds)) {
                    list.push({
                      text: `${episodeOrSubject}/${scene}`,
                      level: 2
                    });
                    list.push({
                      entries: bySceneOrSubject[scene],
                      level: 3
                    });
                  }
                }
                else {
                  list.push({
                    text: ph('looks') as string,
                    level: 2
                  });
                  for (const subjectId of scenesOrSubjects.sort()) {
                    list.push({
                      entries: bySceneOrSubject[subjectId],
                      level: 3
                    });
                  }
                }
              }
            }
          }
        }
        if (otherProjects?.length) {
          list.push(
            ...fileCountEntries(ph, 'other-projects', otherProjects.length)
          );
        }
        if (unknownProject?.length) {
          list.push(
            ...fileCountEntries(ph, 'uncategorised', unknownProject.length)
          );
        }
        setListData(list);
      }
      catch (err) { console.error(err); }
    })();
    return () => { cancelled = true };
  }, [project, downloads, ph]);
  return (
    <View style={{ gap: 16 }}>
      <Text variant="bodyMedium">{availableStorageDesc}</Text>
      {listData ? null : <ProgressBar indeterminate />}
      <FlashList
        data={listData}
        estimatedItemSize={100}
        renderItem={
          ({ item }) => {
            const marginLeft = item.level * 16;
            if ('text' in item) {
              return (
                <Text
                  style={{
                    marginTop: (levels.length - item.level) * 4,
                    marginLeft
                  }}
                  variant={levels[item.level]}
                >
                  {item.text}
                </Text>
              );
            }
            else if ('entries' in item) {
              const { entries } = item;
              return <ListDownloads entries={entries} marginLeft={marginLeft} />;
            }
            return null;
          }
        } />
    </View>
  );
}

export default function DownloadsScreen({ }: RootTabScreenProps<'downloads'>) {
  return (
    <NavPage>
      <Downloads />
    </NavPage>
  );
}
