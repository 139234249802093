import { z } from 'zod';
import { reference } from "../reference";
import { SpecForSubject, Subject } from '../subject';

export const characterReference = reference;
export const characterId = z.string();

export const characterSpec = new SpecForSubject({}, {
  singlePhraseKey: 'character',
  pluralPhraseKey: 'characters'
});

export type Character = Subject

