import { v4 as uuidv4 } from "uuid";
import React from 'react';
import { Button, Card, Icon, IconButton, Menu, Modal, Portal, ProgressBar, Text, useTheme } from 'react-native-paper';
import { LanguageContext } from '../common/language';
import { Linking, View, Text as RawText } from 'react-native';
import { FieldValue } from '@atgof-firebase/firebase';
import { ProjectContext } from '../data/projectContext';
import { UserContext } from "../data/userContext";
import { getStorageURL } from "../config/firebase";
import { Export } from "@atgof-common/generated/entities/project/export";
import { PhraseKey } from "@atgof-common/phrases";
import { CoreComponentClass, CoreComponentsContext } from "@atgof-common/core-components";

import { NewItemIndicator } from './NewItemIndicator';
import { TimeOfDayRepresentation } from './TimeOfDayRepresentation';

type ExportState =
  'awaiting-setup' | 'in-progress' | 'error' | { generated: string }

export function ExportButton({
  kind,
  exportData = {},
  phrases,
  setupComponent
}: {
  kind: Export['kind'];
  exportData?: Partial<Export>;
  phrases: {
    generate: PhraseKey,
    generating: PhraseKey,
    unableToGenerate: PhraseKey,
    generated: PhraseKey,
    download: PhraseKey
  },
  setupComponent?:
  (
    exportWithExtraData: (data: Partial<Export>) => void,
    dismiss: () => void
  ) => React.ReactElement
}) {
  const { ph } = React.useContext(LanguageContext);
  const { colors, roundness } = useTheme();
  const { project } = React.useContext(ProjectContext);
  const { user } = React.useContext(UserContext);
  const [menuVisible, setMenuVisible] = React.useState(false);
  const [result, setResult] = React.useState<ExportState>();
  function openMenu() { setMenuVisible(true); }
  function closeMenu() { setMenuVisible(false); }
  const createExport = async (extraData?: Partial<Export>) => {
    closeMenu();
    if (setupComponent && !extraData) {
      setResult('awaiting-setup');
      return;
    }
    const exportRef = project?.collection('exports').doc(uuidv4());
    if (!exportRef) return;
    setResult('in-progress');
    try {
      await exportRef.set(
        {
          createdBy: user.ref,
          createdAt: FieldValue.serverTimestamp(),
          deleted: false,
          kind,
          ...exportData,
          ...(extraData ?? {})
        }
      );
      exportRef.onSnapshot(async snapshot => {
        if (snapshot.get('errorAt')) {
          setResult('error');
          return;
        }
        const path = snapshot.get('path');
        if (path) {
          try {
            setResult({ generated: await getStorageURL(path) });
          }
          catch (err) {
            console.error(err);
            setResult('error');
          }
        }
      });
    }
    catch (err) {
      console.error(err);
      setResult('error');
    }
  };
  function dismiss() {
    setResult(result => result === 'in-progress' ? result : undefined);
  }
  return (
    <React.Fragment>
      <Menu
        visible={menuVisible} anchorPosition="bottom"
        onDismiss={closeMenu}
        anchor={<IconButton icon="export-variant" onPress={openMenu} />}
      >
        <Menu.Item
          title={ph(phrases.generate) as string}
          onPress={() => { createExport(); }}
        />
      </Menu>
      <Portal>
        <CoreComponentsContext.Provider value={{ // TODO Refactor this with what's in App.tsx
          View: View as CoreComponentClass,
          Text: (Text as unknown) as CoreComponentClass,
          NewItemIndicator,
          TimeOfDayRepresentation,
          RawText: RawText as CoreComponentClass,
          colors: {
            fixture: colors.secondary,
            onBackground: colors.onBackground
          }
        }}>

          <Modal
            visible={result !== undefined}
            onDismiss={dismiss}
            contentContainerStyle={{
              borderRadius: roundness,
              backgroundColor: colors.background,
              alignSelf: "center"
            }}
          >
            {setupComponent && result === 'awaiting-setup' ?
              setupComponent(createExport, dismiss) :
              (result ?
                <Card>
                  <Card.Content>
                    {result === 'in-progress' ?
                      <View style={{ gap: 8 }}>
                        <Text variant="titleMedium">
                          {ph(phrases.generating) as string}
                        </Text>
                        <ProgressBar indeterminate />
                      </View> :
                      <View style={{ flexDirection: "row", gap: 8, alignItems: "center" }}>
                        <Icon
                          source={
                            result === 'error' ? "alert-circle" : "check-circle"
                          }
                          size={20}
                        />
                        <Text variant="titleMedium">
                          {ph(
                            result === 'error' ?
                              phrases.unableToGenerate : phrases.generated
                          ) as string}
                        </Text>
                      </View>}
                  </Card.Content>
                  <Card.Actions>
                    <Button mode="text" onPress={dismiss}>
                      {ph('close') as string}
                    </Button>
                    {typeof result === 'object' ?
                      <Button onPress={() => {
                        Linking.openURL(result.generated);
                        dismiss();
                      }}>
                        {ph(phrases.download) as string}
                      </Button> : null}
                  </Card.Actions>
                </Card> : null)}
          </Modal>
        </CoreComponentsContext.Provider>
      </Portal>
    </React.Fragment>
  );
}
