import React from "react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { ActivityIndicator, View } from "react-native";
import { FieldValue } from "@atgof-firebase/firebase";
import DetailPage from "../components/pages/DetailPage";
import { LanguageContext } from "../common/language";
import { RootStackParamList } from "../types";
import { episodeLabel } from "../common/util";
import { toEpisode } from "../common/episode";
import SectionHeader from "../components/SectionHeader";
import NewSheetOrLookButton from "../components/NewSheetOrLookButton";
import { UserContext } from "../data/userContext";
import { useNavigation } from "@react-navigation/native";
import { BreakdownSheets } from "../components/BreakdownSheets";
import { ScriptButton } from "../components/ScriptButton";
import { ProjectContext } from "../data/projectContext";
import { SheetCategory, sheetCategorySpecs } from "../common/sheet";
import { transformSnapshot, useSnapshot } from "../data/useSnapshot";
import _ from "lodash";
import { getEpisodeLooksRef, onEpisodeLookSheets, onEpisodeSceneSheets, SceneSheets } from "../common/episodeBreakdown";
import { ProgressBar } from "react-native-paper";
import { useDocumentReference } from "../data/firestore";
import { DocumentSnapshot } from "@atgof-firebase/types";
import { useUserInkColours } from "../data/useUserInkColours";
import { UserColoursKey } from "../components/UserColoursKey";
import { useProject } from "../data/useProject";
import { useDocument } from "../data/useDocument";

export default function EpisodeBreakdownScreen({ route }:
  NativeStackScreenProps<RootStackParamList, 'episodeBreakdown'>
) {
  const { ph } = React.useContext(LanguageContext);
  const { path, subjectKind, subjectName } = route.params;
  const { categories } = React.useContext(ProjectContext);
  const { user } = React.useContext(UserContext);
  const { navigate } = useNavigation();
  const subject =
    React.useMemo(() => ({ subjectKind, subjectName }), [subjectKind, subjectName]);
  const sheetCategories = React.useMemo(
    () => categories.filter(
      category =>
        category in sheetCategorySpecs &&
        'newLookPh' in sheetCategorySpecs[category as SheetCategory]
    ) as SheetCategory[],
    [categories]
  );
  const episodeRef = useDocumentReference(path);
  const episode = useSnapshot(
    transformSnapshot(_.bind(episodeRef.onSnapshot, episodeRef), toEpisode),
    [episodeRef]
  );
  const sceneSheets = useSnapshot<SceneSheets[] | undefined>(
    _.curry(onEpisodeSceneSheets)(episodeRef, sheetCategories, subject),
    [episodeRef, sheetCategories, subject]
  );
  const episodeLookSheets = useSnapshot<Record<SheetCategory, DocumentSnapshot[]> | undefined>(
    _.curry(onEpisodeLookSheets)(episodeRef, sheetCategories, subject),
    [episodeRef, sheetCategories, subject]
  );
  const episodeLooksRef = React.useMemo(
    () => getEpisodeLooksRef(episodeRef, subject),
    [episodeRef, subject]
  );
  const newEpisodeLookAction = React.useCallback(
    (category: SheetCategory) => {
      return () => {
        if (!episodeLooksRef) return;
        const newDoc = episodeLooksRef.doc();
        newDoc.set({
          createdBy: user.ref,
          createdAt: FieldValue.serverTimestamp(),
          lastModifiedBy: user.ref,
          lastModifiedAt: FieldValue.serverTimestamp(),
          order: episodeLookSheets ? episodeLookSheets[category].length : 0,
          deleted: false,
          category
        });
        navigate('look', {
          isNew: 'true',
          path: newDoc.path,
          subjectKind,
          subjectName,
          episodePath: path
        });
      }
    },
    [episodeLooksRef, user.id, navigate, episodeLookSheets]);
  const inkColours = useUserInkColours();
  const project = useDocument(useProject());

  return (
    <DetailPage title={`${subjectName} - ${episodeLabel(ph, path)}`}
      headerRight={
        <ScriptButton wholeEpisode
          parent={episode}
          subjectKind={subjectKind}
          subjectName={subjectName}
        />
      }
    >
      <View>
        <UserColoursKey value={inkColours} />
        <SectionHeader title={ph('episode-looks') as string}>
          {!episodeLookSheets ?
            <ActivityIndicator style={{ marginLeft: 8 }} /> :
            <NewSheetOrLookButton
              kind="episode"
              restrictToCategories={sheetCategories}
              actionForCategory={newEpisodeLookAction} />
          }
        </SectionHeader>
        {Object.keys(episodeLookSheets ?? {}).map(category =>
          <BreakdownSheets
            key={category}
            sheets={episodeLookSheets![category as SheetCategory]}
            subjectKind={subjectKind} subjectName={subjectName}
            episodePath={path} sheetCategories={sheetCategories}
            inkColours={inkColours}
            horizontal={
              _.get(
                project?.get('breakdownSettings'),
                ['look', category, 'horizontal']
              ) ? true : false}
          />
        )}
        <SectionHeader title={ph('scenes') as string} />
        {sceneSheets ?
          sceneSheets.map(({ scene, sheets }, i) =>
            <BreakdownSheets
              key={scene.id}
              scene={scene}
              prevSheets={i > 0 ? sceneSheets[i - 1].sheets : undefined}
              sheets={sheets}
              subjectKind={subjectKind} subjectName={subjectName}
              episodePath={path} sheetCategories={sheetCategories}
              inkColours={inkColours}
            />) :
          <ProgressBar indeterminate />
        }
      </View>
    </DetailPage>
  );
}
