import React from "react";
import NavPage from "../components/pages/NavPage";
import { useProject } from "../data/useProject";
import { RootTabScreenProps } from "../types";
import { formatDate } from "../common/util";
import { Platform, View } from "react-native";
import { useReactToPrint } from "react-to-print";
import ErrorBoundary from "../components/ErrorBoundary";
import dayjs from "dayjs";
import { DateRangePicker } from "../components/DateRangePicker";
import { Button, Card, IconButton, ProgressBar, SegmentedButtons } from "react-native-paper";
import { useTeams } from "../data/team";
import { BreakdownTable, BreakdownTableSection } from "../components/BreakdownTable";
import { useStoredPref } from "../data/useStoredPref";
import { onDaysWithScenes, onFilmingDays } from "../common/day";
import { ProjectContext } from "../data/projectContext";
import { TeamsSelector } from "../components/TeamsSelector";
import { useSnapshot } from "../data/useSnapshot";
import _ from "lodash";
import { ExportButton } from "../components/ExportButton";
import { DocumentSnapshot, Timestamp } from "@atgof-firebase/firebase";
import LanguagePicker from "../components/LanguagePicker";
import { defaultLang, Language } from "@atgof-common/phrases";
import { LanguageContext } from "@atgof-common/language";

async function print(printWindow: HTMLIFrameElement) {
  const printContent = printWindow.contentDocument || printWindow.contentWindow?.document;
  for (const elt of printContent?.getElementsByTagName('div') ?? []) {
    if (elt.style.overflow === "scroll") elt.style.removeProperty('overflow');
  }
  printWindow.contentWindow?.print();
}

export function useSelectedTeams() {
  const availableTeams = useTeams();
  const availableTeamPaths = React.useMemo(() => availableTeams?.map(doc => doc.ref.path), [availableTeams]);
  const [teamPaths, setTeamPaths] = useStoredPref<string[]>("ScheduleScreen.teams", []);
  const teams = React.useMemo(
    () => availableTeams ?
      teamPaths?.map(path => availableTeams.find(doc => doc.ref.path === path))
        .filter(x => x) as DocumentSnapshot[] | undefined :
      undefined,
    [availableTeams, teamPaths]);
  React.useEffect(() => {
    if (availableTeamPaths && availableTeamPaths.length == 1) setTeamPaths([availableTeamPaths[0]]);
    else setTeamPaths(ts => ts?.filter(team => availableTeamPaths?.includes(team)) ?? null);
  }, [availableTeamPaths?.join('\n')]);
  return { availableTeams, teams, teamPaths, setTeamPaths };
}

function CallSheetElementsExportButton({ startDay, endDay, teams }: {
  startDay: dayjs.Dayjs;
  endDay: dayjs.Dayjs;
  teams: DocumentSnapshot[]
}) {
  const { ph } = React.useContext(LanguageContext);
  const [language, setLanguage] = React.useState<Language>(defaultLang);
  const [teamPaths, setTeamPaths] = React.useState<string[] | null>(null);
  const teamRef = teamPaths?.length ?
    teams.find(team => team.ref.path === teamPaths[0])?.ref : undefined;
  return (
    <ExportButton
      kind="callSheetElements"
      phrases={{
        generate: 'generate-call-sheet-elements',
        generating: 'generating-document',
        unableToGenerate: 'unable-to-generate-document',
        generated: 'generated-document',
        download: 'download-document'
      }}
      setupComponent={(exportWithExtraData, dismiss) =>
        <Card>
          <Card.Content>
            <View style={{ gap: 8 }}>
              <TeamsSelector
                availableTeams={teams}
                selectedTeamPaths={teamPaths} setSelectedTeamPaths={setTeamPaths}
                single={true}
              />
              <View style={{ alignItems: "center" }}>
                <LanguagePicker
                  language={language}
                  setLanguage={setLanguage}
                />
              </View>
            </View>
          </Card.Content>
          <Card.Actions>
            <Button mode="text" onPress={dismiss}>
              {ph('cancel') as string}
            </Button>
            {teamRef ?
              <Button onPress={() => exportWithExtraData({
                startDate: startDay.toDate(),
                endDate: endDay.toDate(),
                team: teamRef,
                parameters: { lang: language }
              })}>
                {ph('generate-call-sheet-elements') as string}
              </Button> : null}
          </Card.Actions>
        </Card>
      }
    />
  );
}

export default function ScheduleScreen({ }: RootTabScreenProps<'schedule'>) {
  const project = useProject();
  const { categories, characters } = React.useContext(ProjectContext);
  const isPostProduction = categories.includes('postProduction');
  const { availableTeams, teams, teamPaths, setTeamPaths } = useSelectedTeams();
  const [filterCharacters, setFilterCharacters] = React.useState<string[]>();
  const componentRef = React.useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    print
  });
  const defaultStartDay = isPostProduction ? dayjs().subtract(7, 'day') : dayjs();
  const [startDay, setStartDay] = React.useState(defaultStartDay);
  const [endDay, setEndDay] = React.useState(defaultStartDay.add(7, 'day'));
  React.useEffect(() => {
    if (endDay < startDay) setEndDay(startDay.add(1, 'day'))
  }, [startDay]);
  const days = useSnapshot(
    _.curry(onFilmingDays)(
      project,
      startDay.isValid() ?
        Timestamp.fromMillis(startDay.toDate().getTime() - 3600000) : // TODO This is probably a timezone workaround, and shouldn't be required
        undefined,
      endDay.isValid() ? Timestamp.fromMillis(endDay.toDate().getTime()) : undefined
    ),
    [project, startDay, endDay]
  );
  const [sections, setSections] = React.useState<BreakdownTableSection[]>();
  React.useEffect(
    () => onDaysWithScenes(
      days, teams,
      daysWithScenes => setSections(
        daysWithScenes?.map(({ day, scenes }) => ({
          key: day.id,
          label: formatDate(new Date(day.id), true) +
            (
              availableTeams && availableTeams.length > 1 ?
                (' – ' + (teams?.map(team => team.id).sort().join('/') ?? '')) : ''
            ),
          entries: filterCharacters ? scenes?.filter(scene => scene.cast?.some(character => filterCharacters.includes(character))) : scenes
        }))
      )
    ),
    [project, days, teams, categories, availableTeams, filterCharacters]
  );
  return (
    <NavPage
      headerRight={
        <View style={{ flexDirection: "row" }}>
          {categories.includes('admin') && availableTeams ?
            <CallSheetElementsExportButton
              startDay={startDay}
              endDay={endDay}
              teams={availableTeams}
            /> : null}
          {Platform.OS === 'web' ?
            <IconButton icon="printer" onPress={handlePrint} /> : null}
        </View>
      }
      fullScreen={Platform.OS === 'web'}
    >
      <DateRangePicker
        startDay={startDay} setStartDay={setStartDay} endDay={endDay} setEndDay={setEndDay} />
      <ErrorBoundary>
        <View style={{ marginTop: 8, gap: 8, flex: 1 }}>
          {availableTeams?.length == 1 ?
            null :
            <TeamsSelector
              availableTeams={availableTeams}
              selectedTeamPaths={teamPaths} setSelectedTeamPaths={setTeamPaths}
              single={false}
            />}
          {/*characters.length ?
            <SegmentedButtons
              value={}
              onValueChange={}
              buttons={
                [
                  { value: 'me', label: ph('me') },
                  { value: 'everyone', label: ph('everyone') }
                ]
              }
            /> :*/
            null}
          {sections ?
            <View ref={componentRef} style={{
              flex: 1
            }}>
              <BreakdownTable sections={sections} />
            </View> :
            teams?.length ? <ProgressBar indeterminate /> : null}
        </View>
      </ErrorBoundary>
    </NavPage>
  );
}
