import dayjs from 'dayjs';
import "dayjs/locale/en-gb";
import "dayjs/locale/cy";
import localeData from 'dayjs/plugin/localeData';
import { Language, Phrase, PhraseKey } from './types';
import { phrases, languages as langs } from '../generated/phrases';

export {
  PhraseKey, PhraseFunction, Phrase, Language, PhraseLookupFunction
} from "./types";
export { phrases } from '../generated/phrases';

dayjs.extend(localeData);

export const languages = langs.map(lang => ({
  lang,
  label: phrases['language-label'][lang],
  months: dayjs().locale(lang).localeData().months()
}));

export const defaultLang = languages[0].lang;

const mutations = (function() {
  const tr: Record<string, string> = {
    'f': 'm',
    'o': 'go',
    'd': 't',
    'r([^h])': 'rh$1'
  };
  return Object.keys(tr).map(pattern => (
    { re: new RegExp('^' + pattern), s: tr[pattern] }
  ));
})();


export function canonicalMonthName(lang: Language, monthName: string) {
  monthName = monthName.toLocaleLowerCase();
  if (lang === 'cy') {
    return mutations
      .reduce((monthName, { re, s }) => monthName.replace(re, s), monthName);
  }
  return monthName;
}

export const TIME_OF_DAY_PHRASE_KEYS = ['day', 'night', 'dawn', 'dusk'] as const;
export type TimeOfDay = typeof TIME_OF_DAY_PHRASE_KEYS[number];

function ph_(language: string, key: PhraseKey): Phrase {
  const definition = phrases[key];
  return definition && definition[language];
}

export function ph(language: string, key: PhraseKey): Phrase {
  const phrase = ph_(language, key) || ph_("default", key);
  if (!phrase) throw new Error("Phrase " + key + " not found in " + language);
  return phrase;
};

export function safePh(language: string, key: PhraseKey): Phrase {
  return ph_(language, key) || ph_("default", key) || ph_(defaultLang, key);
}

export function listOfItems(combinationWord: string, items: string[]) {
  const lastIdx = items.length - 1;
  if (lastIdx < 0) return '';
  if (lastIdx == 0) return items[0];
  return items.slice(0, lastIdx).join(', ') + ' ' + combinationWord + ' ' + items[lastIdx];
}
