import { CollectionReference, DocumentReference, DocumentSnapshot, Firestore } from '@atgof-firebase/firebase';
import React from 'react';
import { PhraseKey } from '../common/phrases';
import { UploadMetadata, UploadStatus } from './backend/uploader';
import { DownloadsInfo, referencePathForScene } from './backend/downloader';
import { UserContext } from './userContext';
import { MembershipCategory } from '../common/model/project/membership';
import { StorageManagerImpl } from './backend/storage-manager-impl';
import { uploadUri } from '../config/firebase';
import { onFirestoreSyncState } from '@atgof-firebase/util';

export const storageManager = new StorageManagerImpl(uploadUri);

export type Action = {
  descriptionPhraseKey?: PhraseKey;
  error?: any
}

export type TransactFunction<T> = (
  descriptionPhraseKey: PhraseKey,
  transaction: () => Promise<T>
) => Promise<T>

export function useTransact<T>() { // TODO Merge with undoAction in NavPage
  const [latestAction, setLatestAction] = React.useState<Action>();
  const transact = React.useCallback<TransactFunction<T>>(
    async (descriptionPhraseKey, transaction) => {
      const action = { descriptionPhraseKey };
      setLatestAction(action);
      try {
        const result = await transaction();
        return result;
      }
      catch (e) {
        if (setLatestAction) setLatestAction({ ...action, error: e });
        return Promise.reject(e);
      }
    },
    [setLatestAction]
  );
  return { latestAction, transact };
}

type LegacyUploadRef = { // TODO Remove this once FileImportsScreen doesn't use it
  uploadIdentifier: string,
  collectionRef: CollectionReference
}
type UploadRef = {
  docRef: DocumentReference
} | LegacyUploadRef

export async function uploadFile(
  project: DocumentReference,
  category: MembershipCategory,
  user: DocumentSnapshot,
  uploadRef: UploadRef,
  input: string | File,
  metadata: UploadMetadata
) {
  const docRef = 'uploadIdentifier' in uploadRef ?
    uploadRef.collectionRef.doc(uploadRef.uploadIdentifier) : uploadRef.docRef;
  const storagePath = [project.id, category, user.id, docRef.id].join('/');
  return storageManager.uploader.uploadFile(
    input, storagePath, docRef, user, true, metadata
  );
}

export async function getScript(
  sceneRef: DocumentReference,
  path: string
): Promise<string> {
  return storageManager.downloader.retrieveAsString(
    path, 'scripts', referencePathForScene(sceneRef, 'scripts')
  );
}

export async function onBackendConnected(user: DocumentSnapshot) {
  return storageManager.uploader.onUserConnected(user);
}

export function useHasPendingWrites() {
  const { user } = React.useContext(UserContext);
  const [hasPendingWrites, setHasPendingWrites] = React.useState(false);
  React.useEffect(
    () => user ?
      onFirestoreSyncState(user.ref.firestore, setHasPendingWrites) : undefined,
    [user !== undefined]
  );
  return hasPendingWrites;
}

export function useUploads() {
  const [uploads, setUploads] = React.useState<UploadStatus[]>([]);
  React.useEffect(() => storageManager.uploader.onUploads(setUploads), []);
  return uploads;
}

export function useDownloads() {
  const [downloads, setDownloads] = React.useState<DownloadsInfo>({
    downloadCount: 0, queuedCount: 0
  });
  React.useEffect(() => storageManager.downloader.onDownloads(setDownloads), []);
  return downloads;
}
