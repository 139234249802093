import { DocumentReference, DocumentSnapshot, Timestamp, QuerySnapshot } from '@atgof-firebase/types';
import { onScenes } from './scene';
import { Scene } from './scene';
import { onSnapshot } from './onSnapshot';
import _ from "lodash";

export type DayWithScenes = {
  day: DocumentSnapshot,
  scenes: Scene[] | undefined
}

export function onFilmingDays(
  project: DocumentReference | undefined,
  startDate: Timestamp | undefined,
  endDate: Timestamp | undefined,
  onNext: (next: (DocumentSnapshot[] | undefined)) => void
) {
  const q = project && startDate && endDate ?
    project?.collection('days')
      .where('filmingDate', '>=', startDate)
      .where('filmingDate', '<=', endDate)
      .orderBy('filmingDate', 'asc') :
    undefined;
  return onSnapshot(
    q ? _.bind(q.onSnapshot, q) : undefined,
    ({ docs }: QuerySnapshot) => docs,
    onNext
  );
}

export function onDaysWithScenes(
  days: DocumentSnapshot[] | undefined,
  teams: DocumentSnapshot[] | undefined,
  onNext: (next: (DayWithScenes[] | undefined)) => void
): () => void {
  let unsubs: (() => void)[] = [];
  const innerUnsubs = new Map<number, () => void>();
  onNext(undefined);
  if (days && teams?.length) {
    const value: DayWithScenes[] = days.map(day => ({ day, scenes: undefined }));
    onNext(value);
    for (let i = 0; i < value.length; i++) {
      unsubs.push(
        value[i].day.ref.collection('scenes')
          .where('team', 'in', teams.map(({ ref }) => ref))
          .where('deleted', '==', false)
          .orderBy('orderInFilmingDate', 'asc')
          .onSnapshot(({ docs }) => {
            const oldUnsub = innerUnsubs.get(i);
            if (oldUnsub) oldUnsub();
            innerUnsubs.set(i, onScenes(docs, teams, scenes => {
              value[i].scenes = scenes;
              onNext(value)
            }));
          })
      );
    }
  }
  return () => {
    for (const unsub of innerUnsubs.values()) unsub();
    for (const unsub of unsubs) unsub();
  };
}
