import React from "react";
import { LayoutRectangle, Modal, SafeAreaView } from "react-native";
import { ImageViewHeaderProps, ImageViewHeader } from "./ImageViewHeader";
import { Image } from "expo-image";
import { ReactNativeZoomableView } from "@openspacelabs/react-native-zoomable-view";
import { PagerView } from "./PagerView";
import { useTheme } from "react-native-paper";
import { DocumentSnapshot } from "@atgof-firebase/types";
import { useImage } from "../data/image";

function ZoomableImage({ doc, referencePath, onLoad }: {
  doc: DocumentSnapshot;
  referencePath: string | undefined;
  onLoad: () => void
}) {
  const {
    image, registerAsCached,
    registerFailure, registerCompletion
  } = useImage(doc, referencePath);
  const zoomable = React.useRef<ReactNativeZoomableView>(null);
  const [imageLayout, setImageLayout] = React.useState<LayoutRectangle>();
  if (!image) return null;
  const { source, blurhash, thumbnailSource } = image;
  return (
    <ReactNativeZoomableView
      ref={zoomable}
      style={{ width: "100%", height: "100%" }}
      initialZoom={1}
      bindToBorders={true}
      disablePanOnInitialZoom={true}
      minZoom={1}
      maxZoom={10}
      contentWidth={imageLayout?.width}
      contentHeight={imageLayout?.height}
    >
      <Image
        onLayout={evt => setImageLayout(evt.nativeEvent.layout)}
        placeholder={{
          blurhash,
          ...thumbnailSource,
          width: source.width,
          height: source.height
        }}
        placeholderContentFit="contain"
        source={source}
        cachePolicy="disk"
        priority="high"
        contentFit="contain"
        style={{
          width: "100%",
          height: "100%"
        }}
        onLoad={evt => {
          registerAsCached(evt.cacheType === 'disk');
          registerCompletion();
          onLoad();
        }}
        onError={evt => { registerFailure(evt.error); }}
      />
    </ReactNativeZoomableView>
  );
}

export default function ImageView(
  {
    images, imageIndex, setImageIndex, visible, referencePath, onRequestClose,
    ...props
  }:
    ImageViewHeaderProps & {
      referencePath: string | undefined;
      visible: boolean
    }
) {
  const { colors } = useTheme();
  const pager = React.useRef<PagerView>(null);
  const [loaded, setLoaded] = React.useState(false);
  const [paginationEnabled, setPaginationEnabled] = React.useState(true);
  React.useEffect(() => {
    pager.current?.setPage(imageIndex);
  }, [imageIndex]);
  React.useEffect(
    () => setImageIndex(index =>
      index == null || !images.length ? index :
        (index >= images.length ? images.length - 1 : index)
    ),
    [images.length]
  );
  React.useEffect(() => setLoaded(false), [images, imageIndex, visible]);
  if (!visible) return null;
  if (!(images.length && imageIndex < images.length)) {
    onRequestClose();
    return null;
  }
  return (
    <Modal
      visible={visible}
      onRequestClose={onRequestClose}
      style={{
        width: "100%", height: "100%",
        marginTop: 20
      }}
    >
      <SafeAreaView style={{
        height: "100%",
        backgroundColor: colors.background
      }}>
        <ImageViewHeader
          images={images} imageIndex={imageIndex} setImageIndex={setImageIndex}
          onRequestClose={onRequestClose}
          loading={!loaded}
          {...props}
        />
        <PagerView
          ref={pager}
          initialPage={imageIndex}
          style={{ width: "100%", height: "100%" }}
          onPageSelected={evt => {
            setPaginationEnabled(true);
            setImageIndex(evt.nativeEvent.position);
          }}
          onDismiss={onRequestClose}
          scrollEnabled={paginationEnabled}
        >
          {images.map(image =>
            <ZoomableImage
              key={image.id}
              doc={image}
              referencePath={referencePath}
              onLoad={() => setLoaded(true)}
            />)}
        </PagerView>
      </SafeAreaView>
    </Modal>
  );
}
