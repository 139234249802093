import React from "react";
import { NativeScrollEvent, NativeSyntheticEvent, ScrollView } from "react-native";

type OffsetForHeight = {
  height: number;
  scroll: {
    offset: number;
    timestamp: number;
  } | undefined
}

const leewayMilliseconds = 500;

export function OffsetPreservingScrollView({ children }: { children: React.ReactNode }) {
  const scrollView = React.useRef<ScrollView>(null);
  const history = React.useRef<OffsetForHeight[]>();
  function updateHistory(evt: NativeSyntheticEvent<NativeScrollEvent>) {
    const offset = evt.nativeEvent.contentOffset.y;
    const timestamp = new Date().getTime();
    console.log("scroll", offset, timestamp);
    if (!history.current?.length) return;
    const height = history.current[0].height;
    history.current = [
      {
        height,
        scroll: { offset, timestamp }
      },
      history.current[0],
      ...history.current.slice(1)
    ];
  }
  function onContentSizeChange(_: number, height: number) {
    const now = new Date().getTime();
    if (!(history.current?.length && history.current[0].height === height)) {
      history.current = [
        {
          height, scroll: undefined
        },
        ...(history.current ?? []).filter(
          entry => entry.scroll
        ).slice(0, 2)
      ];
    }
    const entry = history.current.find(entry =>
      entry.height === height &&
      entry.scroll &&
      now - entry.scroll.timestamp > leewayMilliseconds
    );
    if (entry?.scroll) {
      scrollView.current?.scrollTo(
        {
          x: 0,
          y: entry.scroll.offset,
          animated: false
        }
      );
    }
  }
  return (
    <ScrollView
      ref={scrollView}
      scrollEventThrottle={500}
      onScroll={updateHistory}
      onContentSizeChange={onContentSizeChange}
    >
      {children}
    </ScrollView>
  );
}
