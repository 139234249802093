import { useNavigation } from "@react-navigation/native";
import React from "react";
import { deleteDocument, undeleteDocument } from "./util";
import { UserContext } from "./userContext";
import { DocumentReference, DocumentSnapshot } from "@atgof-firebase/types";

export type UndoAction = { undo: () => void };

type UndoState = {
  undoAction: UndoAction | undefined;
  setUndoAction: (action: UndoAction | undefined) => void
}

export const UndoContext = React.createContext({} as UndoState);

export function useUndoState() {
  const navigation = useNavigation();
  const undoSetAt = React.useRef<number>();
  const [undoAction, setUndoAction_] = React.useState<UndoAction>();
  React.useEffect(
    () => navigation.addListener('state', () => {
      if (undoSetAt.current) {
        const now = new Date().getTime();
        if (now - undoSetAt.current > 60000 /* 1 minute */) {
          undoSetAt.current = undefined;
          setUndoAction_(undefined);
        }
      }
    }),
    [navigation]
  );
  const setUndoAction = React.useCallback(
    (action: UndoAction | undefined) => {
      undoSetAt.current = new Date().getTime();
      setUndoAction_(action);
    },
    [setUndoAction_]
  );
  return React.useMemo(
    () => ({ undoAction, setUndoAction }),
    [undoAction, setUndoAction]
  );
}

export function useUndoableDocumentDeleter() {
  const { user } = React.useContext(UserContext);
  const [toRestore, setToRestore] = React.useState<DocumentReference>();
  const undo = React.useMemo(
    () => toRestore ?
      async () => {
        await undeleteDocument(toRestore, user.ref);
        setToRestore(undefined);
      } :
      undefined,
    [toRestore, user]
  );
  const undoableDelete = React.useCallback(
    async (docRef: DocumentReference) => {
      await deleteDocument(docRef, user.ref);
      setToRestore(docRef);
    },
    [user, setToRestore]
  );
  return { undo, undoableDelete };
}
