import { CollectionReference, DocumentReference, DocumentSnapshot } from "@atgof-firebase/types";
import { useDocuments } from "./useDocuments";
import { Entity, Spec } from "../common/model/Spec";
import { useDocument } from "./useDocument";

export function entityFromSnapshot<T>(spec: Spec<T>, doc: undefined): undefined;
export function entityFromSnapshot<T>(spec: Spec<T>, doc: DocumentSnapshot): Entity<T>;
export function entityFromSnapshot<T>(spec: Spec<T>, doc: DocumentSnapshot | undefined): Entity<T> | undefined;

export function entityFromSnapshot<T>(
  spec: Spec<T>,
  doc: DocumentSnapshot | undefined
): Entity<T> | undefined {
  if (doc === undefined) return;
  return spec.fromData(doc.ref.path.split('/'), doc.data(), doc.ref);
}

export function useEntity<T>(spec: Spec<T>, ref: DocumentReference): Entity<T>;
export function useEntity<T>(spec: Spec<T>, ref: undefined): undefined;
export function useEntity<T>(spec: Spec<T>, ref: DocumentReference | undefined): Entity<T> | undefined;

export function useEntity<T>(
  spec: Spec<T>,
  ref: DocumentReference | undefined
): Entity<T> | undefined {
  return entityFromSnapshot(spec, useDocument(ref));
}

export function useEntities<T>(
  spec: Spec<T>,
  ref: CollectionReference | undefined
): Entity<T>[] | undefined {
  const docs = useDocuments(ref);
  return docs?.map(doc => entityFromSnapshot(spec, doc));
}


