import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React from "react";
import { View } from "react-native";
import { LanguageContext } from "../common/language";
import NavPage from "../components/pages/NavPage";
import { useProject } from "../data/useProject";
import { RootStackParamList } from "../types";
import { useNavigation } from "@react-navigation/native";
import { Button, ProgressBar } from "react-native-paper";
import { FileUploads } from "../components/FileUploads";

export default function FileImportsScreen({ }: NativeStackScreenProps<RootStackParamList, 'fileImports'>) {
  const { navigate } = useNavigation();
  const { ph } = React.useContext(LanguageContext);
  const project = useProject();
  return (
    <NavPage>
      <View style={{ marginTop: 16 }}>
        <FileUploads
          collectionRef={project?.collection('imports')}
          accept={['msd', 'fdx']}
          extraInitialData={{ importComplete: false }}
          restrict={q => q.where('importComplete', '==', false)}
          completedUploadElement={({ id }) =>
            <Button onPress={() => navigate('fileImport', { id })}>
              {ph('import') as string}
            </Button>}
        />
      </View>
    </NavPage>
  );
}
