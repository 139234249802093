import { useNavigation } from "@react-navigation/native";
import { useHeaderHeight } from "@react-navigation/elements";
import React, { ReactNode } from "react";
import { ScrollView, View } from "react-native";
import { Button, IconButton } from "react-native-paper";
import { Action } from "../../data/backend";
import { ProjectsContext } from "../../data/projectsContext";
import { LanguageContext } from "../../common/language";
import ErrorBoundary from "../ErrorBoundary";
import ErrorStrip from "../ErrorStrip";
import LoadingView from "../LoadingView";
import NewSheetButton, { NewSheetContext } from "../NewSheetButton";
import OpenDrawerButton from "../OpenDrawerButton";
import ProjectChooser from "../ProjectChooser";
import SoftwareUpdatePrompt from "../SoftwareUpdatePrompt";
import { UserContext } from "../../data/userContext";
import { UndoBanner } from "../UndoBanner";
import { DocumentReference } from "@atgof-firebase/types";
import { deleteDocument, undeleteDocument } from "../../data/util";
import { UndoAction, UndoContext } from "../../data/undo";
import { DeleteButton } from "../DeleteButton";
import { SyncIndicator, SyncModal } from "../SyncIndicator";

type EditStateListener<T> = (editState: T | undefined) => void

export type Editor<T> =
  {
    onStateChange: EditStateListener<T>;
    registerListener: (listener: EditStateListener<T> | undefined) => void;
    finishEditingPhrase: string | undefined;
    defaultMode: T
  } | undefined;

export function useEditor<T>(
  defaultMode: T | null,
  finishEditingPhrase?: string
) {
  const [editingMode, setEditingMode_] = React.useState<T>();
  const listenerRef = React.useRef<EditStateListener<T>>();
  const registerListener = React.useCallback(
    (listener: EditStateListener<T> | undefined) => {
      listenerRef.current = listener;
      return () => {
        listenerRef.current = undefined;
      };
    },
    [listenerRef]
  );
  const editor = React.useMemo<Editor<T> | null>(
    () => {
      if (!defaultMode) return null;
      return {
        onStateChange: setEditingMode_,
        registerListener,
        finishEditingPhrase,
        defaultMode
      };
    },
    [defaultMode, setEditingMode_, registerListener, finishEditingPhrase]);
  const setEditingMode = React.useCallback(
    (newEditingMode: T | undefined) => {
      setEditingMode_(newEditingMode);
      if (listenerRef.current) listenerRef.current(newEditingMode);
    },
    [setEditingMode_]
  );
  return { editingMode, setEditingMode, editor };
}

type DeleteItemResult = {
  promise: Promise<void> | undefined;
  undo: (() => void) | undefined
};
export type DeleteItemFn = () => DeleteItemResult

type CommonHeaderProps<E> = {
  isLoading?: boolean;
  editor?: Editor<E> | null;
  deleteItem?: DeleteItemFn | DocumentReference;
  addItem?: () => void;
  onDone?: () => void;
  isDoing?: boolean;
  onCancel?: () => void;
  newSheetContext?: NewSheetContext;
  showNewSheetButtonRegardless?: boolean;
}


function Header<E>({
  isLoading, editor, deleteItem, addItem, onDone, isDoing,
  onCancel, newSheetContext, children, showNewSheetButtonRegardless,
  setUndoAction, toggleSyncModal
}: CommonHeaderProps<E> & {
  setUndoAction: (undo: UndoAction | undefined) => void;
  toggleSyncModal: () => void;
  children: ReactNode
}) {
  const { ph } = React.useContext(LanguageContext);
  const { user } = React.useContext(UserContext);
  const { goBack } = useNavigation();
  const [editingMode, setEditingMode] = React.useState<E>();
  const onStateChange = React.useRef<EditStateListener<E>>();
  React.useEffect(
    () => {
      onStateChange.current = editor?.onStateChange;
      if (editor?.registerListener) {
        editor.registerListener(setEditingMode);
        return () => editor.registerListener(undefined);
      }
    },
    [editor]
  );
  const toggleEditing = React.useCallback(
    () => {
      const newEditingMode = editingMode === undefined ?
        editor?.defaultMode : undefined;
      setEditingMode(newEditingMode);
      if (onStateChange.current) onStateChange.current(newEditingMode);
    },
    [setEditingMode, editingMode, editor?.defaultMode]
  );
  const deleteAction = React.useCallback(
    async () => {
      if (!deleteItem) return;
      const { promise, undo } =
        typeof deleteItem === 'function' ?
          deleteItem() :
          {
            promise: deleteDocument(deleteItem, user.ref),
            undo: () => {
              undeleteDocument(deleteItem, user.ref)
            }
          };
      if (promise) await promise;
      setUndoAction(undo ? {
        undo: () => {
          undo();
          setUndoAction(undefined);
        }
      } : undefined);
      goBack(); // TODO Can't we do this before await promise?
    },
    [deleteItem, user]
  );
  return (
    <View style={{ flexDirection: 'row', marginRight: 8, alignItems: 'center' }}>
      <SyncIndicator onPress={toggleSyncModal} />
      {!onCancel && (newSheetContext || showNewSheetButtonRegardless) &&
        <NewSheetButton {...newSheetContext} />}
      {!isLoading && children}
      {
        !isLoading && deleteItem &&
        <DeleteButton onPress={deleteAction} />
      }
      {
        !isLoading && addItem &&
        <IconButton icon="plus" onPress={addItem} />
      }
      {
        editor &&
        <Button onPress={toggleEditing}>
          {(editingMode ?
            ph(editor.finishEditingPhrase || 'cancel') : ph('edit')) as string}
        </Button>
      }
      {
        !isLoading && onCancel &&
        <Button
          disabled={onDone === undefined} loading={isDoing}
          onPress={onDone}>
          {ph('done') as string}
        </Button>
      }
    </View>
  );
}

export type NavPageProps<E> = CommonHeaderProps<E> & {
  children?: ReactNode;
  title?: string;
  headerRight?: ReactNode;
  headerStrip?: ReactNode;
  fullScreen?: boolean;
  noMargin?: boolean;
  latestAction?: Action | undefined
}

export default function NavPage<E>(
  {
    children, title, isLoading, editor,
    deleteItem, addItem, onDone, isDoing, onCancel,
    headerRight, headerStrip, fullScreen, noMargin, newSheetContext,
    latestAction,
    showNewSheetButtonRegardless = true
  }
    : NavPageProps<E>
) {
  const navigation = useNavigation();
  const { ph } = React.useContext(LanguageContext);
  const { projects } = React.useContext(ProjectsContext);
  const { undoAction, setUndoAction } = React.useContext(UndoContext);
  const syncModalTop = useHeaderHeight();
  const [showSyncModal, setShowSyncModal] = React.useState(false);
  const toggleSyncModal = React.useCallback(
    () => setShowSyncModal(show => !show),
    [setShowSyncModal]
  );
  React.useLayoutEffect(() => {
    navigation.setOptions({
      ...(title ? { title: title } : {}),
      ...(
        onCancel ? {
          headerLeft: () =>
            <Button onPress={onCancel}>
              {ph('cancel') as string}
            </Button>
        } :
          (
            projects?.length > 1 && navigation.getParent() ?
              {
                headerLeft: () =>
                  <React.Fragment>
                    <OpenDrawerButton navigation={navigation} />
                    <ProjectChooser />
                  </React.Fragment>
              } :
              {}
          )
      ),
      headerBackVisible: !onCancel,
      headerRight: () =>
        <Header
          isLoading={isLoading}
          editor={editor}
          deleteItem={deleteItem}
          addItem={addItem}
          onDone={onDone}
          isDoing={isDoing}
          onCancel={onCancel}
          newSheetContext={newSheetContext}
          showNewSheetButtonRegardless={showNewSheetButtonRegardless}
          setUndoAction={setUndoAction}
          toggleSyncModal={toggleSyncModal}
        >
          {headerRight}
        </Header>,
    });
  }, [
    navigation, title, onCancel, projects?.length, deleteItem, addItem, onDone,
    editor, headerRight, isLoading, newSheetContext, isDoing, toggleSyncModal
  ]);
  return (
    isLoading ?
      <LoadingView style={noMargin ? {} : { margin: 16 }} /> :
      <View
        style={{
          flex: 1,
          gap: 10,
          ...(noMargin ? {} : { paddingLeft: 16, paddingRight: 16 })
        }}
      >
        <ErrorBoundary><SoftwareUpdatePrompt /></ErrorBoundary>
        <ErrorBoundary>
          <View style={{ flexDirection: "row", justifyContent: "center" }}>
            <UndoBanner undo={undoAction?.undo} />
          </View>
        </ErrorBoundary>
        <ErrorBoundary>
          {headerStrip || <ErrorStrip latestAction={latestAction} />}
        </ErrorBoundary>
        <ErrorBoundary>
          <SyncModal
            style={{ top: syncModalTop }}
            visible={showSyncModal}
            onDismiss={() => setShowSyncModal(false)}
          />
        </ErrorBoundary>
        {
          fullScreen ? children :
            <ScrollView>
              <ErrorBoundary>{children}</ErrorBoundary>
            </ScrollView>
        }
      </View>
  );
}
