import { DocumentReference, DocumentSnapshot } from "@atgof-firebase/types";
import React from "react";
import { UserContext } from "./userContext";
import { storageManager, useUploads } from "./backend";
import { ImageSizeSuffix, withSizeSuffix } from '../common/sheet';
import { Image, ImageSource } from 'expo-image';
import { Unsubscribe } from "@atgof-common/onSnapshot";

type ImageEntry = {
  id: string;
  ref: DocumentReference;
  kind: string | undefined;
  notes: string | undefined;
  blurhash: string | undefined;
  thumbnailSource?: ImageSource;
  source: ImageSource;
  hasError?: boolean;
  inProgress?: 'upload' | 'download';
  isDownloaded?: boolean
}

function storageAttrsForImageDoc(doc: DocumentSnapshot | undefined) {
  const storagePath: string | undefined = doc?.get('path');
  const uploadCompleted = !!(doc?.get('uploadCompletedAt'));
  return { storagePath, uploadCompleted };
}

async function registerImageAsCached(
  referencePath: string | undefined,
  storagePath: string | undefined,
  sizeSuffix: ImageSizeSuffix | undefined,
  isOnDisk: boolean,
  uriToCacheIfNotOnDisk: string | undefined
) {
  if (!storagePath) return;
  const path = withSizeSuffix(storagePath, sizeSuffix);
  if (!path) return;
  const cachedURI = isOnDisk ?
    await Image.getCachePathAsync(path) : uriToCacheIfNotOnDisk;
  if (!cachedURI) return;
  await storageManager.downloader.registerAsCached(referencePath, path, cachedURI);
}

export function prefetchImages(
  referencePath: string | undefined,
  docs: DocumentSnapshot[] | undefined
): Unsubscribe {
  const { downloader, uploader } = storageManager;
  return downloader.prefetch(
    'images', referencePath,
    docs?.map(doc => {
      const { storagePath, uploadCompleted } = storageAttrsForImageDoc(doc);
      if (uploadCompleted) return storagePath;
    }),
    uploader
  );
}

export function useImage(
  doc: DocumentSnapshot | undefined,
  referencePath: string | undefined,
  onlyThumbnail?: boolean
) {
  const { uploader, downloader } = storageManager;
  const { user } = React.useContext(UserContext);
  const uploads = useUploads();
  const { storagePath, uploadCompleted } = storageAttrsForImageDoc(doc);
  const isUploading = uploads.some(
    upload => upload.storagePath === storagePath && !upload.error
  );
  const [entry, setEntry] = React.useState<ImageEntry>();
  React.useEffect(
    () => {
      let running = true;
      (async function(): Promise<void> {
        if (!doc) {
          setEntry(undefined);
          return;
        }
        const { id } = doc;
        const [width, height]: (number | undefined)[] =
          ['width', 'height'].map(k => doc.get(k));
        const image = {
          id,
          source: { width, height },
          ref: doc.ref,
          kind: doc.get('kind'),
          notes: doc.get('notes'),
          blurhash: doc.get('blurhash')
        };
        const variants: string[] | undefined = doc.get('variants');
        if (!storagePath) {
          setEntry({ ...image, hasError: true });
          return;
        }
        setEntry(image);
        const uploadURI = await uploader.getUriForStoragePath(storagePath);
        if (!running) return;
        if (uploadURI) {
          if (!(uploadCompleted || isUploading)) {
            uploader.uploadFile(
              null, storagePath, doc.ref, user, false,
              { mimeType: doc.get('mimeType') ?? 'image/jpeg' }
            );
          }
          setEntry({ ...image, source: { ...image.source, uri: uploadURI } });
          return;
        }
        if (!uploadCompleted) return;
        try {
          const thumbnailStoragePath = withSizeSuffix(storagePath, 'sm');
          const thumbnailUri = await downloader.getURI(
            thumbnailStoragePath,
            'images', referencePath,
            { priority: onlyThumbnail ? 1 : 0 }
          );
          if (!running) return;
          const imageWithThumbnail = {
            ...image,
            thumbnailSource: {
              width, height,
              uri: thumbnailUri,
              cacheKey: thumbnailStoragePath
            }
          };
          setEntry(imageWithThumbnail);
          if (onlyThumbnail) return;
          const uri = await downloader.getURI(
            withSizeSuffix(
              storagePath,
              variants?.includes('pr') ? 'pr' : undefined
            ),
            'images', referencePath,
            { priority: 1 }
          );
          if (!running) return;
          setEntry({
            ...imageWithThumbnail,
            source: {
              ...image.source,
              uri,
              cacheKey: storagePath
            }
          });
        }
        catch (error) {
          console.log(error);
          if (running) setEntry({ ...image, hasError: true });
        }
      })().catch(error => {
        // TODO
      });
      return () => {
        running = false;
        if (storagePath) downloader.registerCompletion(storagePath);
      };
    },
    [
      doc, referencePath, onlyThumbnail,
      user, storagePath, uploadCompleted, isUploading
    ]
  );
  const uriToCacheIfNotOnDisk = entry?.source.uri ?? entry?.thumbnailSource?.uri;
  const registerAsCached = React.useCallback(
    (isOnDisk: boolean) => {
      registerImageAsCached(
        referencePath,
        storagePath,
        onlyThumbnail ? 'sm' : undefined,
        isOnDisk,
        uriToCacheIfNotOnDisk
      )
    },
    [
      referencePath, storagePath, onlyThumbnail,
      uriToCacheIfNotOnDisk
    ]
  );
  const registerFailure = React.useCallback(
    (error: any) => {
      if (storagePath) downloader.registerFailure(storagePath, error);
    },
    [storagePath]
  );
  const registerCompletion = React.useCallback(
    () => {
      if (storagePath) downloader.registerCompletion(storagePath, false);
    },
    [storagePath]
  );
  return {
    image: entry,
    registerAsCached,
    registerFailure,
    registerCompletion
  };
}
