import { LanguageContext } from "@atgof-common/language";
import { Scene } from "@atgof-common/scene";
import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { Checkbox } from "react-native-paper";

export function PostProductionSceneOptions({
  scene, horizontal, style
}: {
  scene: Scene;
  horizontal?: boolean;
  style?: StyleProp<ViewStyle>
}) {
  const { ph } = React.useContext(LanguageContext);
  return (
    <View style={{ flexDirection: horizontal ? "row" : "column", alignItems: "flex-start" }}>
      <Checkbox.Item
        label={ph('ready-for-edit') as string}
        labelVariant="bodySmall"
        position="leading"
        status={scene.readyForEdit ? 'checked' : 'unchecked'}
        style={style}
        onPress={() => scene.ref.update({ readyForEdit: !scene.readyForEdit })}
      />
      <Checkbox.Item
        label={ph('edit-complete') as string}
        labelVariant="bodySmall"
        position="leading"
        status={scene.editComplete ? 'checked' : 'unchecked'}
        style={style}
        onPress={() => scene.ref.update({ editComplete: !scene.editComplete })}
      />
    </View>
  );
}
