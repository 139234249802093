import React from "react";
import { View } from "react-native";
import { DocumentSnapshot, DocumentReference } from "@atgof-firebase/firebase";
import PhotoStrip from "./PhotoStrip";
import SceneInfo from "./SceneInfo";
import { SheetSections } from "./SheetSections";
import { LanguageContext } from "../common/language";
import { SheetCategory } from "../common/sheet";
import { useNavigation } from "@react-navigation/native";
import NewSheetButton from "./NewSheetButton";
import { Button, Text, useTheme } from "react-native-paper";
import { SubjectSpec } from "../common/subject";
import { referencePathForLook, referencePathForScene } from "../data/backend/downloader";
import { Scene } from "../common/scene";
import { useSnapshot } from "../data/useSnapshot";
import { onSnapshots } from "../common/onSnapshot";
import { LookChip } from "./LookChip";
import { UserInkColour } from "@atgof-common/membership";

function SheetView({ scene, sheet, subject, episodePath, horizontal, inkColours }: {
  scene?: Scene;
  sheet: DocumentSnapshot;
  subject?: SubjectSpec;
  episodePath?: string;
  horizontal?: boolean;
  inkColours?: UserInkColour[]
}) {
  const { ph } = React.useContext(LanguageContext);
  const { colors } = useTheme();
  const { navigate } = useNavigation();
  const category = sheet.get('category');
  const displayName = sheet.get('displayName');
  const description = sheet.get('description');
  return (
    <View>
      {displayName || description ?
        <View style={{
          margin: 4,
        }}>
          {displayName &&
            <Text
              variant="headlineSmall"
              style={{
                borderBottomColor: colors.onBackground, borderBottomWidth: 1,
                marginBottom: 8
              }}
            >
              {displayName}
            </Text>}
          {description && <Text variant="titleMedium" style={{}}>{description}</Text>}
        </View> : null
      }
      <PhotoStrip
        sheetRef={sheet.ref} sheetCategory={category}
        referencePath={
          scene ? referencePathForScene(scene?.ref, sheet.id) :
            referencePathForLook(sheet.ref)
        }
        style={{ marginTop: 2, marginBottom: 2 }} readonly
        horizontal={horizontal}
      />
      <SheetSections doc={sheet} category={category} readonly inkColours={inkColours} />
      {
        subject &&
        <View style={{ flexDirection: "row" }}>
          <Button onPress={() => {
            if (scene)
              navigate('sheet', { path: sheet.ref.path });
            else
              navigate('look', { path: sheet.ref.path, episodePath, ...subject });
          }}>
            {ph('edit') as string}
          </Button>
        </View>
      }
    </View >
  );
}

function useSnapshots(refs: DocumentReference[]) {
  return useSnapshot<DocumentSnapshot[] | undefined>(
    onNext => onSnapshots(
      refs,
      ref => onNext => ref.onSnapshot(onNext),
      onNext
    ),
    [refs.map(ref => ref.path).sort().join(' ')]
  );
}

function CostumeChanges({
  prevSheets, sheets
}: {
  prevSheets: DocumentSnapshot[],
  sheets: DocumentSnapshot[],

}) {
  const { ph } = React.useContext(LanguageContext);
  const prevLookRefs: DocumentReference[] = prevSheets.flatMap(sheet => sheet.get('looks') ?? []);
  const lookRefs: DocumentReference[] = sheets.flatMap(sheet => sheet.get('looks') ?? []);
  const prevLooks = useSnapshots(prevLookRefs);
  const looks = useSnapshots(lookRefs);
  if (lookRefs.map(ref => ref.path).sort().join(' ') === prevLookRefs.map(ref => ref.path).sort().join(' ')) return;
  return (
    <View>
      <View style={{ alignSelf: "center", gap: 8, flexDirection: "row" }}>
        <Text variant="titleMedium" style={{ alignSelf: "center" }}>{(ph('change') as string).toLocaleUpperCase()}</Text>
        {prevLooks?.map(doc => <LookChip key={doc.id} look={doc} concise />)}
        <Text variant="titleMedium" style={{ alignSelf: "center" }}>{">"}</Text>
        {looks?.map(doc => <LookChip key={doc.id} look={doc} concise />)}
      </View>
    </View>
  );
}

export function BreakdownSheets({
  scene, prevSheets, sheets, subjectKind, subjectName, episodePath, sheetCategories,
  horizontal, inkColours
}: {
  scene?: Scene;
  prevSheets?: DocumentSnapshot[] | undefined;
  sheets: DocumentSnapshot[] | undefined;
  episodePath?: string;
  sheetCategories: SheetCategory[];
  horizontal?: boolean;
  inkColours?: UserInkColour[]
} & SubjectSpec) {
  const { colors } = useTheme();
  const [categories, setCategories] = React.useState<SheetCategory[]>([]);
  React.useEffect(
    () => setCategories(sheets?.map(sheet => sheet.get('category')) || []),
    [sheets]);
  const [availableCategories, setAvailableCategories] = React.useState<SheetCategory[]>([]);
  React.useEffect(
    () => {
      if (!scene)
        setAvailableCategories([]);
      else
        setAvailableCategories(sheetCategories.filter(category => !categories.includes(category)));
    },
    [scene, sheets]
  );
  if (!(scene || (sheets && sheets.length > 0)))
    return null;
  const subject = { subjectKind, subjectName };
  return (
    <View style={{
      borderBottomColor: colors.onBackground,
      borderBottomWidth: 1,
      paddingTop: 16, paddingBottom: 16,
      flexDirection: horizontal ? "row" : "column",
      flexWrap: "wrap",
      gap: 16
    }}>
      {prevSheets && sheets && <CostumeChanges prevSheets={prevSheets} sheets={sheets} />}
      {scene && <SceneInfo scene={scene} />}
      {sheets?.map(sheet =>
        <SheetView
          key={sheet.id}
          scene={scene}
          sheet={sheet}
          subject={subject}
          episodePath={episodePath}
          horizontal={!horizontal}
          inkColours={inkColours}
        />)}
      {scene && availableCategories.length > 0 &&
        <View style={{ flexDirection: "row" }}>
          <NewSheetButton sceneRef={scene.ref} subject={subject} restrictToCategories={availableCategories} />
        </View>}
    </View>
  );
}
