import { View } from "react-native";
import { Chip, IconButton } from "react-native-paper";
import FixtureLabel from "../common/FixtureLabel";
import React from "react";
import { SearchableMenu } from "./SearchableMenu";
import { TokenisedCandidate } from "../common/search";

export type ChipEditorProps<T> = {
  label: string;
  newItemToAddPhrase: string;
  entries: T[];
  entryKey: (entry: T) => string;
  entryLabel: (entry: T) => string;
  labelFromTextInput: (text: string) => string | undefined;
  searchTokens: (entry: T) => string[];
  candidateList: T[];
  onChange: (value: T[]) => void;
  onAdd?: (text: string) => void;
  min?: number;
  max?: number;
  allowDuplicates?: boolean;
  allowNonCandidates?: boolean;
  showMenuWhenSearchEmpty?: boolean;
}

export function ChipEditor<T>({
  label,
  newItemToAddPhrase,
  entries,
  entryKey,
  entryLabel,
  labelFromTextInput,
  searchTokens,
  candidateList,
  onChange,
  onAdd,
  min = 0,
  max,
  allowDuplicates = false,
  allowNonCandidates = true,
  showMenuWhenSearchEmpty = true
}: ChipEditorProps<T>) {
  const [adding, setAdding] = React.useState(false);
  const fewerThanMin = entries.length < min;
  React.useEffect(() => {
    if (fewerThanMin) {
      setAdding(true);
    }
  }, [fewerThanMin]);
  const list = React.useMemo(() => {
    const ks = entries.map(entryKey);
    return allowDuplicates ?
      candidateList :
      candidateList.filter(entry => !ks.includes(entryKey(entry)))
  }, [allowDuplicates, candidateList, entries]);
  return (
    <View style={{ gap: 4 }}>
      <View style={{ gap: 4, flexDirection: "row", flexWrap: "wrap", alignItems: "center" }}>
        <FixtureLabel style={{ marginRight: 4 }} text={label} />
        {entries.map(entry => {
          const k = entryKey(entry);
          const label = entryLabel(entry);
          return (
            <View key={k} style={{ flexDirection: 'column' }}>
              <Chip onClose={() => onChange(entries.filter(e => entryKey(e) !== k))}>
                {label}
              </Chip>
            </View>
          );
        })}
        {adding || (max !== undefined && entries.length >= max) ? null :
          <IconButton style={{ alignSelf: "flex-end" }} icon="plus"
            onPress={() => {
              setAdding(true);
            }}
          />}
      </View>
      {adding ?
        <View style={{ flexDirection: "row" }}>
          <SearchableMenu
            candidateList={list}
            entryKey={entryKey}
            labelFromTextInput={allowNonCandidates ? labelFromTextInput : undefined}
            searchTokens={searchTokens}
            render={entryLabel}
            onSelect={entry => {
              onChange([...entries, entry]);
              setAdding(false);
            }}
            onAdd={onAdd}
            onDismiss={() => { if (!fewerThanMin) setAdding(false); }}
            placeholder={newItemToAddPhrase}
            additionPhraseKey="create"
            autoFocus={!fewerThanMin}
            showMenuWhenSearchEmpty={showMenuWhenSearchEmpty}
          />
        </View> : null}
    </View >
  );
}
