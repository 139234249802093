import React from "react";
import FixtureLabel from "./FixtureLabel";
import { CoreComponentsContext } from "./core-components";

export type LabelledAttrProps = {
  children: React.ReactNode | string | undefined;
  label: string;
  small?: boolean;
  progressBarWidth?: number;
  style?: {
    marginLeft?: number;
    marginRight?: number;
    marginBottom?: number,
    flexDirection?: "row" | "column"
  }
}

export default function LabelledAttr(
  { children, label, small, style }: LabelledAttrProps
) {
  const { View, Text, colors } = React.useContext(CoreComponentsContext);
  return (
    <View style={style}>
      <FixtureLabel text={label} small={small} />
      {children ?
        (typeof children === 'string' ?
          <Text style={{
            fontSize: small ? 12 : 14,
            color: colors.onBackground
          }}>
            {children}
          </Text> :
          children) :
        null
      }
    </View >
  );
}
