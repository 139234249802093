import React from "react";
import { Style } from "@react-pdf/types";
import { TimeOfDay } from "./phrases";

type CoreComponentProps = React.PropsWithChildren<{
  style?: Style | Style[] | undefined
}>
export type ComponentClass<T> = React.ComponentClass<T> | React.FunctionComponent<T>

export type CoreComponentClass = ComponentClass<CoreComponentProps>

export type TimeOfDayRepresentationProps = { timeOfDay: TimeOfDay | undefined }

export const CoreComponentsContext = React.createContext({
  View: (undefined as unknown) as CoreComponentClass,
  RawText: (undefined as unknown) as CoreComponentClass,
  Text: (undefined as unknown) as CoreComponentClass,
  NewItemIndicator: (undefined as unknown) as CoreComponentClass,
  TimeOfDayRepresentation: (undefined as unknown) as ComponentClass<TimeOfDayRepresentationProps>,
  colors: {
    fixture: "",
    onBackground: ""
  }
});
