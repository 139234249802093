import React from 'react';
import { LanguageContext } from '../common/language';
import { DocumentReference } from '@atgof-firebase/firebase';
import { ExportButton } from "./ExportButton";

export function ScriptExportButton({ sceneRef, showTramlines }: {
  sceneRef: DocumentReference | undefined;
  showTramlines: boolean;
}) {
  const { language } = React.useContext(LanguageContext);
  if (!sceneRef) return null;
  return (
    <ExportButton
      kind="postProductionScene"
      exportData={{
        scene: sceneRef,
        parameters: {
          lang: language,
          showTramlines
        }
      }}
      phrases={{
        generate: 'generate-pdf',
        generating: 'generating-pdf',
        unableToGenerate: 'unable-to-generate-pdf',
        generated: 'generated-pdf',
        download: 'download-pdf'
      }}
    />
  );
}
