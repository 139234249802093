import { UserInkColour } from "@atgof-common/membership";
import { View } from "react-native";
import { Text, useTheme } from "react-native-paper";
import { ColourWell } from "./ColourWell";

export function UserColoursKey({ value }: { value: UserInkColour[] | undefined }) {
  const { dark } = useTheme();
  return (
    <View style={{ flexDirection: "row", gap: 16, margin: 8, flexWrap: "wrap" }}>
      {value?.sort((a, b) => a.displayName.localeCompare(b.displayName))
        .map(({ userId, displayName, inkColour }) =>
          <View
            key={userId}
            style={{ flexDirection: "row", gap: 6, alignItems: "center" }}
          >
            <ColourWell value={inkColour[dark ? 'dark' : 'light']} size={18} />
            <Text>{displayName}</Text>
          </View>
        )
      }
    </View >
  );
}

