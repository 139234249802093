import { Firestore } from "./types";
import _ from 'lodash';
import { Unsubscribe } from "../common/onSnapshot";
import { delay } from "../common/util";


export function onFirestoreSyncState(
  db: Firestore,
  onChange: (hasPendingWrites: boolean) => void
): Unsubscribe {
  const onChange_ = _.debounce(onChange, 500);
  let interrupted = false;
  (async function() {
    while (true) {
      onChange_(true);
      try { await db.waitForPendingWrites(); }
      catch { }
      if (interrupted) return;
      onChange_(false);
      await delay(1000);
    }
  })();
  return () => { interrupted = true };
}
