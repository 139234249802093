import { SubjectSpec } from "@atgof-common/subject";
import { useNavigation } from "@react-navigation/core";
import { Button } from "react-native-paper";

export function SubjectButton(
  { subjectKind, subjectName, episodePath, fallback }:
    SubjectSpec & {
      episodePath: string | undefined;
      fallback?: React.ReactElement
    }
) {
  const { navigate } = useNavigation();
  if (!episodePath) return fallback ?? null;
  return (
    <Button onPress={() => navigate('episodeBreakdown', {
      path: episodePath,
      subjectKind,
      subjectName
    })}>
      {subjectName}
    </Button>
  );
}
