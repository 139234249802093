import React from "react";
import { Platform, Text as RNText, View } from "react-native";
import { Banner, Icon, Text, useTheme } from "react-native-paper";
import { episodePhrases, extractPrefixed, processIdentifier, processScript, ScriptScene } from "../common/file-import";
import { CollectionReference, DocumentReference, DocumentSnapshot } from "@atgof-firebase/firebase";
import { LanguageContext } from "../common/language";
import { ImporterComponentProps } from "../screens/Importer";
import { Revealer } from "./Revealer";
import ScriptParagraph from "../common/ScriptParagraph";
import SceneInfo, { PreexistingSubjects, usePreexistingSubjects } from "./SceneInfo";
import { useSnapshot } from "../data/useSnapshot";
import { ProjectContext } from "../data/projectContext";
import { sceneLabel, toScene } from "../common/scene";
import ReferentSelector from "./ReferentSelector";
import { getEpisodePath } from "../common/episode";
import { useDocument } from "../data/useDocument";
import { PhraseFunction } from "../common/phrases";
import { episodeLabel, UNKNOWN_LABEL } from "../common/util";
import { addEpisode } from "../data/episode";
import { UserContext } from "../data/userContext";

function parseEpisodeIdFromFilename(importEntry: DocumentSnapshot | undefined) {
  const metadata: { name: string } | undefined = importEntry?.get('originalFileMetadata');
  if (!metadata) return;
  const filename = metadata.name;
  for (const part of filename.split(/_|(\s\-\s)/)) {
    const prefixed = extractPrefixed(episodePhrases, part, true);
    const m = prefixed?.match(/^(\d+)/);
    if (m) return processIdentifier(m[1]);
  }
  const episode = filename
    .match(/(?<episode>([A-Z]?\d*)|(\d+))[\.\/]\d+/)?.groups?.episode;
  if (episode) return processIdentifier(episode);
}

async function updateEpisode(
  episodeRef: DocumentReference,
  cast: string[] | undefined,
  location: string | undefined
) {
  const episode = (await episodeRef.get()).data();
  const episodeCast = new Set([...(episode?.cast ?? []), ...(cast ?? [])]);
  const episodeSets = new Set([...(episode?.sets ?? []), ...(location ? [location] : [])]);
  const episodeData = {
    sceneCount: (episode?.sceneCount ?? 0) + 1,
    cast: [...episodeCast].sort(),
    sets: [...episodeSets].sort()
  };
  if (!episode) {
    episodeRef.set({
      deleted: false,
      displayName: episodeRef.id,
      ...episodeData
    });
  }
  else episodeRef.update(episodeData);
}

async function updateSubjects(coll: CollectionReference | undefined, values: string[]) {
  if (!coll) return;
  const toAdd = new Set(values);
  for (const doc of (await coll.get()).docs) {
    toAdd.delete(doc.get('displayName')); // TODO Take into account doc.get('deleted')
  }
  for (const value of toAdd) {
    coll.add({
      deleted: false,
      displayName: value
    });
  }
}

function PromptToAddIfNeeded({
  kind, entityDescription, docRef, add, children
}: {
  kind: 'episode' | 'scene';
  entityDescription: string | undefined;
  docRef: DocumentReference | undefined;
  add: (ref: DocumentReference) => void
  children?: React.ReactNode
}) {
  const { ph } = React.useContext(LanguageContext);
  const doc = useDocument(docRef);
  if (!docRef || doc?.exists) return null;
  return (
    <Banner
      visible
      icon="alert-rhombus"
      actions={[
        {
          label: ph('add') + ' ' + ph(kind),
          onPress: () => add(docRef)
        }
      ]}
      style={{ /*maxWidth: 600 TODO but this truncates long cast lists */ }}
    >
      <View style={{ gap: 4 }}>
        <Text>{
          (ph('does-not-exist-prompt') as PhraseFunction)({
            entityDescription: entityDescription ?? UNKNOWN_LABEL
          }) as string}
        </Text>
        {children}
      </View>
    </Banner>
  );
}


function ScriptSceneImporter(
  {
    scene, episodeRef, preexistingSubjects
  }: {
    scene: ScriptScene;
    episodeRef: DocumentReference | undefined;
    preexistingSubjects: PreexistingSubjects
  }) {
  const { colors } = useTheme();
  const { ph } = React.useContext(LanguageContext);
  const { project } = React.useContext(ProjectContext);
  const [sceneData, setSceneData] = React.useState(
    {
      ...scene, episodeId: episodeRef?.id
    });
  // TODO Warn if season is undefined
  const sceneRef = sceneData.id ? episodeRef?.collection('scenes').doc(sceneData.id) : undefined;
  const actualScene = useSnapshot<DocumentSnapshot | undefined>( // ~TODO This should return multiple scenes as a result of querying for baseSceneId
    sceneRef ? (onNext => sceneRef.onSnapshot(onNext)) : undefined,
    [sceneRef?.path]
  );
  async function updateScene(data: Partial<ScriptScene>) {
    setSceneData(existingData => ({ ...existingData, ...data }));
  }
  React.useEffect(() => { updateScene({ episodeId: episodeRef?.id }) }, [episodeRef]);
  async function addScene(ref: DocumentReference) {
    if (ref.path !== sceneRef?.path) return;
    const { id, episodeId, isInterior, isExterior, location, scriptDay, cast, timeOfDay, synopsis } = sceneData;
    if (!(sceneRef && episodeRef && id && episodeId)) return;
    sceneRef.set({
      deleted: false,
      id,
      episodeId,
      baseSceneId: id, // TODO
      isInterior,
      isExterior,
      ...(timeOfDay ? { timeOfDay } : {}),
      sets: [location ?? ""],
      cast,
      ...(synopsis ? { synopsis } : {}),
      ...(scriptDay ? { scriptDay } : {})
    });
    updateEpisode(episodeRef, cast, location);
    if (cast) updateSubjects(project?.collection('characters'), cast);
    if (location) updateSubjects(project?.collection('sets'), [location]);
  }
  return (
    <Revealer
      title={
        <View style={{ gap: 4, flexDirection: "row" }}>
          {!episodeRef || (actualScene && !actualScene.exists) ?
            <Icon size={24} source="alert" color={colors.error} /> : null}
          <Text variant="titleMedium">{ph('scene') + ' ' + scene.id}</Text>
        </View>}
    >

      <View>
        <PromptToAddIfNeeded
          kind="scene"
          entityDescription={actualScene ? sceneLabel(actualScene.ref.path) : undefined}
          docRef={actualScene?.ref}
          add={addScene}
        >
          <SceneInfo
            scene={sceneData}
            updateScene={updateScene}
            preexistingSubjects={preexistingSubjects}
          />
        </PromptToAddIfNeeded>
        {actualScene?.exists ?
          <SceneInfo scene={toScene(actualScene)} /> :
          null
        }
        <div style={{ userSelect: 'text' }}>
          {scene.content.map((paragraph, index) =>
            <ScriptParagraph key={index} content={paragraph}
              textAnnotations={
                scene.textAnnotations.filter(entry => entry.paragraph == index)
              }
              web={Platform.OS === 'web'} Text={RNText} View={View}
            />
          )}
        </div>
      </View>
    </Revealer>
  );
}

export default function ScriptImporter({ content, importButton, importEntry }: ImporterComponentProps) {
  const { ph } = React.useContext(LanguageContext);
  const { user } = React.useContext(UserContext);
  const script = React.useMemo(() => processScript(content), [content]);
  const [selectedEpisode, setSelectedEpisode] = React.useState<DocumentReference>();
  const episodePath = getEpisodePath(selectedEpisode);
  const defaultEpisodeId = script.episodeId ?? parseEpisodeIdFromFilename(importEntry);
  React.useEffect(
    () => {
      document.addEventListener('selectionchange', () => console.log("SEL", document.getSelection()));
    },
    []
  );
  const preexistingSubjects = usePreexistingSubjects();
  return (
    <View style={{ gap: 8, marginTop: 16, marginBottom: 16 }}>
      <ReferentSelector
        kind="episode"
        defaultReferentId={defaultEpisodeId}
        referent={selectedEpisode}
        onReferentChange={setSelectedEpisode}
        allowOther
      />
      <PromptToAddIfNeeded
        kind="episode"
        entityDescription={episodeLabel(ph, selectedEpisode?.path)}
        docRef={selectedEpisode}
        add={ref => addEpisode(user.ref, ref)}
      />
      {script.scenes.map(scene =>
        <ScriptSceneImporter
          key={scene.id}
          scene={scene}
          episodeRef={selectedEpisode}
          preexistingSubjects={preexistingSubjects}
        />)}
      {
        script.scenes && importButton &&
        <View style={{ gap: 8, alignSelf: "center" }}>
          {selectedEpisode && importButton(episodePath ? {
            seasonId: episodePath.season,
            ...(episodePath.episode ? { episodeId: episodePath.episode } : {})
          } : {})}
        </View>
      }
    </View >
  );
}
