import { View } from "react-native";
import { Episode } from "../common/episode";
import { IconButton, Text } from "react-native-paper";
import React from "react";
import dayjs from "dayjs";
import { LanguageContext } from "@atgof-common/language";
import { countLabel, formatTimestamp } from "@atgof-common/util";
import FixtureLabel from "@atgof-common/FixtureLabel";
import DayPicker from "./DayPicker";
import { FieldValue } from "@atgof-firebase/firebase";
import { Editable } from "./Editable";
import { CompletionIndicator } from "@atgof-common/CompletionIndicator";

function EpisodeEditor({ episode }: {
  episode: Episode;
}) {
  const { ref, txDate } = episode;
  const { ph } = React.useContext(LanguageContext);
  const txDay = React.useMemo(
    () => txDate ? dayjs(txDate?.toDate()) : undefined,
    [episode.txDate?.seconds]
  );
  function setTxDate(date: Date | undefined) {
    ref.update({ txDate: date ?? FieldValue.delete() });
  }
  return (
    <View style={{
      flexDirection: "row", flexWrap: "wrap", gap: 16,
      alignItems: "center"
    }}>
      <View style={{ flexDirection: "row" }}>
        <View style={{ alignSelf: "center" }}>
          <FixtureLabel text={ph('tx-date') as string} />
          {txDay ?
            <DayPicker
              day={txDay}
              setDay={day => setTxDate(day.toDate())}
            /> :
            null
          }
        </View>
        <IconButton
          style={{ alignSelf: "flex-end" }}
          icon={txDay ? "calendar-remove" : "calendar-plus"}
          onPress={() => setTxDate(txDay ? undefined : new Date())}
        />
      </View>
      <View>
        <FixtureLabel text={ph('director') as string} />
        <Editable
          text={episode.director ?? ''}
          onChange={director => ref.update({ director })}
          paperTheme
        />
      </View>
      <View>
        <FixtureLabel text={ph('scriptwriter') as string} />
        <Editable
          text={episode.writer ?? ''}
          onChange={writer => ref.update({ writer })}
          paperTheme
        />
      </View>
    </View>
  );
}

export function EpisodeInfo({ episode, readonly = true }: {
  episode: Episode;
  readonly?: boolean
}) {
  const { ph } = React.useContext(LanguageContext);
  const { txDate, sceneCount, director, writer, isComplete, pendingActionsForScenes } = episode;
  if (!readonly) return <EpisodeEditor episode={episode} />;
  return (
    <View style={{ flexDirection: "row" }}>
      <View>
        <View style={{ flexDirection: "row", alignContent: "flex-end" }}>
          <View style={{ minWidth: 140 }}>
            {txDate && <Text variant="bodyLarge" style={{ marginRight: 10 }}>
              {ph('tx') + ' ' + formatTimestamp(txDate)}
            </Text>}
          </View>
        </View>
        <View style={{ flexDirection: "row", alignContent: "center", gap: 16 }}>
          <CompletionIndicator
            isComplete={isComplete ?? false}
            hasPendingActions={pendingActionsForScenes?.length ? true : false}
          />
          <Text variant="bodyLarge">{countLabel(ph, 'count-scene', sceneCount)}</Text>
        </View>
      </View>
      <View>
        {director ?
          <View style={{ gap: 4, flexDirection: "row", alignItems: "center" }}>
            <FixtureLabel text={ph('director-short') as string} small />
            <Text variant="bodyLarge">{director}</Text>
          </View> :
          null}
        {writer ?
          <View style={{ gap: 4, flexDirection: "row", alignItems: "center" }}>
            <FixtureLabel text={ph('script') as string} small />
            <Text variant="bodyLarge">{writer}</Text>
          </View> :
          null}
      </View>
    </View>
  );
}
