import React, { ReactNode, ReactElement } from "react";
import { DocumentSnapshot, DocumentReference, Timestamp } from "@atgof-firebase/firebase";
import { LayoutChangeEvent, Pressable, Text, View, ViewStyle } from "react-native";
import { Button, Menu, Text as PaperText, ProgressBar, ToggleButton, useTheme } from "react-native-paper";
import SceneNavigationButton from "../SceneNavigationButton";
import ScriptParagraph from "../../common/ScriptParagraph";
import Tramlines, { TramlineEndKind } from "./Tramlines";
import { EditingMode, ScriptAnnotationLayers } from "./ScriptAnnotationLayers";
import HeaderStrip from "../HeaderStrip";
import { Take, takeSchema } from "../../common/model/project/sheet/take";
import { LanguageContext } from "../../common/language";
import { formatTime, formatTimecode, formatTimestamp } from "../../common/util";
import { SubjectSpec } from "../../common/subject";
import { GetPos } from "./useTramlines";
import { useLatestScripts } from "../../data/script";
import { ProjectContext } from "../../data/projectContext";
import { useSnapshot } from "../../data/useSnapshot";
import { onSnapshots } from "../../common/onSnapshot";
import { getScript } from "../../data/backend";
import { AnnotationLayerKey, ScriptElement, onScriptElements } from "../../common/script";
import { RegisterSceneDetailProvider, SceneDetailProvider, useParagraphPosManager } from "./useParagraphPosManager";
import { StylusIgnoringScrollView } from "../scroll-views/StylusIgnoringScrollView";
import { useDocument } from "../../data/useDocument";
import { episodeForScene, toEpisode } from "@atgof-common/episode";

export const LAYOUT_VERSION = 0;

function layerStyle(zIndex: number): ViewStyle {
  return {
    position: 'absolute', width: '100%', height: '100%', zIndex
  };
}

const annotationLayerStyle = layerStyle(2);

function TakeHeader(
  { takeData, updateTake, setAnnotating, isAnnotating, adjustScriptLoc, onDone, children }:
    {
      takeData: Take;
      updateTake: (data: Partial<Take>) => void;
      setAnnotating: (annotating: boolean) => void;
      isAnnotating: boolean;
      adjustScriptLoc: TramlineEndKind | undefined;
      onDone: () => void;
      children: ReactNode
    }) {
  const { ph } = React.useContext(LanguageContext);
  const { colors } = useTheme();
  const onIn = React.useCallback(() => {
    const startDate = Timestamp.now();
    updateTake({ startDate, timecode: formatTimecode(startDate) });
    setAnnotating(true);
  }, []);
  const onOut = React.useCallback(() => {
    const endDate = Timestamp.now();
    const length = takeData?.startDate ?
      formatTime(new Date(endDate.toMillis() - takeData.startDate.toMillis())) : '';
    updateTake({ endDate, length });
    setAnnotating(false);
  }, [takeData?.startDate]);
  const [curDate, setCurDate] = React.useState<Date>(new Date());
  React.useEffect(() => {
    if (takeData?.startDate && !(takeData?.endDate)) {
      const timer = setInterval(() => setCurDate(new Date()), 500);
      return () => {
        clearInterval(timer);
      };
    }
  }, [takeData?.startDate, takeData?.endDate]);
  if (!takeData) return null;
  const { startDate, endDate, startScriptLoc, endScriptLoc } = takeData;
  const prompt = !isAnnotating && (
    adjustScriptLoc === 'start' ?
      'select-script-new-starting-point' :
      (adjustScriptLoc === 'end' ?
        'select-script-new-ending-point' :
        (startScriptLoc === undefined ?
          'select-script-starting-point' :
          (endDate && endScriptLoc === undefined ?
            'select-script-ending-point' : null)))
  );
  return (
    <HeaderStrip mode={prompt ? 'prompt' : 'default'}>
      <View style={{ padding: 8, gap: 4 }}>
        <View style={{ flexDirection: "row", gap: 8, alignSelf: "center" }}>
          {children}
          {startScriptLoc ?
            (startDate ?
              (endDate ?
                <PaperText variant="titleLarge" style={{ alignSelf: "center" }}>
                  {formatTimecode(startDate)} - {formatTimecode(endDate)}
                </PaperText> :
                <View style={{ flexDirection: "row", gap: 8, alignItems: "center" }}>
                  <PaperText variant="titleLarge" style={{ alignSelf: "center" }}>
                    {formatTimecode(startDate)} - {formatTime(curDate)}
                  </PaperText>
                  <Button mode="contained" onPress={onOut}>{ph('out') as string}</Button>
                </View>) :
              <Button mode="contained" onPress={onIn}>{ph('in') as string}</Button>) :
            null}
        </View>
        {prompt ? <Text style={{ color: colors.onBackground }}>
          {ph(prompt) as string}</Text> : null
        }
        {adjustScriptLoc ?
          <Button mode="text" onPress={onDone}>{ph('done') as string}</Button> : null}
      </View>
    </HeaderStrip>
  );
}

function AnnotationToolstrip(
  { editingMode, setEditingMode }: {
    editingMode: EditingMode;
    setEditingMode: (editingMode: EditingMode) => void
  }) {
  const { ph } = React.useContext(LanguageContext);
  const [menuVisible, setMenuVisible] = React.useState(false);
  function openMenu() { setMenuVisible(true); }
  function closeMenu() { setMenuVisible(false); }

  if (!(editingMode && 'available' in editingMode)) return null;
  const { available } = editingMode;
  const paLayerKeys: AnnotationLayerKey[] = (available.includes('pa') ? ['pa'] : []);
  const isPublic = 'pencil' in editingMode ?
    (editingMode.pencil === 'public') : editingMode.eraser.includes('public');
  return (
    <View style={{ gap: 2, flexDirection: "row" }}>
      {(available.includes('public') || available.includes('user')) ?
        <Menu
          visible={menuVisible}
          onDismiss={closeMenu}
          anchor={
            <Button mode="text" icon="layers" onPress={openMenu}>
              {ph(
                isPublic ?
                  'shared' : 'private'
              ) as string}
            </Button>
          }
          anchorPosition="bottom"
        >
          {available.includes('public') ?
            <Menu.Item
              title={ph('shared') as string}
              leadingIcon={
                isPublic ?
                  "radiobox-marked" : "radiobox-blank"
              }
              onPress={() => {
                closeMenu();
                setEditingMode({ ...editingMode, pencil: 'public' })
              }}
            /> : null}
          {available.includes('user') ?
            <Menu.Item
              title={ph('private') as string}
              leadingIcon={
                !isPublic ?
                  "radiobox-marked" : "radiobox-blank"}
              onPress={() => {
                closeMenu();
                setEditingMode({ ...editingMode, pencil: 'user' })
              }}
            /> : null}
        </Menu> :
        null}
      <ToggleButton.Row
        onValueChange={value => setEditingMode(
          {
            available: editingMode.available,
            ...(value === 'eraser' ?
              { eraser: isPublic ? ['public'] : ['user', ...paLayerKeys] } :
              { pencil: isPublic ? 'public' : 'user' }
            )
          }
        )}
        value={'eraser' in editingMode ? 'eraser' : 'pencil'}
      >
        {['pencil', 'eraser'].map(value => <ToggleButton key={value} icon={value} value={value} />)}
      </ToggleButton.Row>
    </View>
  );
}


function ScriptViewHeader(
  { takeData, updateTake, editableAnnotationLayers, editingMode, setEditingMode }: {
    takeData: Take | undefined;
    updateTake: (data: Partial<Take>) => void;
    editableAnnotationLayers: AnnotationLayerKey[];
    editingMode: EditingMode;
    setEditingMode: (editingMode: EditingMode) => void;
  }
) {
  const annotationToolstrip = editingMode ?
    <AnnotationToolstrip
      editingMode={editingMode}
      setEditingMode={setEditingMode}
    /> : null;
  return (
    takeData && editableAnnotationLayers.length ?
      <TakeHeader takeData={takeData} updateTake={updateTake}
        setAnnotating={annotating => setEditingMode(
          annotating ?
            {
              pencil: editableAnnotationLayers[0],
              available: editableAnnotationLayers
            } : undefined
        )}
        isAnnotating={editingMode && 'available' in editingMode ? true : false}
        adjustScriptLoc={editingMode && 'adjustScriptLoc' in editingMode ?
          editingMode.adjustScriptLoc : undefined}
        onDone={() => setEditingMode(undefined)}
      >
        {annotationToolstrip}
      </TakeHeader> :
      (annotationToolstrip ? <HeaderStrip>{annotationToolstrip}</HeaderStrip> : null)
  );
}

function isAwaitingTap(takeData: Take | undefined) {
  if (!takeData) return false;
  const { startScriptLoc, endScriptLoc } = takeData;
  return (startScriptLoc == null || endScriptLoc == null);
}

const metadataStyle = {
  fontFamily: "Roboto-Regular", fontSize: 16,
};

const metadataFixtureStyle = {
  ...metadataStyle,
  color: "#5E5E5E"
}

const metadataMargin = 16;

function SceneScriptView(
  { canModifyScripts, sceneRef, script, elements, subject, dialogueLineNumberOffset,
    viewableAnnotationLayers, editingMode, awaitingTap,
    onElementPress, registerSceneDetailProvider }:
    {
      canModifyScripts: boolean;
      sceneRef: DocumentReference;
      script: DocumentSnapshot | undefined;
      elements: ScriptElement[] | undefined;
      subject: SubjectSpec | undefined;
      dialogueLineNumberOffset: number;
      viewableAnnotationLayers: AnnotationLayerKey[];
      editingMode: EditingMode;
      awaitingTap: boolean;
      onElementPress: (
        (sceneRef: DocumentReference, paragraphIndex: number, pos: number) => void
      ) | undefined
      registerSceneDetailProvider: RegisterSceneDetailProvider;
    }
) {
  const { ph } = React.useContext(LanguageContext);
  const metadataHeight = React.useRef<number>();
  const episodeDoc = useDocument(episodeForScene(sceneRef) ?? undefined);
  const episode = episodeDoc ? toEpisode(episodeDoc) : undefined;
  const noHeightPresent = script && script.get('height') == null;
  const posManager = useParagraphPosManager(
    sceneRef.id, registerSceneDetailProvider, elements?.length
  );
  const onListLayout = React.useCallback((evt: LayoutChangeEvent) => {
    if (canModifyScripts && noHeightPresent) {
      const { height } = evt.nativeEvent.layout;
      if (height) script.ref.update({ height });
    }
  }, [canModifyScripts, noHeightPresent]);
  if (!elements) return <ProgressBar indeterminate />;
  return (
    <React.Fragment>
      <View
        onLayout={evt => { metadataHeight.current = evt.nativeEvent.layout.height; }}
        style={{
          marginVertical: metadataMargin / 2, marginHorizontal: 32,
          flexDirection: "row", justifyContent: "space-between",
          alignContent: "flex-start"
        }}
      >
        <View style={{ gap: 4 }}>
          <View style={{ flexDirection: "row", gap: 8 }}>
            <Text style={metadataFixtureStyle}>{ph('tx') as string}</Text>
            <Text style={metadataStyle}>{formatTimestamp(episode?.txDate)}</Text>
          </View>
          <View style={{ flexDirection: "row", gap: 8 }}>
            <Text style={metadataFixtureStyle}>{ph('director-short') as string}</Text>
            <Text style={metadataStyle}>{episode?.director}</Text>
          </View>
          <View style={{ flexDirection: "row", gap: 8 }}>
            <Text style={metadataFixtureStyle}>{ph('script') as string}</Text>
            <Text style={metadataStyle}>{episode?.writer}</Text>
          </View>
        </View>
        <Text style={metadataFixtureStyle}>
          {formatTimestamp(script?.get('lastModifiedAt'), true)}
        </Text>
      </View>
      <View style={{ position: "relative" }}>
        <View onLayout={onListLayout}>
          {elements.map((item, index) => {
            const marginTop = index ? 0 : 40;
            const marginBottom = index + 1 == elements.length ? 40 : 0;
            return (
              <Pressable
                key={index}
                style={{ marginTop, marginBottom }}
                onLayout={evt => posManager.onParagraphLayout(index, evt)}
                onPress={
                  onElementPress ?
                    (_ => {
                      const pos = posManager.getParagraphPos(index);
                      onElementPress(
                        sceneRef,
                        index,
                        pos!!.top + marginTop + (metadataHeight.current ?? 0) + metadataMargin
                      )
                    }) :
                    undefined
                }
              >
                <ScriptParagraph
                  content={{
                    ...item,
                    dialogueLineNumber: item.dialogueLineNumber === undefined ?
                      undefined : dialogueLineNumberOffset + item.dialogueLineNumber
                  }}
                  web
                  subject={subject}
                  Text={Text}
                  View={View}
                  backgroundColor="transparent"
                />
              </Pressable>
            );
          }
          )}
        </View>
        <View style={{
          position: "absolute", top: 0, left: 0, width: "100%", height: "100%",
          pointerEvents: awaitingTap && !(editingMode && 'available' in editingMode) ?
            "none" : undefined
        }}>
          {script ?
            <ScriptAnnotationLayers
              layoutVersion={LAYOUT_VERSION}
              style={annotationLayerStyle}
              viewableAnnotationLayers={viewableAnnotationLayers}
              editingMode={editingMode}
              script={script}
            />
            : null
          }
        </View>
      </View>
    </React.Fragment>
  );
}

export default function ScriptView(
  {
    canModifyScripts, subject, sceneRefs, sheet, hasTake, take,
    viewableAnnotationLayers, editableAnnotationLayers,
    afterScript, showTramlines, editingMode, setEditingMode,
    prevScene, nextScene, moveToScene
  }:
    {
      canModifyScripts: boolean;
      subject: SubjectSpec | undefined;
      sceneRefs: DocumentReference[] | undefined;
      sheet: DocumentSnapshot | undefined;
      hasTake: boolean;
      take: DocumentSnapshot | undefined;
      viewableAnnotationLayers: AnnotationLayerKey[];
      editableAnnotationLayers: AnnotationLayerKey[];
      afterScript?: ReactElement | undefined;
      showTramlines: boolean;
      editingMode: EditingMode;
      setEditingMode: (editingMode: EditingMode) => void;
      prevScene?: DocumentSnapshot | undefined;
      nextScene?: DocumentSnapshot | undefined;
      moveToScene?: (sceneId: string) => void;
    }
) {
  const scripts = useLatestScripts(sceneRefs);
  const elements = useSnapshot<ScriptElement[][] | undefined>(
    onNext => onSnapshots(
      scripts,
      ({ script, sceneRef }) => (
        onNext => onScriptElements(
          script?.get('scriptPath') as (string | undefined),
          storagePath => getScript(sceneRef, storagePath),
          onNext
        )
      ),
      onNext
    ),
    [
      scripts?.map(
        ({ script, sceneRef }) => `${script?.get('scriptPath')} ${sceneRef.path}`
      ).sort().join('\n'),
      /* TODO The following line is required so that we trigger a
         refresh and don't lose the ability to scroll after making an
         annotation (e.g. during a line run) on Web.

         See issue #001 in Atgof.org */
      Object.keys(editingMode ?? {}).sort().join('\n')
    ]
  );
  const { categories } = React.useContext(ProjectContext);
  const isPA = categories.includes('pa');
  const takeData = take ? takeSchema.parse(take.data()) : undefined;
  const awaitingTap = (editingMode && 'adjustScriptLoc' in editingMode) || isAwaitingTap(takeData);
  function updateTake(data: Partial<Take>) { take?.ref.update(data); }
  const onElementPress = isPA ?
    (sceneRef: DocumentReference, paragraphIndex: number, pos: number) => {
      if (!takeData ||
        !(takeData.startScriptLoc === undefined || takeData.endScriptLoc === undefined ||
          (editingMode && 'adjustScriptLoc' in editingMode))) {
        return;
      }
      const k =
        takeData.startScriptLoc === undefined ||
          (editingMode && 'adjustScriptLoc' in editingMode &&
            editingMode.adjustScriptLoc === 'start') ?
          'startScriptLoc' : 'endScriptLoc';
      updateTake({
        [k]: {
          sceneId: sceneRef.id,
          paragraphIndex,
          yPos: pos
        }
      });
    } :
    undefined;
  const onTramlineLongPress = isPA && hasTake ?
    (end: TramlineEndKind) => setEditingMode({ adjustScriptLoc: end }) :
    undefined;
  const sceneDetailProviders = React.useRef(new Map<string, SceneDetailProvider>());
  function registerSceneDetailProvider(sceneId: string, provider: SceneDetailProvider) {
    sceneDetailProviders.current.set(sceneId, provider);
    return () => { sceneDetailProviders.current.delete(sceneId); };
  }
  const getPos = React.useCallback<GetPos>(
    paragraphIndex => {
      if (!(elements && sceneRefs)) return;
      let provider: SceneDetailProvider | undefined;
      let paragraphIndexOffset = 0;
      for (let i = 0; i < elements.length; i++) {
        const p = sceneDetailProviders.current.get(sceneRefs[i].id);
        const n = elements[i].length;
        if (!p) {
          console.warn(`Unable to getPos(${paragraphIndex}) because of unavailable paragraph count for ${sceneRefs[i].path}`);
          break;
        }
        if (paragraphIndex < paragraphIndexOffset + n) {
          provider = p;
          break;
        }
        paragraphIndexOffset += n;
      }
      const pos = provider?.getParagraphPos(paragraphIndex - paragraphIndexOffset);
      return pos;
    },
    [elements]
  );
  if (!scripts) return <ProgressBar indeterminate />;
  return (
    <React.Fragment>
      <ScriptViewHeader
        takeData={takeData}
        updateTake={updateTake}
        editableAnnotationLayers={editableAnnotationLayers}
        editingMode={editingMode}
        setEditingMode={setEditingMode}
      />
      <StylusIgnoringScrollView>
        <SceneNavigationButton scene={prevScene} moveToScene={moveToScene} />
        <View style={{
          position: 'relative',
          width: '100%', backgroundColor: '#ffffff'
        }}>
          <View style={{
            flex: 1, zIndex: 1,
            marginBottom: 40,
          }}>
            {scripts.map(({ sceneRef, script, dialogueLineNumberOffset }, i) =>
              <SceneScriptView
                key={sceneRef.id}
                canModifyScripts={canModifyScripts}
                sceneRef={sceneRef}
                script={script}
                elements={elements ? elements[i] : undefined}
                subject={subject}
                dialogueLineNumberOffset={dialogueLineNumberOffset}
                viewableAnnotationLayers={viewableAnnotationLayers}
                editingMode={editingMode}
                awaitingTap={awaitingTap}
                onElementPress={onElementPress}
                registerSceneDetailProvider={
                  provider => registerSceneDetailProvider(sceneRef.id, provider)
                }
              />
            )}
          </View>
          <Tramlines
            scripts={showTramlines && elements ? scripts : undefined}
            sheet={sheet}
            hasTake={hasTake}
            take={take}
            getPos={getPos}
            style={{
              position: 'absolute',
              top: 0, left: 0,
              zIndex: (editingMode && 'available' in editingMode ? 0 : 2),
              pointerEvents: awaitingTap ? "none" : undefined
            }}
            onLongPress={onTramlineLongPress}
          />
        </View>
        <SceneNavigationButton scene={nextScene} moveToScene={moveToScene} isForward />
        {afterScript}
      </StylusIgnoringScrollView>
    </React.Fragment>
  );
}
