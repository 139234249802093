import { useNavigation } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React from "react";
import { View } from "react-native";
import { Phrase, PhraseKey } from "../common/phrases";
import FieldsRow from "../components/fields/FieldsRow";
import DetailPage from "../components/pages/DetailPage";
import { SheetCategory } from "../common/sheet";
import { UserContext } from "../data/userContext";
import { DocumentSnapshot, FieldValue } from "@atgof-firebase/firebase";
import { LanguageContext } from "../common/language";
import { RootStackParamList } from "../types";
import { SheetSections } from "../components/SheetSections";
import { episodeLabel } from "../common/util";
import PhotoStrip from "../components/PhotoStrip";
import { referencePathForLook } from "../data/backend/downloader";
import { useDocument } from "../data/useDocument";
import { useDocumentReference } from "../data/firestore";
import { useProject } from "../data/useProject";
import _ from "lodash";

export function lookTitle(
  ph: (k: PhraseKey) => Phrase, look: DocumentSnapshot | undefined, isNew: boolean = false
) {
  return look?.get('displayName') || (isNew ? ph('new-look') : ph('unnamed-look'));
}

export default function LookScreen({ route }: NativeStackScreenProps<RootStackParamList, 'look'>) {
  const { ph } = React.useContext(LanguageContext);
  const { path, episodePath, subjectName } = route.params;
  const isNew = route.params.isNew === 'true';
  const look = useDocument(useDocumentReference(path));
  const category: SheetCategory | undefined = look?.get('category');
  const project = useDocument(useProject());
  const extraPhotoStrips: string[] | undefined =
    category ?
      Object.keys(_.get(
        project?.get('breakdownSettings'),
        ['look', category, 'photoStrips'],
        {}
      )) :
      undefined;
  const referencePath = referencePathForLook(look?.ref);
  return (
    <DetailPage
      title={
        `${subjectName} - ${episodePath ? episodeLabel(ph, episodePath, true) : lookTitle(ph, look, isNew)}`
      }
      deleteItem={look?.ref}
    >
      {look && category &&
        <View>
          <FieldsRow
            doc={look}
            fields={[
              {
                key: 'displayName', labelKey: 'look-name',
                kind: 'text', isTitle: true
              },
              {
                key: 'description', labelKey: 'look-description',
                kind: 'text'
              }
            ]}
          />
          <PhotoStrip
            sheetRef={look.ref} sheetCategory={category}
            referencePath={referencePath}
            style={{ marginTop: 16, marginBottom: 16 }}
          />
          <SheetSections doc={look} category={category} docIsLook />
          <View>
            {extraPhotoStrips?.map(kind =>
              <PhotoStrip
                key={kind}
                sheetRef={look.ref} sheetCategory={category}
                referencePath={referencePath}
                style={{ marginTop: 16, marginBottom: 16 }}
                kind={kind} />
            )}
          </View>
        </View>
      }
    </DetailPage>
  );
}
