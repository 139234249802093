import { isCharacterCategory, SheetCategory } from "./sheet";
import { SheetSubjectKind, SUBJECT_KINDS } from "./sheet-subject";
import { DocumentReference, DocumentSnapshot, Query } from "@atgof-firebase/types";

export type SubjectSpec = { subjectKind: SheetSubjectKind; subjectName: string };

export function hasSubject(q: Query, { subjectKind, subjectName }: SubjectSpec) {
  return q.where(subjectKind === 'character' ? 'cast' : 'sets', 'array-contains', subjectName);
}

export function subjectKindForCategory(category: SheetCategory | undefined) {
  if (isCharacterCategory(category)) return 'character';
  if (category === 'arts') return 'set';
  return;
}

export function subjectSpecForSheet(
  sheet: DocumentSnapshot | undefined
): SubjectSpec | undefined {
  const subjectKind = subjectKindForCategory(sheet?.get('category'));
  if (subjectKind) return { subjectKind, subjectName: sheet!.get(subjectKind) };
  return;
}

export function idForSubjectName(subjectName: string) {
  return subjectName.replace(/\//g, '_'); // TODO
}

export function subjectNameForId(subjectId: string | undefined) {
  return subjectId?.replace(/_/g, '/'); // TODO
}

export function subjectCollection(
  project: DocumentReference | undefined,
  subjectKind: string | undefined
) {
  if (!(subjectKind && SUBJECT_KINDS.includes(subjectKind as SheetSubjectKind))) {
    return;
  }
  return project?.collection(`${subjectKind}s`);
}

export function subjectRef(
  project: DocumentReference | undefined,
  { subjectKind, subjectName }: SubjectSpec
) {
  return subjectCollection(project, subjectKind)?.doc(
    idForSubjectName(subjectName)
  );
}

export function kindOfSubject(
  subjectRef: DocumentReference | undefined
): SheetSubjectKind | undefined {
  const collId = subjectRef?.parent.id;
  return (
    collId === 'characters' ?
      'character' : (collId === 'sets' ? 'set' : undefined)
  );
}

export function projectRefForSubject(
  subjectRef: DocumentReference | null | undefined
) {
  return subjectRef?.parent.parent;
}
