import { CoreComponentsContext } from "./core-components";

export function CompletionIndicator({ isComplete, hasPendingActions }: {
  isComplete: boolean;
  hasPendingActions: boolean
}) {
  return (
    <CoreComponentsContext.Consumer>
      {({ Text }) =>
        isComplete ?
          (hasPendingActions ?
            <Text style={{ color: "#FD8D0E" }} >⬤</Text >
            : <Text>✅</Text>) :
          null}
    </CoreComponentsContext.Consumer>
  );
}
