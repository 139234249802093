import React from "react";
import { LanguageContext } from "../common/language";
import FileImportButton from "../components/FileImportButton";
import ListPage from "../components/pages/ListPage";
import { useSeasons } from "../data/useSeasons";
import { RootTabScreenProps } from "../types";
import { CollectionListSpec } from "../components/CollectionList";
import { QueryDocumentSnapshot } from "@atgof-firebase/firebase";
import { episodeNumberLabel, formatTimestamp } from "../common/util";
import { useNavigation } from "@react-navigation/native";
import ListItem from "../components/ListItem";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { Episode, toEpisode } from "@atgof-common/episode";
import { EpisodeInfo } from "../components/EpisodeInfo";

function process(docs: QueryDocumentSnapshot[]): Episode[] {
  return docs.reverse().map(toEpisode);
}

export default function EpisodesScreen({ }: RootTabScreenProps<'episodes'>) {
  const { ph } = React.useContext(LanguageContext);
  const navigation = useNavigation();
  const renderItem = React.useCallback(
    ({ item }: { item: Episode }) => {
      const { path } = item;
      return (
        <ListItem
          onPress={() => navigation.navigate('episode', { episodePath: path })}>
          <View style={{ flexDirection: "row", alignContent: "flex-start", overflow: "hidden" }}>
            <View style={{}}>
              <Text variant="headlineLarge" style={{ marginRight: 20 }}>
                {episodeNumberLabel(path)}
              </Text>
            </View>
            <EpisodeInfo episode={item} />
          </View>
        </ListItem>
      );
    },
    [ph, navigation]);
  const seasons = useSeasons(true);
  const [collections, setCollections] = React.useState<CollectionListSpec<Episode, string>[]>();
  React.useEffect(() => setCollections(
    seasons?.map(season => ({
      collection: season.ref.collection('episodes'),
      process, renderItem,
      searchTokens: item => [
        item.director,
        formatTimestamp(item.txDate),
        ...item.path.split('/').reverse().slice(0, 2), // TODO This should be properly generalised
      ],
      headerData: ph('season') + ' ' + season.id,
      renderHeader: title => <Text variant="headlineLarge">{title}</Text>
    }))), [seasons, ph]);
  return (
    <ListPage
      headerRight={<FileImportButton />}
      collections={collections}
      estimatedItemSize={52}
    />
  );
}
