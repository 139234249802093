import { DocumentSnapshot } from "@atgof-firebase/types";
import { View, ViewStyle } from "react-native";
import { Chip, Text } from "react-native-paper";

export function LookChip({ look, concise, style }: {
  look: DocumentSnapshot;
  concise?: boolean;
  style?: ViewStyle
}) {
  const desc: string | undefined = concise ? undefined : look.get('description');
  return (
    <View style={style}>
      <View style={{ flexDirection: "row" }}>
        <Chip>{look.get('displayName')}</Chip>
      </View>
      {desc ? <Text variant="bodyMedium">{desc}</Text> : null}
    </View>
  );
}

