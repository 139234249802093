import { z } from 'zod';
import { reference } from "../reference";
import { SpecForSubject, Subject } from '../subject';

export const setReference = reference;
export const setId = z.string();

export const setSpec = new SpecForSubject({}, {
  singlePhraseKey: 'set',
  pluralPhraseKey: 'sets'
});

export type Set_ = Subject
