import { Auth, Firestore } from "@atgof-firebase/firebase";

export const overrideUris: {
  functions: string;
  storage: string
} | undefined = undefined;

export const shouldUsePersistence = true;


export function configureFirebase(
  _: Auth | undefined,
  __: Promise<Firestore> | undefined
) { }
