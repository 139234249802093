import React from "react";
import { TextInput, View } from "react-native";
import { matchesSearch, TokenisedCandidate, tokeniseQuery } from "../common/search";
import { Searchbar, Text } from "react-native-paper";
import Menu from "./Menu";
import { LanguageContext } from "../common/language";
import { PhraseKey } from "../common/phrases";

export function SearchableMenu<T>({
  candidateList,
  entryKey,
  labelFromTextInput,
  searchTokens,
  render,
  onSelect,
  onAdd,
  onDismiss,
  placeholder,
  additionPhraseKey = 'add',
  showMenuWhenSearchEmpty = true,
  autoFocus = true
}: {
  candidateList: T[];
  entryKey: (entry: T) => string;
  labelFromTextInput?: (text: string) => string | undefined;
  searchTokens: (entry: T) => string[];
  render: (entry: T) => string; // TODO React.ReactNode;
  onSelect: (entry: T) => void;
  onAdd?: (text: string) => void;
  onDismiss?: () => void;
  placeholder?: string;
  additionPhraseKey?: PhraseKey;
  showMenuWhenSearchEmpty?: boolean;
  autoFocus?: boolean;
}) {
  const { ph } = React.useContext(LanguageContext);
  const searchbarRef = React.useRef<TextInput>(null);
  React.useEffect(() => {
    if (autoFocus) searchbarRef?.current?.focus();
  }, [searchbarRef, autoFocus]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [showMenu, setShowMenu] = React.useState(false);
  const queryTokens = React.useMemo(() => tokeniseQuery(searchQuery) ?? [], [searchQuery]);
  function showMenuIfNeeded(_?: any, focused?: boolean) {
    if (!(focused || searchbarRef?.current?.isFocused())) return;
    setShowMenu(showMenuWhenSearchEmpty || searchQuery.length > 0);
  }
  React.useEffect(showMenuIfNeeded, [showMenuWhenSearchEmpty, searchQuery]);
  function reset() { setSearchQuery(""); }
  // TODO Show the adder if an inexact match (see multiple words)
  const list = (
    queryTokens.length ?
      candidateList.filter(entry => matchesSearch(queryTokens, searchTokens(entry))) :
      candidateList
  ).map(entry =>
    <Menu.Item
      key={entryKey(entry)}
      title={render(entry)}
      onPress={() => {
        onSelect(entry);
        reset();
      }}
    />);
  const entryToAdd = React.useMemo(
    () => {
      const label = labelFromTextInput ? labelFromTextInput(searchQuery) : undefined;
      if (!label) return;
      const hasExactMatch = candidateList.find(candidate => render(candidate) === label); // TODO Use searchTokeniser from Spec.ts and proper search functionality
      if (hasExactMatch || !onAdd) return; // TODO Show "Not found" if !onAdd
      return (
        <Menu.Item
          key="_add"
          title={
            <View style={{ flexDirection: "row" }}>
              <Text>{ph(additionPhraseKey) + (placeholder ? ' ' + placeholder : '') + ' "'}</Text>
              <Text style={{ fontStyle: "italic" }}>{label}</Text>
              <Text>"</Text>
            </View>
          }
          onPress={() => { if (onAdd) onAdd(label); }}
        />
      );
    },
    [searchQuery, candidateList, onAdd !== undefined]
  );
  return (
    <Menu
      visible={showMenu}
      onDismiss={() => {
        setShowMenu(false);
        searchbarRef.current?.focus();
      }}
      anchorPosition="bottom"
      suppressAutofocus
      anchor={
        <Searchbar
          style={{ minWidth: 200 }}
          ref={searchbarRef}
          value={searchQuery}
          placeholder={placeholder}
          onChangeText={setSearchQuery}
          onIconPress={showMenuIfNeeded}
          onSubmitEditing={showMenuIfNeeded}
          onFocus={() => showMenuIfNeeded({}, true)}
          onClearIconPress={() => {
            reset();
            if (onDismiss) onDismiss();
          }}
          onBlur={() => {
            if (!searchQuery.length && onDismiss) onDismiss();
          }}
        />
      }
    >
      {[entryToAdd ?? null, ...list]}
    </Menu>
  );
}
