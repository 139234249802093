import { LanguageContext } from "../common/language";
import Labelled from "./LabelledSelect";
import React from "react";
import Select from "./Select";
import { Scene } from "../common/scene";
import { normaliseSubjectName } from "../common/util";
import { SearchableMenu } from "./SearchableMenu";
import { usePreexistingSubjects } from "./SceneInfo";
import { useProject, useSubjects } from "../data/useProject";
import { ProgressBar } from "react-native-paper";
import { View } from "react-native";

type SubjectEntry = {
  displayName: string;
} | 'other'

function transformEnteredValue(v: string) {
  const displayName = normaliseSubjectName(v);
  if (!displayName) return;
  return { displayName };
}

function OtherSubjectSelector({
  kind, scene, onCommit
}: {
  kind: 'character' | 'set';
  scene: Scene
  onCommit: (value: string | undefined) => void;
}) {
  const project = useProject();
  const subjects = useSubjects(
    project, kind === 'character' ? 'characters' : 'sets'
  );
  if (!subjects) return <ProgressBar indeterminate />;
  return (
    <SearchableMenu
      candidateList={subjects}
      entryKey={({ displayName }) => displayName}
      labelFromTextInput={normaliseSubjectName}
      searchTokens={({ displayName }) => [displayName]}
      render={({ displayName }) => displayName}
      onSelect={({ displayName }) => onCommit(displayName)}
      onAdd={onCommit}
    />
  );
}

export default function SubjectSelector({
  kind, value, onChange, scene
}: {
  kind: 'character' | 'set';
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  scene: Scene
}) {
  const { ph } = React.useContext(LanguageContext);
  const subjects = kind == 'character' ? scene.cast : scene.sets;
  const entries = React.useMemo<SubjectEntry[] | undefined>(
    () => subjects ?
      [...subjects.map(displayName => ({ displayName })), 'other'] :
      undefined,
    [subjects]
  );
  const [selectedEntry, setSelectedEntry] = React.useState<SubjectEntry>();
  React.useEffect(
    () => setSelectedEntry(value ? { displayName: value } : undefined),
    [value]
  );
  function onEntryChange(entry: SubjectEntry | undefined) {
    setSelectedEntry(entry);
    if (entry !== 'other') onChange(entry?.displayName);
  }
  return (
    <Labelled label={ph(kind) as string}>
      <View style={{ flexDirection: "row", gap: 8, alignItems: "center" }}>
        <Select
          entries={entries}
          entryKey={entry => entry === 'other' ? '!__other__!' : entry.displayName}
          entryLabel={
            entry => entry === 'other' ?
              ph('other') as string :
              entry.displayName
          }
          onEntryChange={onEntryChange}
          selectedEntry={selectedEntry}
          resetSelectionIfNotPresent={false}
        />
        {selectedEntry === 'other' ?
          <OtherSubjectSelector
            kind={kind} scene={scene} onCommit={onChange} />
          : null}
      </View>
    </Labelled>
  );
}
