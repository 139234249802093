import { DocumentReference } from "@atgof-firebase/types";
import { processIdentifier } from "../common/file-import";
import { objectCreationAttributes } from "./util";
import { episodeSchema } from "../common/generated/entities/project/season/episode";

export async function addEpisode(userRef: DocumentReference, ref: DocumentReference) {
  const episodeRef = ref.parent.doc(processIdentifier(ref.id)); // TODO More sanitising here
  await episodeRef.set(episodeSchema.parse({
    ...objectCreationAttributes(userRef),
    displayName: episodeRef.id
  }));
}
