import { View } from "react-native";
import { DocumentReference, DocumentSnapshot } from "@atgof-firebase/firebase";
import React from "react";
import { useProject } from "../data/useProject";
import { LanguageContext } from "../common/language";
import { SheetCategory, sheetColour } from "../common/sheet";
import { sheetsCollection } from "../common/sheet";
import { useSheets } from "../data/useSheets";
import { Text, useTheme } from "react-native-paper";
import { takeRow, getRows, compareSheets } from "../common/sheet/pa";
import { useDocument } from "../data/useDocument";
import { Field, keysForField } from "@atgof-common/helpers/fields";

function GraphicsEntries({ scene }: { scene: DocumentReference | undefined }) {
  const { ph } = React.useContext(LanguageContext);
  const project = useProject();
  const [sheets, setSheets] = React.useState<DocumentSnapshot[]>();
  React.useEffect(
    () => {
      if (scene && project) {
        return sheetsCollection(project)
          .where('scene', '==', scene).where('category', '==', 'arts')
          .where('deleted', '==', false)
          .onSnapshot(snapshot => setSheets(snapshot.docs));
      }
      setSheets(undefined);
    },
    [scene?.path, project?.path]
  );
  const notes: string[] =
    (sheets || []).flatMap(sheet => ((sheet.get('arts:graphics') as string[] | undefined) || []));
  if (notes.length == 0) return null;
  return (
    <View style={{ marginHorizontal: 16 }}>
      <Text variant="headlineMedium">{ph('arts:graphics') as string}</Text>
      {notes.map((text, i) => <Text key={i}>{text}</Text>)}
    </View>
  );
}

export const borderWidth = 0.5;

export const fixtureBottomBorder = {
  borderBottomWidth: borderWidth,
  borderBottomStyle: "solid"
};

export function TakeInfo({ sheet, take }: {
  sheet: DocumentSnapshot | undefined;
  take: DocumentSnapshot | undefined
}) {
  const { colors } = useTheme();
  if (!sheet) return null;
  const index: number | undefined = take?.get('index');
  const isPickup: boolean | undefined = take?.get('isPickup');
  const isPreferred = take?.get('preferred');
  return (
    <Text style={{
      width: 40,
      padding: 2, borderRadius: 2,
      color: take ? colors.onBackground : colors.secondary,
      ...(isPreferred ?
        { backgroundColor: sheetColour(sheet), color: 'white' } : {})
    }}>
      {take ? `${index !== undefined ? index + 1 : ''}${isPickup ? 'PU' : ''}` : '#'}
    </Text>
  );
}


function DataField(
  { children, label, showSeparator = false }: React.PropsWithChildren<{
    label: string | undefined; // TODO This is not a phrase key here, unlike in functions/src/exporters/pdf/DataField.tsx. Implement labelIsPhraseKey here, or refactor
    showSeparator?: boolean;
  }>) {
  const { colors } = useTheme();
  return (
    <View>
      {label ?
        <View style={
          showSeparator ?
            {
              ...fixtureBottomBorder,
              borderBottomColor: colors.secondary,
              paddingBottom: 4
            } :
            {}
        }>
          <Text style={{
            color: colors.secondary,
          }}>
            {label}
          </Text>
        </View> :
        null}
      {typeof children === 'string' ?
        <Text style={{ marginTop: 4 }}>{children}</Text> : children}
    </View>
  );
}

export function SheetField({
  category, field, doc, noSuffix, noLabels, noBorders, composite, isLast
}: {
  category: SheetCategory;
  field: Field;
  doc: DocumentSnapshot | undefined;
  noSuffix: boolean; noLabels: boolean; noBorders: boolean;
  composite: boolean; isLast: boolean;
}) {
  const { colors } = useTheme();
  const { ph } = React.useContext(LanguageContext);
  const { kind } = field;
  const { k, labelK } = keysForField(field, noSuffix ? undefined : category);
  const labelSuffix = 'labelSuffix' in field ? field.labelSuffix : undefined;
  if (k === 'pa:slate')
    return null; // TODO Bit of a hack
  let v = k ? doc?.get(k) : undefined;
  let children: JSX.Element | undefined;
  if (typeof kind === 'object') {
    if ('select' in kind)
      v = ph(v.key);
    else if ('composite' in kind) {
      children =
        <SheetRow
          category={category}
          fields={kind.composite}
          doc={doc}
          noLabels={noLabels}
          noBorders={noBorders}
          composite
        />;
    }
  }
  const width = children ?
    undefined :
    (k === 'pa:cameraCardNumber' || k === 'pa:soundCardNumber' ?
      75 :
      (k === 'pa:dialogue' ?
        100 :
        (k === 'pa:description' ?
          400 :
          (kind === 'longText' || kind === 'paragraph' ? 250 : 125))));
  const text: string | undefined = v?.toString();
  return (
    <View style={{
      width,
      ...(composite && !isLast ?
        {
          borderRightColor: colors.secondary, borderRightWidth: borderWidth,
          borderRightStyle: "solid"
        } :
        {}),
      ...(noBorders ? { padding: 2 } :
        {
          margin: 8, padding: 8, paddingTop: 2
        }
      ),
      ...(composite ? { marginTop: 0, paddingTop: 4 } : {})
    }}>
      <DataField
        label={noLabels || !labelK ?
          undefined : ph(labelK) + (labelSuffix ?? '')}
        showSeparator={!(composite || noBorders)}
      >
        {children ||
          <View style={{
            marginTop: noBorders ? undefined : 6,
            minHeight: noBorders ? undefined : 16,
            gap: 8
          }}>
            {text?.split('\n').map((line, i) => <Text key={i}>{line}</Text>)}
          </View>}
      </DataField>
    </View>
  );
}

function SheetRow(
  {
    category, fields,
    composite = false, noSuffix = false, noLabels = false, noBorders = false,
    ...props
  }: {
    category: SheetCategory;
    fields: Field[];
    composite?: boolean;
    noSuffix?: boolean;
    noLabels?: boolean;
    noBorders?: boolean;
    doc: DocumentSnapshot | undefined;
  }
) {
  return (
    <View style={{
      display: "flex",
      flexDirection: "row",
      flexWrap: composite ? undefined : "wrap"
    }}>
      {fields.map((field, i) =>
        <SheetField
          key={i}
          category={category}
          field={field}
          noSuffix={noSuffix || composite}
          noLabels={noLabels}
          noBorders={noBorders}
          composite={composite}
          isLast={i == fields.length - 1}
          {...props}
        />)}
    </View>
  );
}

const takeFields = takeRow.filter(row => !('key' in row && row.key === 'preferred'));

export function Takes({ sheet, noSlates }: {
  sheet: DocumentSnapshot | undefined;
  noSlates: boolean
}) {
  const { dark, colors } = useTheme();
  const { ph } = React.useContext(LanguageContext);
  const [takes, setTakes] = React.useState<DocumentSnapshot[]>();
  React.useEffect(
    () => sheet?.ref.collection('takes').where('deleted', '==', false)
      .orderBy('index', 'asc')
      .onSnapshot(({ docs }) => setTakes(docs)),
    [sheet?.ref.path]);
  const slate: string | undefined = sheet?.get('pa:slate');
  if (!sheet) return null;
  return (
    <View style={{ margin: 16, gap: 16 }}>
      <Text variant="headlineMedium">{ph(noSlates ? 'shot' : 'pa:slate') + ' ' + slate}</Text>
      <View>
        {[undefined, ...(takes ?? [])].map((take, i) =>
          <View key={i} style={{
            flexDirection: "row",
            ...(take ?
              {
                backgroundColor: i % 2 == 0 ?
                  (dark ? '#3E3E3E' : '#eeeeee') : undefined
              } :
              {
                ...fixtureBottomBorder,
                borderBottomColor: colors.secondary
              })
          }}>
            <TakeInfo sheet={sheet} take={take} />
            <SheetRow
              category="pa"
              fields={takeFields}
              doc={take}
              noSuffix
              noLabels={take !== undefined}
              noBorders
            />
          </View>)}
      </View>
      <View>
        {getRows(false, sheet?.get('pa:cameras')).map((row, i) =>
          <SheetRow key={i} category="pa" fields={row} doc={sheet} />)}
      </View>
    </View>
  );
}

export function PostProductionInfo({ scene }: { scene: DocumentReference | undefined }) {
  const noSlates = useDocument(useProject())?.get('noSlates') ?? false;
  const sheets = useSheets(scene, ['pa']);
  return (
    <View style={{ gap: 16, margin: 16 }}>
      {scene && <GraphicsEntries scene={scene} />}
      <View style={{ gap: 8 }}>
        {sheets?.sort(compareSheets)
          .map(sheet => <Takes key={sheet.id} sheet={sheet} noSlates={noSlates} />)}
      </View>
    </View>
  );
}
