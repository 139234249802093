import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { DocumentReference, DocumentSnapshot, FieldValue, Query } from "@atgof-firebase/firebase";
import FieldsRow from "../components/fields/FieldsRow";
import { Field } from "../common/helpers/fields";
import { RootStackParamList } from "../types";
import React from "react";
import NavPage from "../components/pages/NavPage";
import { useSheetsCollectionSpec } from "../data/useSheetsCollectionSpec";
import CollectionList, { useCollectionItems } from "../components/CollectionList";
import { ScriptButton } from "../components/ScriptButton";
import PASceneInfo from "../components/PASceneInfo";
import Select from "../components/Select";
import { compareScenes, sortScenes, toScene } from "../common/scene";
import { LanguageContext } from "../common/language";
import Labelled from "../components/LabelledSelect";
import { ProjectContext } from "../data/projectContext";
import { useDocument } from "../data/useDocument";
import { useDocumentReference } from "../data/firestore";
import SceneInfo from "../components/SceneInfo";
import { PostProductionSceneOptions } from "../components/PostProductionSceneOptions";
import { View } from "react-native";

const ROWS: Field[][] = [
  [
    { key: "isComplete", kind: { flags: [{ key: 'isComplete', labelKey: "" }] } },
    {
      key: "actions",
      kind: {
        composite: [
          { key: "isDone", kind: { flags: [{ key: 'isDone', labelKey: "" }] } },
          { key: "description", kind: 'longText' }
        ]
      },
      multi: { explicitAdd: {} }
    }
  ]
  /*[
      {
          key: "set", isTitle: true,
          kind: {
              reference: { collection: "sets" }
          },
      },
      {
          key: "cast", multi: {},
          kind: {
              reference: { collection: "characters" }
          },
      },
  ],
  [
      { key: "synopsis", kind: 'longText' },
  ],
  [
      {
          labelKey: "int-ext",
          kind: {
              flags: [{ key: "isInterior", labelKey: "int" }, { key: "isExterior", labelKey: "ext" }]
          }
      },
      { key: "timeOfDay", labelKey: "tod", kind: { select: ["day", "night", "dawn", "dusk"] } },
      { key: "pages", kind: 'shortText' },
  ],
  [
      { key: "notes", kind: 'paragraph' },
      { key: "extras", multi: {} },
  ]*/
];

function indexInFilmingSequence(sceneRef: DocumentReference | undefined, sequence: DocumentReference[] | undefined) {
  if (!(sceneRef && sequence)) return -1;
  return sequence.findIndex(ref => ref.path === sceneRef.path);
}

function nextInFilmingSequence(
  sceneRef: DocumentReference | undefined, sequence: DocumentReference[] | undefined
) {
  const i = indexInFilmingSequence(sceneRef, sequence);
  if (sequence && i >= 0 && i + 1 < sequence.length) return sequence[i + 1];
  return;
}

async function updateFilmingSequence(
  sceneRef: DocumentReference | undefined, runsIntoSceneRef: DocumentReference | undefined,
  sequence: DocumentReference[] | undefined) {
  if (!sceneRef) return;
  let seq = sequence ? [...sequence] : [];
  let i = indexInFilmingSequence(sceneRef, seq);
  if (i < 0) {
    seq.push(sceneRef);
    i = seq.length - 1;
  }
  const origTail = seq.slice(i + 1);
  const head = seq.slice(0, i + 1);
  const runsIntoScene = await runsIntoSceneRef?.get();
  const runsIntoSeq: DocumentReference[] = runsIntoScene?.get('continuousFilmingSequence') ?? [];
  const runsIntoI = indexInFilmingSequence(runsIntoSceneRef, runsIntoSeq);
  const newTail = runsIntoI < 0 ? (runsIntoSceneRef ? [runsIntoSceneRef] : []) : runsIntoSeq.slice(runsIntoI);
  const newSeq = [...head, ...newTail];
  for (const ref of newSeq) ref.update({ continuousFilmingSequence: newSeq });
  for (const ref of origTail) {
    if (!newTail.find(r => r.path === ref.path)) ref.update({ continuousFilmingSequence: FieldValue.delete() });
  }
}

function AdminSettings({ scene }: { scene: DocumentSnapshot | undefined }) {
  const { ph } = React.useContext(LanguageContext);
  const collSpec = React.useMemo(
    () => {
      const collection = scene?.ref.parent;
      return collection ? {
        collection,
        process: (snapshots: DocumentSnapshot[]) => snapshots.map(snapshot => snapshot.ref)
      } : undefined;
    },
    [scene?.ref.parent.path]
  );
  const scenes = useCollectionItems<DocumentReference>(collSpec);
  const selectableScenes = React.useMemo(
    () => scene && scenes ?
      sortScenes(scenes.filter(otherScene => compareScenes(scene, otherScene) < 0)) : undefined,
    [scene, scenes]
  );
  const sequence: DocumentReference[] | undefined = scene?.get('continuousFilmingSequence');
  const sequenceStr = React.useMemo(() => JSON.stringify(sequence?.map(ref => ref.path)), [sequence]);
  const selectedEntry = React.useMemo(
    () => nextInFilmingSequence(scene?.ref, sequence),
    [scene?.ref.path, sequenceStr]
  );
  const onEntryChange = React.useCallback(
    (ref: DocumentReference | undefined) => updateFilmingSequence(scene?.ref, ref, sequence),
    [scene?.ref.path, sequenceStr]
  );
  return (
    <React.Fragment>
      <View style={{ flexDirection: "row" }}>
        <Labelled label={ph('runs-into') as string}>
          <Select
            entries={selectableScenes} entryKey={ref => ref?.id ?? ''}
            entryLabel={ref => ref.id}
            onEntryChange={onEntryChange} selectedEntry={selectedEntry}
            includeNone highlightUnselected={false} resetSelectionIfNotPresent={false} />
        </Labelled>
      </View>
      {ROWS.map((fields, i) => <FieldsRow key={i} doc={scene} fields={fields} />)}
    </React.Fragment>
  );
}

export default function SceneScreen({ route }: NativeStackScreenProps<RootStackParamList, 'scene'>) {
  const { scenePath } = route.params;
  const { categories } = React.useContext(ProjectContext);
  const scene = useDocument(useDocumentReference(scenePath));
  const addConstraints = React.useCallback(
    (q: Query) => scene ? q.where('scene', '==', scene.ref) : undefined,
    [scene?.ref.path]);
  const sheetsCollection = useSheetsCollectionSpec({ addConstraints });
  const isPA = categories.includes('pa');
  const showAdminSettings = React.useMemo(
    () => isPA || categories.includes('admin'),
    [isPA, categories]
  );
  const scene_ = scene ? toScene(scene) : undefined;
  return (
    <NavPage fullScreen
      isLoading={!sheetsCollection}
      headerRight={<ScriptButton parent={scene_} />}
      newSheetContext={{ sceneRef: scene?.ref }}
    >
      {scene_ ? <SceneInfo scene={scene_} /> : null}
      {scene_ && categories.includes('postProduction') &&
        <PostProductionSceneOptions scene={scene_} horizontal />}
      {showAdminSettings && <AdminSettings scene={scene} />}
      {isPA && <PASceneInfo scene={scene?.ref} />}
      {sheetsCollection && <CollectionList collections={[sheetsCollection]} estimatedItemSize={85} />}
    </NavPage>
  );
}
