import { DocumentSnapshot, FieldValue } from "@atgof-firebase/firebase";
import React from "react";
import { LanguageContext } from "../common/language";
import { FieldSpecs, SheetCategory, sheetsCollection } from "../common/sheet";
import { Text, useTheme } from "react-native-paper";
import { TextStyle, View } from "react-native";
import { useProject } from "../data/useProject";
import { UserContext } from "../data/userContext";
import { keysForField } from "../common/helpers/fields";
import { episodeForScene } from "../common/episode";
import { Scene } from "../common/scene";
import { ProjectContext } from "../data/projectContext";
import { CellRenderProps, ScenesTable, ScenesTableColumnGroup, ScenesTableSection, SheetWithLooks, locationColumn, sceneColumn } from "./ScenesTable";
import { Editable } from "./Editable";
import { ScriptButton } from "./ScriptButton";
import { SceneButton } from "./SceneButton";
import { MembershipCategory } from "../common/model/project/membership";
import { useUserInkColours } from "../data/useUserInkColours";
import { UserInkColour } from "../common/membership";
import { useFieldSpecs } from "../data/useFieldSpecs";
import { PostProductionSceneOptions } from "./PostProductionSceneOptions";
import { UserColoursKey } from "./UserColoursKey";
import { LookChip } from "./LookChip";
import { SubjectButton } from "./SubjectButton";

export type BreakdownTableSection = ScenesTableSection;

type SheetColumn = {
  category: SheetCategory,
  attr: string;
  labelK: string;
  isMulti: boolean
}

function sheetColumnsForCategory(fieldSpecs: FieldSpecs, category: MembershipCategory): SheetColumn[] {
  const sections = fieldSpecs[category];
  let columns: SheetColumn[] = [];
  const sectionKs = Object.keys(sections ?? {});
  for (const sectionK of sectionKs) {
    for (const field of sections[sectionK]) {
      const { k, labelK } = keysForField(field, category);
      if ('tableColumn' in field && k) {
        const tableColumn = field['tableColumn'];
        if (tableColumn && k && labelK) {
          columns.push({
            category: category as SheetCategory,
            labelK,
            attr: k, isMulti: field.multi !== undefined
          });
        }
      }
    }
  }
  return columns;
}

type EditableCellProps = {
  attr: string;
  readonly: boolean;
  isMulti: boolean;
  scene: Scene;
  category: SheetCategory;
  snapshot: DocumentSnapshot | undefined;
  character: string | undefined;
  inkColours: UserInkColour[]
}

function EditableCell({
  attr, readonly, isMulti, scene, snapshot, category, character, inkColours
}: EditableCellProps) {
  const project = useProject();
  const { dark, colors } = useTheme();
  const { user } = React.useContext(UserContext);
  const value = snapshot?.get(attr);
  const lastModifiedByUserId: string | undefined =
    snapshot?.get(`latestModifications.${attr}.u`);
  const inkColour = inkColours
    .find(({ userId }) => userId === lastModifiedByUserId)?.inkColour;
  const ref = React.useMemo(
    () => snapshot?.ref ?? (project ? sheetsCollection(project).doc() : undefined),
    [snapshot?.ref, project]
  );
  const exists = snapshot !== undefined;
  const onChange = React.useCallback(
    (newValue: string[] | string) => {
      if (value === newValue || (!exists && newValue.length == 0)) return;
      const { sets } = scene;
      const modFields = {
        lastModifiedBy: user.ref,
        lastModifiedAt: FieldValue.serverTimestamp(),
        [attr]: newValue
      };
      if (exists) ref?.update(modFields);
      else ref?.set({
        scene: scene.ref,
        episode: episodeForScene(scene.ref),
        ...(!character && sets?.length ? { set: sets[0] } : {}), // TODO
        ...(character ? { character } : {}),
        category,
        deleted: false,
        createdBy: user.ref,
        createdAt: FieldValue.serverTimestamp(),
        ...modFields
      });
    },
    [value, ref, exists, scene, user]
  );
  const style: TextStyle = {
    alignSelf: 'flex-start',
    color: inkColour ? (dark ? inkColour.dark : inkColour.light) : colors.onBackground
  };
  if (isMulti) { // TODO This could do with more robust support
    const values: string[] = value ?? [""];
    return values.map((v, i) =>
      <Editable
        key={i}
        style={style}
        readonly={readonly}
        text={v}
        onChange={newValue => {
          let values_ = [...values];
          values_[i] = newValue;
          onChange(values_);
        }}
      />);
  }
  return (
    <Editable
      style={style}
      readonly={readonly}
      text={value ?? ""}
      onChange={onChange}
    />
  );
}

function EditableCellWithLooks(
  { item, ...props }: {
    item: SheetWithLooks | undefined
  } & Omit<EditableCellProps, 'snapshot'>
) {
  const { colors } = useTheme();
  return (
    <View style={{ gap: 8 }} >
      {
        item?.looks.map(look =>
          <View key={look.ref.path} style={{
            borderBottomColor: colors.primaryContainer,
            borderBottomWidth: 4,
            paddingVertical: 8,
            gap: 4,
          }}>
            <LookChip style={{ alignSelf: "center" }} look={look} concise />
            <EditableCell
              {...props}
              snapshot={look}
              readonly
            />
          </View>)
      }
      < EditableCell snapshot={item?.sheet} {...props} />
    </View >
  );
}

export function BreakdownTable({ sections }: { sections: BreakdownTableSection[] }) {
  const { ph } = React.useContext(LanguageContext);
  const { categories, adminCategories } = React.useContext(ProjectContext);
  const fieldSpecs = useFieldSpecs();
  const inkColours = useUserInkColours();
  const columnGroups = React.useMemo<ScenesTableColumnGroup[]>(
    () => [
      {
        key: 'core',
        kind: 'scene',
        columns: [
          sceneColumn(
            ph,
            scene => <React.Fragment>
              {categories.includes('postProduction') ?
                <PostProductionSceneOptions
                  scene={scene}
                  style={{ margin: 0, padding: 0 }}
                /> : null}
              <SceneButton scene={scene} />
              <ScriptButton parent={scene} />
            </React.Fragment>
          ),
          locationColumn(ph)
        ]
      },
      {
        key: 'character',
        kind: 'character',
        columns: [
          {
            key: 'character',
            label: ph('character') as string,
            render: ({ item, scene }: CellRenderProps<string>) =>
              <SubjectButton
                subjectKind="character"
                subjectName={item}
                episodePath={episodeForScene(scene.ref)?.path}
                fallback={<Text>{item}</Text>}
              />
          }
        ]
      },
      ...categories.map(category => {
        return {
          key: category,
          kind: { sheetCategory: category as SheetCategory }, // TODO Shouldn't really cast this here
          label: ph(category) as string,
          columns: sheetColumnsForCategory(fieldSpecs, category).map(({ labelK, attr, isMulti }) => ({
            key: attr,
            label: ph(labelK) as string,
            render: ({
              item,
              scene,
              category,
              character
            }: CellRenderProps<SheetWithLooks | undefined>) =>
              category ?
                <EditableCellWithLooks
                  item={item}
                  readonly={!adminCategories.includes(category)}
                  character={character}
                  attr={attr}
                  isMulti={isMulti}
                  scene={scene}
                  category={category}
                  inkColours={inkColours ?? []}
                /> :
                null
          }))
        };
      })
    ],
    [ph, fieldSpecs, categories, inkColours]
  );
  return (
    <View style={{ flex: 1, gap: 4 }}>
      <UserColoursKey value={inkColours} />
      <ScenesTable sections={sections} columnGroups={columnGroups} />
    </View>
  );
}
