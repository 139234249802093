import React from "react";
import { TextStyle } from "react-native";
import { TextInput, textForEvent } from "./fields/TextInput";
import { Text, useTheme } from "react-native-paper";

export function Editable({ text, readonly = false, onChange, style = {}, paperTheme }: {
  text: string;
  readonly?: boolean;
  onChange: (v: string) => void;
  style?: TextStyle;
  paperTheme?: boolean
}) {
  const { colors, fonts } = useTheme();
  const styleExtra = paperTheme ?
    {
      background: "solid",
      backgroundColor: colors.elevation.level3,
      padding: 8,
      ...fonts.bodyMedium,
      lineHeight: 1
    } : {
      backgroundColor: "none",
      background: "none"
    };
  const timeout = React.useRef<ReturnType<typeof setTimeout>>();
  const [value, setValue] = React.useState(text ?? "");
  React.useEffect(() => {
    setValue(text ?? "");
  }, [text]);
  if (readonly) return <Text style={style}>{text}</Text>;
  return (
    <TextInput
      style={{
        ...(style as any),
        ...styleExtra,
        borderWidth: 0,
        resize: "none"
      }}
      value={value}
      onChange={event => {
        if (timeout.current !== undefined) {
          clearTimeout(timeout.current);
          timeout.current = undefined;
        }
        const v = textForEvent(event);
        setValue(v);
        timeout.current = setTimeout(() => onChange(v), 1000);
      }}
      onBlur={() => {
        if (value !== text) {
          if (timeout.current) {
            clearTimeout(timeout.current);
            timeout.current = undefined;
          }
          onChange(value);
        }
      }}
    />
  );
}
