import { DocumentReference, DocumentSnapshot, Query } from "@atgof-firebase/firebase";
import { roleCategories } from "./user";
import { MembershipCategory } from "../common/model/project/membership";
import { Project } from "../common/model/project";
import { Season } from "../common/model/project/season";
import { Entity } from "../common/model/Spec";

export type ProjectState = {
  project: DocumentReference | undefined
  categories: MembershipCategory[];
  adminCategories: MembershipCategory[];
  characters: string[]
}

export function projectOfMembership(membership: DocumentSnapshot | undefined) {
  return membership?.ref.parent.parent ?? undefined;
}

export function projectStateFor(membership: DocumentSnapshot | undefined): ProjectState {
  const roles = membership?.get('roles') || {};
  return {
    project: projectOfMembership(membership),
    categories: roleCategories(roles, 'all'),
    adminCategories: roleCategories(roles, 'admin'),
    characters: ('cast' in roles ? roles.cast['characters'] : undefined) ?? []
  };
}

export function seasonQuery(project: undefined, date: Date | undefined): undefined;
export function seasonQuery(project: Project, date: Date | undefined): Query;

export function seasonQuery(
  project: Entity<Project> | undefined,
  date: Date | undefined
): Season | undefined {
  return project?._ref
}
