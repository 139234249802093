import { DocumentReference, DocumentSnapshot } from "@atgof-firebase/types";
import { hasSubject, subjectRef, SubjectSpec } from "./subject";
import { OnNext, onSnapshots } from "./onSnapshot";
import { onSheets, SheetCategory } from "./sheet";
import { EpisodePath, getEpisodePath, perEpisodeRef } from "./episode";
import { onScenesInParent, Scene } from "./scene";
import _ from "lodash";
import { notDeleted } from "./general";

export type EpisodeBreakdown = {
  episodeLookSheets: Record<string, DocumentSnapshot>;
}

export function getProjectRef(
  episodeRef: DocumentReference,
  episodePath: EpisodePath = getEpisodePath(episodeRef)
) {
  return episodePath.project ?
    episodeRef.firestore.collection('projects').doc(episodePath.project) : undefined;
}

export type SceneSheets = { scene: Scene, sheets: DocumentSnapshot[] };

export function onEpisodeSceneSheets(
  episodeRef: DocumentReference,
  categories: readonly SheetCategory[],
  subject: SubjectSpec,
  onNext: OnNext<SceneSheets[] | undefined>
) {
  const projectRef = getProjectRef(episodeRef, getEpisodePath(episodeRef));
  return onScenesInParent(
    episodeRef,
    scenes =>
      onSnapshots(
        scenes,
        scene => (
          onNext => onSheets(
            projectRef,
            [scene.ref],
            categories,
            subject,
            (sheets: DocumentSnapshot[] | undefined) => sheets ?
              onNext({ scene, sheets }) : undefined
          )
        ),
        onNext
      ),
    q => hasSubject(q, subject)
  );
}

export function getEpisodeLooksRef(
  episodeRef: DocumentReference,
  subject: SubjectSpec
) {
  const episodePath = getEpisodePath(episodeRef);
  return perEpisodeRef(
    subjectRef(getProjectRef(episodeRef, episodePath), subject), episodePath
  )?.collection('looks');
}

export function onEpisodeLookSheets(
  episodeRef: DocumentReference,
  categories: readonly SheetCategory[],
  subject: SubjectSpec,
  onNext: OnNext<Record<SheetCategory, DocumentSnapshot[]> | undefined>
) {
  const episodeLooksRef = getEpisodeLooksRef(episodeRef, subject);
  if (!episodeLooksRef) return;
  return onSnapshots<SheetCategory, Record<SheetCategory, DocumentSnapshot[]>>(
    categories,
    category => {
      return onNext => notDeleted(episodeLooksRef.where('category', '==', category))
        .orderBy('order', 'asc')
        .onSnapshot(
          snapshot => onNext({ [category]: snapshot.docs } as Record<SheetCategory, DocumentSnapshot[]>)
        )
    },
    entries => onNext(
      entries?.reduce(
        (acc, entry) => ({ ...acc, ...entry }),
        {}
      ) as Record<SheetCategory, DocumentSnapshot[]>)
  );
}
