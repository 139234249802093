import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import {
  FieldValue as FieldValue_,
  Timestamp as Timestamp_
} from './types';

export * from './types';

export const initializeApp = firebase.initializeApp;
export async function initializeFirestore(
  app: firebase.app.App,
  enablePersistence: boolean = true
) {
  const db = firebase.firestore(app);
  if (enablePersistence) {
    try {
      db.settings({
        cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
      });
      await db.enablePersistence();
    }
    catch (e) {
      console.error("Unable to enable firestore persistence", e);
    }
  }
  return db;
}
export const getAuth = firebase.auth;
export const getStorage = firebase.storage;

export function connectFirestoreEmulator(db: firebase.firestore.Firestore, host: string, port: number) {
  db.useEmulator(host, port);
}
export function connectStorageEmulator(storage: firebase.storage.Storage, host: string, port: number) {
  storage.useEmulator(host, port);
}
export function connectAuthEmulator(auth: firebase.auth.Auth, url: string) {
  auth.useEmulator(url);
}

export const GoogleAuthProvider = firebase.auth.GoogleAuthProvider;

export const FieldValue = firebase.firestore.FieldValue;
export const Timestamp = firebase.firestore.Timestamp;

export type FieldValue = FieldValue_;
export type Timestamp = Timestamp_;
