import { storageBaseUri } from "../config/firebase";

export function isStorageUrl(url: string) {
  return url.startsWith(storageBaseUri);
}

const pathParamPrefix = '/?path=';

export function getStoragePath(url: string) {
  if (!isStorageUrl(url)) return;
  const i = url.lastIndexOf(pathParamPrefix) + pathParamPrefix.length;
  if (i < pathParamPrefix.length) return;
  return decodeURIComponent(url.substring(i));
}
