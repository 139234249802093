import dayjs from "dayjs";
import { Timestamp } from "@atgof-firebase/types";
import { PhraseKey, PhraseLookupFunction } from "./phrases";

export const UNKNOWN_LABEL = "??";

export function initials(name: string | null) {
  if (name) {
    const chs = name.split(/\s+/).map(s => s?.charAt(0));
    if (!chs.length) return '';
    return (chs[0] + (chs.length > 1 ? chs[chs.length - 1] : '')).toUpperCase();
  }
  return;
}

export function normaliseSubjectName(name: string | undefined) {
  // TODO Look for occurences of toLocaleUpperCase to refactor existing usages to this
  const s = name?.trim().toLocaleUpperCase();
  return s?.length ? s : undefined;
}

export function formatTime(d: Date | undefined) {
  return d?.toLocaleTimeString() || '';
}

export function formatTimecode(timecode: Timestamp | string | undefined) {
  return typeof timecode === 'string' ? timecode : formatTime(timecode?.toDate());
}

export function formatDate(d: Date | undefined, long: boolean = false) {
  return d ? dayjs(d).format((long ? 'ddd ' : '') + 'D MMM YYYY') : '';
}

export function formatTimestamp(t: Timestamp | undefined, includeTime: boolean = false) {
  const d = t?.toDate();
  return d ? formatDate(d) + (includeTime ? ' ' + formatTime(d) : '') : '';
}

const eighthsRepresentations = [
  '', '⅛', '¼', '⅜', '½', '⅝', '¾', '⅞'
];

export function tryParsePositiveInt(s: string | undefined) {
  if (!s?.match(/^\d+$/)) return;
  return parseInt(s);
}

export function scriptPagesDescription(pages: string | number) {
  const n = typeof pages === 'string' ? tryParsePositiveInt(pages) : pages;
  if (n === undefined) return;
  const fullPages = Math.floor(n / 8);
  const eighths = n % 8;
  return (fullPages ? fullPages.toString() : '') + eighthsRepresentations[eighths];
}

export function episodeNumberLabel(path: string) {
  const m = path.match(/[^/]+$/);
  return m ? m[0] : UNKNOWN_LABEL;
}

export function episodeLabel(ph: PhraseLookupFunction, path: string | undefined, short: boolean = false) {
  const episode = path ? episodeNumberLabel(path) : undefined;
  return ph(short ? 'ep' : 'episode') + (episode ? ' ' + episode : '');
}

export function countLabel(
  ph: PhraseLookupFunction, countPhraseKey: PhraseKey, count: number | undefined
) {
  if (count === undefined) return;
  const phraseOrFn = ph(countPhraseKey);
  const countPhrase = typeof phraseOrFn === 'function' ? phraseOrFn({ count: count }) : phraseOrFn;
  return (count == 0 ? ph("zero-count") : count) + " " + countPhrase;
}

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
