import {
  FlatList, Platform, Pressable, StyleProp, View, ViewStyle
} from "react-native";
import { Image } from "expo-image";
import * as ImagePicker from 'expo-image-picker';
import React from "react";
import { LanguageContext } from "../common/language";
import { DocumentReference, DocumentSnapshot } from "@atgof-firebase/firebase";
import { UserContext } from "../data/userContext";
import { useProject } from "../data/useProject";
import { storageManager, uploadFile } from "../data/backend";
import ImageView from "./ImageView";
import { Card, IconButton, ProgressBar, Text } from "react-native-paper";
import { imagesCollection, imagesQuery, SheetCategory } from "../common/sheet";
import { useSnapshot } from "../data/useSnapshot";
import { Editable } from "./Editable";
import FixtureLabel from "../common/FixtureLabel";
import { useUndoableDocumentDeleter } from "../data/undo";
import { UndoBanner } from "./UndoBanner";
import ErrorBoundary from "./ErrorBoundary";
import { prefetchImages, useImage } from "../data/image";

function Thumbnail(
  { doc, referencePath, index, showPhoto, size, readonly }:
    {
      doc: DocumentSnapshot;
      referencePath: string | undefined;
      index: number;
      showPhoto: (index: number) => void;
      size: number;
      readonly?: boolean;
    }
) {
  const {
    image, registerAsCached,
    registerCompletion, registerFailure
  } = useImage(doc, referencePath, true);
  if (!image) return null;
  const { ref, source, notes, blurhash, thumbnailSource } = image;
  const { width, height } = source;
  const aspect = width && height ? (width / height) : 1;
  return (
    <View style={{ gap: 4 }}>
      <View style={{
        alignItems: "center",
        backgroundColor: "#888888",
        padding: 10
      }}>
        <Pressable onPress={() => showPhoto(index)}>
          <ErrorBoundary>
            <Image
              source={{ ...source, ...thumbnailSource }}
              cachePolicy="disk"
              placeholder={{ blurhash }}
              style={{
                width: aspect * size,
                height: size
              }}
              onLoad={evt => {
                registerAsCached(evt.cacheType === 'disk');
                registerCompletion();
              }}
              onError={evt => {
                registerFailure(evt.error);
              }}
            />
          </ErrorBoundary>
        </Pressable>
      </View>
      {readonly ?
        (notes?.length ?
          <Text variant="bodySmall">{notes}</Text> : null) :
        <Editable
          text={notes ?? ""}
          onChange={v => ref.update({ notes: v })}
        />
      }
    </View>
  );
}

function PhotoStripButtons({ sheetRef, sheetCategory, kind }: {
  sheetRef: DocumentReference;
  sheetCategory: SheetCategory;
  kind: string | undefined
}) {
  const project = useProject();
  const { ph } = React.useContext(LanguageContext);
  const { user } = React.useContext(UserContext);
  const [cameraPermission, requestCameraPermission] =
    ImagePicker.useCameraPermissions();
  const [libraryPermission, requestLibraryPermission] =
    ImagePicker.useMediaLibraryPermissions();

  async function pickImage(fromCamera: boolean) {
    if (!project) return;
    // TODO Handle all errors
    const [launchPicker, requestPermission] =
      fromCamera ? [ImagePicker.launchCameraAsync, requestCameraPermission]
        : [ImagePicker.launchImageLibraryAsync, requestLibraryPermission];
    if (Platform.OS !== 'web') {
      let permission = fromCamera ? cameraPermission : libraryPermission;
      if (!permission?.granted && (!permission || permission.canAskAgain)) {
        permission = await requestPermission();
      }
      if (!permission?.granted) {
        alert(ph(
          fromCamera ?
            'camera-permission-required-error' :
            'library-permission-required-error'
        ));
        return;
      }
    }
    const { assets } = await launchPicker({
      mediaTypes: sheetCategory === 'pa' ?
        ImagePicker.MediaTypeOptions.All : ImagePicker.MediaTypeOptions.Images,
      allowsEditing: fromCamera,
      allowsMultipleSelection: !fromCamera,
      exif: true,
    });
    if (assets) {
      for (const { uri, width, height } of assets) {
        await uploadFile(
          project, sheetCategory, user,
          { docRef: sheetRef.collection("images").doc() },
          uri,
          {
            deleted: false, width, height,
            ...(kind ? { kind } : {})
          }
        );
      }
    }
  }
  return (
    <View style={{ flexDirection: "row", marginBottom: 8, gap: 8 }}>
      <IconButton icon="camera-plus" onPress={() => pickImage(true)} />
      <IconButton icon="image-plus" onPress={() => pickImage(false)} />
    </View>
  );
}

export default function PhotoStrip(
  { sheetRef, sheetCategory, referencePath, kind, style,
    readonly, horizontal = true }:
    {
      sheetRef: DocumentReference;
      sheetCategory: SheetCategory;
      referencePath: string | undefined;
      kind?: string;
      style: StyleProp<ViewStyle>;
      readonly?: boolean;
      horizontal?: boolean
    }
) {
  const { ph } = React.useContext(LanguageContext);
  const coll = imagesCollection(sheetRef);
  const images = useSnapshot<DocumentSnapshot[] | undefined>(
    onNext => imagesQuery(coll, kind).onSnapshot(snapshot =>
      onNext(snapshot.docs.filter(doc => doc.get('kind') === kind))),
    [coll.path]
  );
  React.useEffect(
    () => prefetchImages(referencePath, images),
    [referencePath, images]
  );
  const [selectedIndex, setSelectedIndex] = React.useState<number>();

  const { undo, undoableDelete } = useUndoableDocumentDeleter();

  function closeImageView() {
    setSelectedIndex(undefined);
  }
  function deleteImage(id: string) {
    undoableDelete(coll.doc(id));
  }

  return (
    <View style={style}>
      {images ?
        <ImageView
          referencePath={referencePath}
          images={images}
          imageIndex={selectedIndex ?? 0}
          setImageIndex={setSelectedIndex}
          visible={selectedIndex !== undefined}
          onRequestClose={closeImageView} deleteImage={deleteImage}
        />
        :
        null}
      {kind ? <FixtureLabel text={ph(kind) as string} /> : null}
      <View style={{ flexDirection: "row", flex: 1 }}>
        {readonly && images && !images.length ?
          null :
          <Card style={{ flex: 1 }}>
            <Card.Content>
              {images?.length ?
                <FlatList horizontal={horizontal}
                  data={images}
                  renderItem={({ item, index }) =>
                    <Thumbnail index={index}
                      referencePath={referencePath}
                      doc={item} showPhoto={setSelectedIndex}
                      size={readonly ? 140 : 200}
                      readonly={readonly} />
                  } />
                : (!images ? <ProgressBar indeterminate /> : null)
              }
            </Card.Content>
            <Card.Actions>
              {!readonly &&
                <PhotoStripButtons
                  sheetRef={sheetRef}
                  sheetCategory={sheetCategory}
                  kind={kind}
                />}
            </Card.Actions>
          </Card>
        }
      </View>
      <View style={{ flexDirection: "row", marginTop: 4 }}>
        <UndoBanner undo={undo} />
      </View>
    </View>
  );
}
