import { LanguageContext } from "@atgof-common/language";
import React from "react";
import { View } from "react-native";
import { Button, Surface, Text, useTheme } from "react-native-paper";

export function UndoBanner({ undo }: { undo?: () => void }) {
  const { ph } = React.useContext(LanguageContext);
  const { colors } = useTheme();
  if (!undo) return null;
  return (
    <Surface style={{ backgroundColor: colors.primaryContainer }}>
      <View style={{ flexDirection: "row", padding: 8, gap: 4, alignItems: "center" }}>
        <Text style={{ padding: 8 }}>{ph('deleted-an-item') as string}</Text>
        <Button mode="text" onPress={undo}>{ph('undo') as string}</Button>
      </View>
    </Surface>
  );
}
