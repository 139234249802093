import { overrideUris } from '../config';
import { appId } from './appId';
import { getAuth } from '@atgof-firebase/firebase';

export const appConfig = {
  apiKey: 'AIzaSyCozwk5OZWGqvBn9juQI1N5yMu0dynEVqU',
  authDomain: 'sheets.atgof.app',
  databaseURL: 'https://atgof-app.firebaseio.com',
  projectId: 'atgof-app',
  storageBucket: 'atgof-app.appspot.com',
  messagingSenderId: '224511278641',
  appId
}

export const functionsUri =
  overrideUris?.functions ??
  "https://europe-west1-atgof-app.cloudfunctions.net";

export const storageBaseUri = functionsUri + '/download';

export const uploadUri = functionsUri + '/upload';

export const storageCacheName = 'storage-cache';

export function isUploadUrl(url: string) {
  return url.startsWith(uploadUri);
}

export async function getStorageURL(storagePath: string) {
  const idToken = await getAuth().currentUser?.getIdToken();
  return storageBaseUri + "/?path=" +
    encodeURIComponent(storagePath) +
    "&token=" + encodeURIComponent(idToken ?? '');
}
