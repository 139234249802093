import { z } from 'zod';
import { LanguageContext } from "../../common/language";
import FixtureLabel from "../../common/FixtureLabel";
import LabelledAttr from "../../common/LabelledAttr";
import DetailPage from "./DetailPage";
import Select from "../../components/Select";
import React from "react";
import { useNavigation } from "@react-navigation/native";
import { Checkbox, Text, TextInput } from "react-native-paper";
import { View, ViewStyle } from "react-native";
import { DarkCompatibleColour, MEMBERSHIP_CATEGORIES, MembershipRoles } from "../../common/model/project/membership";
import { ColourControl } from "../ColourControl";
import { useProject, useSubjects } from "../../data/useProject";
import { ChipEditor } from "../ChipEditor";
import { normaliseSubjectName } from "../../common/model/subject";
import { membershipSchema } from "@atgof-common/generated/entities/project/membership";
import { DeleteItemFn } from './NavPage';
import { DocumentReference } from '@atgof-firebase/types';
import { Action } from '../../data/backend';

type RoleProps = {
  roles: MembershipRoles;
  onRolesChanged?: (roles: MembershipRoles) => void;
}

function Roles({ roles, onRolesChanged, style }: RoleProps & { style?: ViewStyle }) {
  const { ph } = React.useContext(LanguageContext);
  const keys = ['none', 'normal-user', 'admin-user'];
  const labels = keys.reduce((m, k) => ({ ...m, [k]: ph(k) as string }), {}) as { [k: string]: string };
  return (
    <View style={{ ...style, gap: 4 }}>
      <Text variant="headlineSmall">{ph('roles') as string}</Text>
      <View style={{ flexDirection: "row", flexWrap: "wrap" }} >
        {
          MEMBERSHIP_CATEGORIES.map(category => {
            const role = roles[category] || {};
            const selectedEntry = role.admin ?
              'admin-user' : (role.user ? 'normal-user' : 'none');
            return (
              <View key={category} style={{ margin: 8 }}>
                <FixtureLabel text={ph(category) as string} />
                {onRolesChanged ?
                  <Select entries={keys}
                    entryKey={key => key}
                    entryLabel={key => labels[key]}
                    selectedEntry={selectedEntry}
                    onEntryChange={key => onRolesChanged({
                      ...roles,
                      [category]: (
                        key === 'admin-user' ? { user: true, admin: true } :
                          (key === 'normal-user' ? { user: true } : {})
                      )
                    })}
                  /> :
                  <Text style={{ marginRight: 8 }}>{labels[selectedEntry]}</Text>
                }
              </View>
            );
          })
        }
      </View>
    </View>
  );
}

export type NotificationPrefs =
  z.infer<typeof membershipSchema.shape.notifications>

type MembershipDetailPageProps = {
  isNew?: boolean;
  isInvitation?: boolean;
  readOnly?: boolean;
  isLoading?: boolean;
  userName?: string;
  email: string;
  onEmailChanged?: (email: string) => void;
  inkColour: DarkCompatibleColour | undefined;
  onInkColourChanged: (colour: DarkCompatibleColour) => void;
  notificationPrefs: NotificationPrefs | undefined,
  onNotificationPrefsChanged: (prefs: NotificationPrefs) => void;
  onSubmit?: () => void;
  submitInProgress?: boolean;
  deleteAction?: DeleteItemFn | DocumentReference;
  latestAction?: Action
} & RoleProps

function CharactersSelector() {
  const { ph } = React.useContext(LanguageContext);
  const project = useProject();
  const characters = useSubjects(project, 'characters');
  return (
    <ChipEditor
      label={ph('characters') as string}
      newItemToAddPhrase={ph('character') as string}
      entries={[]}
      entryKey={entry => entry.displayName}
      entryLabel={entry => entry.displayName}
      labelFromTextInput={normaliseSubjectName}
      searchTokens={displayName => ([displayName])}
      candidateList={characters ?? []}
      onChange={cast => console.log(cast)}
      min={1}
      showMenuWhenSearchEmpty={false}
    />
  );
}

export default function MembershipDetailPage({
  isNew, isInvitation, readOnly, isLoading,
  userName, email, onEmailChanged, inkColour, onInkColourChanged,
  notificationPrefs, onNotificationPrefsChanged,
  onSubmit, submitInProgress, deleteAction, roles, latestAction,
  ...props
}: MembershipDetailPageProps) {
  const { ph } = React.useContext(LanguageContext);
  const { goBack } = useNavigation();
  const notifyChangeOfScene = notificationPrefs?.changeToScene ?? [];
  return (
    <DetailPage
      title={ph((isNew ? 'new-' : '') + (isInvitation ? 'invitation' : 'member')) as string}
      isLoading={isLoading}
      deleteItem={deleteAction}
      {...(isNew ? { onCancel: goBack, onDone: onSubmit, isDoing: submitInProgress } : {})}
      latestAction={latestAction}
    >
      <View style={{ margin: 16, gap: 8 }}>
        {isInvitation || !userName ? null :
          <LabelledAttr label={ph('name') as string}>{userName}</LabelledAttr>}
        {
          onEmailChanged ?
            <TextInput
              label={ph('email') as string} value={email} onChangeText={onEmailChanged}
            /> :
            <LabelledAttr label={ph('email') as string}>
              {email}
            </LabelledAttr>
        }
        {roles ? <Roles style={{ marginTop: 8 }} roles={roles} {...props} /> : null}
        <View style={{ flexDirection: "row" }}>
          <Checkbox.Item
            label={ph('notify-email-for-scene-changes') as string}
            status={
              notifyChangeOfScene.includes('email') ? 'checked' : 'unchecked'
            }
            onPress={() => onNotificationPrefsChanged({
              ...notificationPrefs,
              changeToScene:
                notifyChangeOfScene.includes('email') ?
                  notifyChangeOfScene.filter(x => x !== 'email') :
                  [...notifyChangeOfScene, 'email']
            })}
          />
        </View>
        {roles && (roles.cast?.admin || roles.cast?.user) ?
          <CharactersSelector /> : null}
        <View style={{ gap: 4 }}>
          <Text variant="headlineSmall">{ph('ink-colours') as string}</Text>
          <ColourControl
            value={inkColour ?? { dark: '#ffffff', light: '#000000' }}
            onChange={onInkColourChanged}
          />
        </View>
      </View>
    </DetailPage>
  );
}
