import { User, getAuth } from "@atgof-firebase/firebase";

export async function authHeadersForUser(
  user: User | null,
  forceRefresh?: boolean
)
  : Promise<Record<string, string>> {
  const idToken = await user?.getIdToken(forceRefresh);
  return idToken ?
    { "Authorization": "Bearer " + idToken } : {};
}

export async function authHeadersForCurrentUser() {
  return authHeadersForUser(getAuth().currentUser);
}
