import { z } from 'zod';

export type Entity<T> = T & {
  id: string;
  _path: string[];
  _ref: any,
  _exists: boolean
}

type RawData = { [field: string]: any };

export abstract class Spec<T> {
  private readonly _cachedValidator: z.ZodSchema<any> = this.validator();

  abstract validator(): z.ZodSchema<any>

  fromData(path: string[], data: RawData | undefined, ref: any): Entity<T> {
    const id = path[path.length - 1];
    return {
      ...this._cachedValidator.parse(data),
      id,
      _path: path,
      _ref: ref,
      _exists: data !== undefined
    };
  }
}
