import { EpisodePath } from "../common/episode";
import { SheetCategory } from "../common/sheet";
import { DocumentReference, DocumentSnapshot } from "@atgof-firebase/types";
import { useSnapshot } from "./useSnapshot";
import _ from "lodash";
import { onAvailableLooks } from "../common/look";

export function useAvailableLooks(
  lookSubjectRef: DocumentReference | undefined,
  episodePaths: EpisodePath[],
  categories: SheetCategory[],
) {
  return useSnapshot<DocumentSnapshot[] | undefined>(
    _.curry(onAvailableLooks)(
      lookSubjectRef,
      episodePaths,
      categories
    ),
    [
      lookSubjectRef?.path,
      episodePaths.map(({ project, episode, season }) =>
        [project, episode, season].join('/')
      ).sort().join('\n'),
      categories.sort().join('\n')
    ]
  );
}
