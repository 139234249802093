import React from "react";
import { View } from "react-native";
import { PagerViewProps as RNPagerViewProps } from "react-native-pager-view";
import { useSwipeable } from "react-swipeable";

export type PagerView = { setPage: (page: number) => void }

type PagerViewProps = RNPagerViewProps & {
  onPageSelected: (event: { nativeEvent: { position: number } }) => void;
  onDismiss: () => void;
  children: React.ReactNode[]
}

export const PagerView = React.forwardRef<PagerView, PagerViewProps>(
  (
    {
      initialPage,
      style,
      onPageSelected,
      onDismiss,
      scrollEnabled,
      children
    },
    ref
  ) => {
    const [page, setPage] = React.useState<number>(initialPage ?? 0);
    React.useEffect(() => setPage(initialPage ?? 0), [initialPage]);
    React.useImperativeHandle(ref, () => ({ setPage }));
    const handlers = useSwipeable({
      onSwipedUp: onDismiss,
      onSwipedRight: () => {
        const page_ = page ? page - 1 : 0
        setPage(page_);
        onPageSelected({ nativeEvent: { position: page_ } });
      },
      onSwipedLeft: () => {
        const page_ = page !== undefined && page < children.length - 1 ?
          page + 1 : (children.length ? children.length - 1 : 0)
        setPage(page_);
        onPageSelected({ nativeEvent: { position: page_ } });
      }
    });

    return (
      <div style={{
        flex: 1,
      }}
        {...(scrollEnabled ? handlers : {})}
      >
        <View style={style}>
          {page < children.length ? children[page] : null}
        </View>
      </div>
    );
  }
);
