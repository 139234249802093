import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { DocumentReference, DocumentSnapshot, Query } from "@atgof-firebase/firebase";
import React from "react";
import { LanguageContext } from "../common/language";
import NavPage, { useEditor } from "../components/pages/NavPage";
import { RootStackParamList } from "../types";
import { View } from "react-native";
import { PostProductionInfo } from "../components/Takes";
import { sceneLabel, sortScenes } from "../common/scene";
import { useStoredPref } from "../data/useStoredPref";
import { IconButton, Menu, Text } from "react-native-paper";
import ScriptView from "../components/script/ScriptView";
import { ProjectContext } from "../data/projectContext";
import { ScriptExportButton } from "../components/ScriptExportButton";
import { useSnapshot } from "../data/useSnapshot";
import { onScenesInContinuousFilmingSequence } from "../common/scene";
import { EditingMode, } from "../components/script/ScriptAnnotationLayers";
import { AnnotationLayerKey } from "../common/script";
import { useDocument } from "../data/useDocument";
import { useDocumentReference } from "../data/firestore";
import { UserContext } from "../data/userContext";
import { getDocumentReference } from "../common/firestore";

function TramlinesMenu(
  { showTramlines, setShowTramlines }:
    { showTramlines: boolean | null; setShowTramlines: (show: boolean) => void }
) {
  const { ph } = React.useContext(LanguageContext);
  const [menuVisible, setMenuVisible] = React.useState(false);
  function openMenu() { setMenuVisible(true); }
  function closeMenu() { setMenuVisible(false); }
  return (
    <Menu
      visible={menuVisible} anchorPosition="bottom"
      onDismiss={closeMenu}
      anchor={<IconButton icon="layers" onPress={openMenu} />}
    >
      <View>
        <Text variant="titleSmall" style={{ marginLeft: 8 }}>{ph('layers') as string}</Text>
        <Menu.Item
          title={ph('tramlines') as string}
          onPress={() => { setShowTramlines(!showTramlines); closeMenu() }}
          leadingIcon={showTramlines ? "checkbox-marked-outline" : "checkbox-blank-outline"}
        />
      </View>
    </Menu>
  );
}

export default function ScriptScreen(
  { route }: NativeStackScreenProps<RootStackParamList, 'script'>
) {
  const { scenePath, sheetPath, episodePath, currentTakePath, subjectKind, subjectName } =
    route.params;
  const subject = subjectKind && subjectName ? { subjectKind, subjectName } : undefined;
  const { user } = React.useContext(UserContext);
  const { categories } = React.useContext(ProjectContext);
  const isPostProduction = categories.includes('postProduction');
  const isPA = categories.includes('pa');
  const isAdmin = categories.includes('admin');
  const [showTramlines, setShowTramlines] =
    useStoredPref<boolean>("ScriptScreen.showTramlines", isPostProduction);
  const sheet = useDocument(useDocumentReference(sheetPath));
  const [scenes, setScenes] = React.useState<DocumentSnapshot[]>();
  const [currentSceneId, setCurrentSceneId] = React.useState<string>();
  const sceneRef = React.useMemo(
    () =>
      sheet?.get('scene') ?? getDocumentReference(user, scenePath) ??
      scenes?.find(({ id }) => id === currentSceneId)?.ref,
    [sheet, scenePath, user, scenes, currentSceneId]
  );
  const [prevScene, setPrevScene] = React.useState<DocumentSnapshot>();
  const [nextScene, setNextScene] = React.useState<DocumentSnapshot>();
  React.useEffect(
    () => {
      const i = scenes?.findIndex(({ id }) => id === currentSceneId);
      setPrevScene(
        scenes?.length && i !== undefined && i > 0 ? scenes[i - 1] : undefined
      );
      setNextScene(
        scenes?.length && i !== undefined && i + 1 < scenes.length ?
          scenes[i + 1] : undefined
      );
    },
    [currentSceneId]
  );
  React.useEffect(
    () => {
      const episodeRef = getDocumentReference(user, episodePath);
      if (episodePath) {
        let q: Query | undefined = episodeRef?.collection('scenes');
        if (subjectKind && subjectName) {
          q = q?.where(subjectKind === 'character' ? 'cast' : 'sets', 'array-contains', subjectName);
        }
        return q?.onSnapshot(snapshot => {
          const sortedScenes = sortScenes(snapshot.docs);
          setScenes(sortedScenes);
          setCurrentSceneId(
            id => id || (sortedScenes.length ? sortedScenes[0].id : undefined)
          );
        });
      }
      setScenes(undefined);
      setCurrentSceneId(undefined);
    },
    [episodePath, user, subjectKind, subjectName]);
  const take = useDocument(useDocumentReference(currentTakePath));
  const hasTake = currentTakePath !== undefined;
  const slate = sheet?.get('pa:slate');
  const title = React.useMemo(() => {
    const currentTakeIndex = take?.get('index');
    return sceneRef && (
      sceneLabel(sceneRef.path) +
      (
        slate ? ` - ${slate}` +
          (currentTakeIndex !== undefined ? `/${currentTakeIndex + 1}` : '') : ''
      )
    );
  }, [take, sceneRef, slate]);
  const sceneRefs = useSnapshot<DocumentReference[] | undefined>(
    onNext => onScenesInContinuousFilmingSequence(sceneRef, onNext),
    [sceneRef]
  );
  const afterScript = React.useMemo(
    () => isPostProduction ? <PostProductionInfo scene={sceneRef} /> : undefined,
    [isPostProduction, sceneRef]
  );
  const editableAnnotationLayers = React.useMemo<AnnotationLayerKey[]>(
    () => isPA ? ['user', 'pa', 'public'] : ['user'],
    [isPA]
  );
  const viewableAnnotationLayers = React.useMemo<AnnotationLayerKey[]>(
    () => (
      editableAnnotationLayers.includes('public') ?
        editableAnnotationLayers : [...editableAnnotationLayers, 'public']
    ),
    [editableAnnotationLayers]
  );
  const defaultEditingMode = React.useMemo<EditingMode>(
    () => ({
      pencil: 'user',
      available: editableAnnotationLayers
    }),
    [editableAnnotationLayers]
  );
  const { editor, editingMode, setEditingMode } =
    useEditor<EditingMode>(defaultEditingMode, "done");
  return (
    <NavPage
      newSheetContext={hasTake ? undefined : { sceneRef }}
      showNewSheetButtonRegardless={false}
      fullScreen noMargin title={title}
      editor={editor}
      headerRight={
        (isPA || isPostProduction) && !currentTakePath ?
          <View style={{ flexDirection: "row" }}>
            <TramlinesMenu showTramlines={showTramlines} setShowTramlines={setShowTramlines} />
            <ScriptExportButton sceneRef={sceneRef} showTramlines={showTramlines ?? false} />
          </View> :
          undefined
      }
    >
      <ScriptView
        sceneRefs={sceneRefs}
        canModifyScripts={isPA || isAdmin}
        subject={subject} hasTake={hasTake} take={take} sheet={sheet}
        viewableAnnotationLayers={viewableAnnotationLayers}
        editableAnnotationLayers={editableAnnotationLayers}
        editingMode={editingMode}
        setEditingMode={setEditingMode}
        afterScript={afterScript}
        showTramlines={showTramlines || take !== undefined}
        prevScene={prevScene} nextScene={nextScene}
        moveToScene={setCurrentSceneId}
      />
    </NavPage>
  );
}
