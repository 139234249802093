import React from "react";
import { LanguageContext } from "../common/language";
import { formatDate, normaliseSubjectName } from "../common/util";
import { View, ViewStyle } from "react-native";
import { DocumentReference, DocumentSnapshot, FieldValue } from "@atgof-firebase/firebase";
import { useProject, useSubjects } from "../data/useProject";
import { TeamSelector } from "./TeamSelector";
import { Icon, Text, useTheme } from "react-native-paper";
import { SceneData, Scene } from "../common/scene";
import { AttrStack } from "../common/AttrStack";
import { SceneLocation, SceneStatusView } from "../common/SceneInfo";
import { ScriptScene } from "../common/file-import";
import { SceneLocationEditor } from "./SceneLocationEditor";
import { ChipEditor } from "./ChipEditor";
import { Character } from "../common/model/project/character";
import { Set_ } from "../common/model/project/set";
import { episodeForScene } from "../common/episode";
import { SubjectButton } from "./SubjectButton";

export type PreexistingSubjects = {
  characters: Character[] | undefined;
  sets: Set_[] | undefined
} | undefined

export function usePreexistingSubjects() {
  const project = useProject();
  const characters = useSubjects(project, 'characters');
  const sets = useSubjects(project, 'sets');
  return { characters, sets };
}

function CastList({
  cast,
  preexisting,
  episodePath
}: {
  cast: { displayName: string }[] | undefined;
  preexisting: { displayName: string }[] | undefined;
  episodePath: string | undefined
}) {
  const { colors } = useTheme();
  if (!cast) return null;
  return (
    <View style={{ marginBottom: 2, marginTop: 2, flexDirection: 'row' }}>
      {cast?.map((character, i) =>
        <View key={i} style={{ flexDirection: 'row', alignItems: 'center' }}>
          {preexisting && !preexisting.some(entry => entry.displayName === character.displayName) ?
            <Icon source="circle-medium" size={16} color={colors.error} /> : null}
          <SubjectButton
            subjectKind="character"
            subjectName={character.displayName}
            episodePath={episodePath}
            fallback={<Text style={{ fontSize: 14 }}>
              {character.displayName + (i < cast.length - 1 ? ', ' : '')}
            </Text>}
          />
        </View>)}
    </View>
  );

}

export default function SceneInfo<T extends SceneData | Scene | ScriptScene>(
  {
    scene, style, sceneRefToEdit, handleTeamChange, updateScene, preexistingSubjects,
    excludeSynopsis, excludeFilmingDate
  }:
    {
      scene: T;
      style?: ViewStyle;
      sceneRefToEdit?: DocumentReference;
      handleTeamChange?: (team: DocumentReference | undefined) => void;
      updateScene?: (data: Partial<T>) => Promise<void>;
      preexistingSubjects?: PreexistingSubjects;
      excludeSynopsis?: boolean;
      excludeFilmingDate?: boolean
    }
) {
  const { colors } = useTheme();
  const { ph } = React.useContext(LanguageContext);
  const project = useProject();
  const [team, setTeam] = React.useState<DocumentSnapshot>();
  const teamRef = 'teamRef' in scene ? scene.teamRef : undefined;
  React.useEffect(() => {
    if (sceneRefToEdit && project) {
      if (teamRef) return teamRef.onSnapshot(setTeam); // TODO Probably unnecessary given that scene.teamData may be set; check all callers of SceneInfo
      setTeam(undefined);
    }
  }, [project?.id, sceneRefToEdit?.path, teamRef?.path]);
  const onTeamChange = React.useCallback((newTeam: DocumentSnapshot | undefined) => {
    if (sceneRefToEdit && newTeam) {
      sceneRefToEdit.update({ team: newTeam?.ref || FieldValue.delete() }).then(() => {
        if (handleTeamChange) handleTeamChange(newTeam?.ref);
      });
    }
  }, [sceneRefToEdit?.path, handleTeamChange]);
  const castEntries = scene.cast?.map(displayName => ({ displayName })) ?? [];
  return (
    <View style={{ gap: updateScene ? 4 : 0, ...style }}>
      <SceneStatusView scene={scene} color={colors.onBackground} />
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {updateScene ?
          <SceneLocationEditor
            scene={scene} updateScene={updateScene}
            setsOptions={preexistingSubjects?.sets ?? []}
          /> :
          <View style={{ flexDirection: 'row', alignItems: 'center', gap: 4 }}>
            <SceneLocation
              scene={scene}
              fontSize={16}
              preexistingSets={preexistingSubjects?.sets}
            />
          </View>
        }
      </View>
      {updateScene ?
        <ChipEditor
          label={ph('cast') as string}
          newItemToAddPhrase={ph('character') as string}
          entries={castEntries}
          entryKey={entry => entry.displayName}
          entryLabel={entry => entry.displayName}
          labelFromTextInput={normaliseSubjectName}
          searchTokens={entry => ([entry.displayName])}
          candidateList={preexistingSubjects?.characters ?? []}
          onChange={
            entities => updateScene({ cast: entities.map(({ displayName }) => displayName) } as any) /* TODO Type? */}
        /> :
        <View style={{ flexDirection: 'row', alignItems: 'center', gap: 4 }}>
          <CastList
            cast={castEntries}
            preexisting={preexistingSubjects?.characters}
            episodePath={'ref' in scene ?
              episodeForScene(scene.ref)?.path :
              undefined}
          />
        </View>
      }
      {!excludeSynopsis && 'synopsis' in scene && <Text>{scene.synopsis}</Text>}
      {
        !excludeFilmingDate && 'filmingDate' in scene &&
        <AttrStack small hideEmpties attrs={[
          {
            label: ph('filming-date') as string,
            value: formatDate(scene.filmingDate, true)
          }
        ]}
        />
      }
      <AttrStack small hideEmpties attrs={[
        //{ label: ph('pages') as string, value: scene.pages }
      ]} />
      <AttrStack small hideEmpties attrs={[
        {
          label: ph('extras') as string,
          value: (('extras' in scene ? scene.extras : undefined) || []).join(', ')
        }
      ]} />
      {
        sceneRefToEdit &&
        <View style={{ flexDirection: 'row' }}>
          <TeamSelector
            selectedTeam={team} onTeamChange={onTeamChange}
          />
        </View>
      }
    </View >
  );
}
