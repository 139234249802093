import { ProgressBar } from "react-native-paper";
import React from "react";
import { DocumentReference, DocumentSnapshot } from "@atgof-firebase/types";
import { episodeForScene, getEpisodePath } from "../common/episode";
import { episodePathForLook, subjectRefForLook } from "../common/look";
import { isCharacterCategory, SheetCategory } from "../common/sheet";
import { useAvailableLooks } from "../data/useAvailableLooks";
import { ChipSelector } from "./ChipSelector";
import { useProject } from "../data/useProject";
import { View } from "react-native";
import { LanguageContext } from "../common/language";
import FixtureLabel from "../common/FixtureLabel";
import { LookChip } from "./LookChip";
import { subjectRef } from "@atgof-common/subject";

export function LooksSelector({ doc, readonly, docIsLook }: {
  doc: DocumentSnapshot
  readonly?: boolean;
  docIsLook?: boolean
}) {
  const { ph } = React.useContext(LanguageContext);
  const project = useProject();
  const category: SheetCategory | undefined = doc.get('category');
  const subjectKind = isCharacterCategory(category) ? 'character' : 'set';
  const subjectName: string | undefined = doc.get(isCharacterCategory(category) ? 'character' : 'set');
  const lookSubjectRef: DocumentReference | undefined =
    (
      subjectName ?
        subjectRef(project, { subjectKind, subjectName }) :
        undefined
    ) ?? (docIsLook ? subjectRefForLook(doc.ref) : undefined) ?? undefined;
  const sceneRef: DocumentReference | undefined = doc.get('scene');
  const episodePath = sceneRef ? getEpisodePath(episodeForScene(sceneRef)) : episodePathForLook(doc.ref);
  const looks_ = useAvailableLooks(
    lookSubjectRef,
    episodePath ? [episodePath] : [],
    category ? [category] : []
  );
  const looks = looks_?.filter(look => look.ref.path !== doc.ref.path);
  const selectedLooks: DocumentReference[] = doc.get('looks') ?? [];
  if (!looks) return <ProgressBar indeterminate />;
  return (
    <View style={{ gap: 4 }}>
      {!readonly && looks.length ?
        <FixtureLabel style={{ fontSize: 16 }} text={ph(sceneRef ? 'looks' : 'based-on') as string} /> :
        null}
      {readonly ?
        <View style={{ gap: 4, flexDirection: "row", flexWrap: "wrap" }}>
          {looks
            .filter(look => selectedLooks.find(ref => ref.path === look.ref.path))
            .map(look => <LookChip key={look.id} look={look} />)}
        </View> :
        <ChipSelector
          entries={looks.map(look => ({
            id: look.ref.path,
            label: look.get('displayName')
          }))}
          selectedEntryIds={selectedLooks.map(ref => ref.path)}
          onSelectionChanged={selectedIds => doc.ref.update({
            looks: looks.map(({ ref }) => ref).filter(ref => selectedIds.includes(ref.path))
          })}
        />}
    </View>
  );
}
