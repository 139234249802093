import { LanguageContext } from "@atgof-common/language";
import { listOfItems, PhraseFunction } from "@atgof-common/phrases";
import { MaterialIcons } from "@expo/vector-icons";
import React from "react";
import { View, TextStyle } from "react-native";
import { useTheme, Text, Button, Banner } from "react-native-paper";
import { FILE_TYPES, fileType, FileType } from "../data/imports";

const width = 320;
const height = 200;

function TextPrompt({ children, style }: { children: React.ReactNode; style?: TextStyle }) {
  return (
    <Text
      variant="titleMedium"
      style={{ alignSelf: "center", textAlign: "center", ...(style ?? {}) }}
    >
      {children}
    </Text>
  );
}

export function FileDropArea({ accept, onFiles }: {
  accept: FileType[];
  onFiles: (files: File[]) => void
}) {
  const { colors } = useTheme();
  const { ph } = React.useContext(LanguageContext);
  const fileInputElement = React.useRef<HTMLInputElement>(null);
  const [fileTypeError, setFileTypeError] = React.useState(false);
  function filesHandler(files: File[]) {
    const acceptedFiles = files.filter(({ name }) => {
      const tp = fileType(name);
      return tp && accept.includes(tp);
    });
    setFileTypeError(acceptedFiles.length !== files.length);
    onFiles(acceptedFiles);
  }
  function dragOverHandler(event: React.DragEvent) { event.preventDefault(); }
  function dropHandler(event: React.DragEvent) {
    event.preventDefault();
    const { dataTransfer } = event;
    let files: File[] = [];
    function addFile(file: File | null) {
      if (!file) return;
      const existingIndex = files.findIndex(({ name, size, lastModified }) =>
        file.name === name &&
        file.size == size &&
        file.lastModified == lastModified
      );
      if (existingIndex !== -1) files.splice(existingIndex, 1);
      files.push(file);
    }
    for (const item of dataTransfer.items) {
      if (item.kind === 'file') addFile(item.getAsFile());
    }
    for (const file of dataTransfer.files) addFile(file);
    filesHandler(files);
  }
  function fileSelectionHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;
    if (files) filesHandler([...files]);
  }
  const fileTypeList = listOfItems(
    ph('or') as string,
    accept.map(fileType =>
      FILE_TYPES[fileType].label + ' (.' + fileType + ')'
    )
  );
  return (
    <View style={{ width, gap: 8 }}>
      <Banner visible={fileTypeError} icon="alert-circle-outline">
        {(ph('only-file-types-error') as PhraseFunction)({ fileTypeList }) as string}
      </Banner>
      <div
        onDragOver={dragOverHandler}
        onDrop={dropHandler}
        style={{ width, height }}
      >
        <View style={{
          borderWidth: 4, borderColor: colors.outline, borderStyle: "dashed",
          borderRadius: 6, gap: 8, paddingTop: 8, paddingBottom: 8, height
        }}
        >
          <TextPrompt style={{ maxWidth: width }}>
            {(ph('drag-prompt') as PhraseFunction)({ fileTypeList }) as string}
          </TextPrompt>
          <MaterialIcons name="arrow-circle-down" size={48} color={colors.outline}
            style={{ flex: 1, alignSelf: "center" }}
          />
          <TextPrompt>{ph('or') as string}</TextPrompt>
          <View style={{ alignSelf: "center" }}>
            <Button onPress={() => fileInputElement.current?.click()}>
              {ph('choose-files') as string}
            </Button>
          </View>
        </View>
        <input ref={fileInputElement} style={{ opacity: 0 }}
          type="file" multiple accept={accept.map(ext => '.' + ext).join(', ')}
          onChange={fileSelectionHandler}
        />
      </div>
    </View>
  );
}
