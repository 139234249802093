import React from "react";
import { SubjectSpec } from "./subject";
import { ScriptElement, ScriptTextElement } from "./script";

const textStyles: { [attr: string]: object } = {
  'Bold': { fontFamily: 'Roboto-Bold' },
  'Italic': { fontFamily: 'Roboto-Italic' },
  'Underline': { textDecorationLine: 'underline', textDecoration: 'underline' },
};

type TextAttrs = { isAllCaps?: boolean; style?: object }

function textAttrs(style: string | number | undefined): TextAttrs {
  if (typeof style === 'string') {
    return style.split('+').reduce((attrs, attr) => (
      { ...attrs, ...(attr === 'AllCaps' ? { isAllCaps: true } : { style: { ...attrs.style, ...textStyles[attr] } }) }
    ), {} as TextAttrs);
  }
  return {};
}

function paragraphAttrs(type: string | number | undefined, unit: (x: number) => number) {
  switch (type) {
    case 'General': return { text: 'AllCaps' };
    case 'Transition': return { text: 'AllCaps' };
    case 'Action': return { props: { marginHorizontal: unit(20) }, text: 'AllCaps' };
    case 'Penawd': return { props: { marginTop: unit(40) }, text: 'Bold+AllCaps+Underline' };
    case 'Scene Heading': return { text: 'Bold+AllCaps+Underline' };
    case 'Character': return { props: { marginBottom: unit(0) }, text: 'Bold+AllCaps+Underline' };
    case 'Dialogue': return {};
    case 'Parenthetical': return { props: { marginBottom: unit(0), marginHorizontal: unit(20) }, text: 'AllCaps' };
    case 'Cast List': return { text: 'Italic' };
    case 'Time': return { text: 'Bold+AllCaps' };
    default:
      console.warn("Unknown paragraph type", type);
      return {};
  }
}

type TextComponentClass = React.ComponentClass<any>; // TODO More specific type here
type ViewComponentClass = React.ComponentClass<any>; // TODO More specific type here

function ScriptTextSection({
  text, color, style,
  Text
}: {
  text: string;
  color: string | undefined;
  style: object | undefined;
  Text: TextComponentClass;
}
) {
  return (
    <Text style={{ fontFamily: "Roboto-Regular", ...(style ?? {}), backgroundColor: color }}>
      {text}
    </Text>
  );
}

function ScriptText({ element, paragraphTextAttrs, highlight, textAnnotations, Text }:
  {
    element: ScriptTextElement;
    paragraphTextAttrs: TextAttrs;
    highlight: string | undefined;
    textAnnotations: ScriptTextAnnotation[] | undefined;
    Text: TextComponentClass;
  }
) {
  const allCaps = element.text?.toLocaleUpperCase();
  const { isAllCaps, style } = {
    ...paragraphTextAttrs, ...textAttrs(element.style)
  };
  const text = isAllCaps ? allCaps : element.text;
  let parts: ({ text: string; color: string | undefined })[] = [
    { text, color: undefined }
  ];
  function incorporate({ start, length }: ScriptTextRange, color: string) {
    let offset = 0;
    for (let partI = 0; partI < parts.length; partI++) {
      const part = parts[partI];
      const len = part.text.length;
      const s = start - offset;
      if (!part.color && s >= 0 && s < len && length <= len) {
        parts = [
          ...parts.slice(0, partI),
          ...(s == 0 ? [] : [{ ...part, text: part.text.substring(0, s) }]),
          { ...part, text: part.text.substring(s, s + length), color },
          ...(length == len ? [] : [{ ...part, text: part.text.substring(s + length) }]),
          ...parts.slice(partI + 1)
        ];
        break;
      }
      offset += len;
    }
  }
  for (const { range, color } of (textAnnotations ?? [])) incorporate(range, color);
  const i = highlight && allCaps ? allCaps.indexOf(highlight) : -1;
  if (i !== -1) incorporate({ start: i, length: highlight!.length }, '#ffff00');
  return (
    <React.Fragment>
      {parts.map((part, i) => <ScriptTextSection key={i} style={style} Text={Text} {...part} />)}
    </React.Fragment>
  );
}

type ScriptTextRange = {
  start: number;
  length: number;
}

export type ScriptTextAnnotation = {
  color: string;
  paragraph: number;
  text: string;
  element: number;
  range: ScriptTextRange;
}

export default function ScriptParagraph(
  {
    content, web, subject, textAnnotations,
    Text, View,
    maxContentWidth = 600,
    lineHeight = web ? 31 : 28,
    lineNumberMarginTop = 3,
    unit = x => x,
    backgroundColor = "#ffffff"
  }: {
    content: ScriptElement;
    web: boolean;
    subject?: SubjectSpec | undefined;
    textAnnotations?: ScriptTextAnnotation[] | undefined;
    Text: TextComponentClass;
    View: ViewComponentClass;
    maxContentWidth?: number,
    lineHeight?: number,
    lineNumberMarginTop?: number,
    unit?: (x: number) => number,
    backgroundColor?: string
  }
) {
  const { kind, paragraph, dialogueLineNumber, dualCharacter } = content;
  const { props, text } = paragraphAttrs(kind, unit);
  const highlight = subject?.subjectKind === 'character' &&
    (kind === 'Character' || kind === 'Cast List') ? subject.subjectName.toLocaleUpperCase() : undefined;
  return (
    <View style={{
      display: "flex", backgroundColor,
    }}>
      <View style={{ flexDirection: "row" }}>
        <View style={{ display: "flex", width: unit(72), marginLeft: unit(32) }} />
        <View style={{ width: unit(32), justifyContent: "flex-start" }}>
          {dialogueLineNumber ?
            <Text style={{
              fontFamily: "Roboto-Regular",
              alignSelf: "flex-end", fontSize: unit(16),
              marginTop: unit(lineNumberMarginTop),
              marginRight: unit(4),
              color: "#777777"
            }}>
              {dialogueLineNumber + '.'}
            </Text> :
            null}
        </View>
        <View style={{
          marginBottom: unit(16),
          width: unit(maxContentWidth),
          flexDirection: "row",
          justifyContent: dualCharacter ? "flex-end" : "flex-start",
          ...(props as any)
        }}>
          <Text style={{
            fontFamily: "Roboto-Regular", fontSize: unit(16),
            lineHeight,
            ...(web ? { marginRight: unit(69) } : {}) // TODO Why is this marginRight needed?
          }}>
            {paragraph?.map((textElement, i) =>
              <ScriptText
                key={i} element={textElement}
                paragraphTextAttrs={textAttrs(text)}
                highlight={highlight}
                textAnnotations={textAnnotations?.filter(({ element }) => element == i)}
                Text={Text}
              />)}
          </Text>
        </View>
      </View>
    </View>
  );
}
