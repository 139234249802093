import { CollectionReference, DocumentReference, DocumentSnapshot } from "@atgof-firebase/types";
import { SheetCategory } from "./sheet";
import { OnNext, onSnapshots } from "./onSnapshot";
import { EpisodePath, perEpisodeRef } from "./episode";
import { notDeleted } from "./general";
import { ReferenceData } from "../common/image";
import { subjectCollection } from "./subject";

export function episodePathForLook(lookRef: DocumentReference | undefined): EpisodePath | undefined {
  const lookEpRef = lookRef?.parent.parent
  if (lookEpRef?.parent.id === 'episodes') {
    const episodeId = lookEpRef.id;
    const lookSeasonRef = lookEpRef.parent.parent;
    const seasonId = lookSeasonRef?.id;
    const projectRef = lookSeasonRef?.parent.parent?.parent.parent;
    if (projectRef && seasonId && episodeId) {
      return {
        project: projectRef.id,
        season: seasonId,
        episode: episodeId
      };
    }
  }
  return;
}

export function subjectRefForLook(lookRef: DocumentReference | undefined) {
  const lookEpRef = lookRef?.parent.parent
  if (lookEpRef?.parent.id === 'episodes') {
    return lookEpRef.parent.parent?.parent.parent;
  }
  return lookRef?.parent.parent;
}

export function onAvailableLooks(
  lookSubjectRef: DocumentReference | undefined,
  episodePaths: EpisodePath[],
  categories: SheetCategory[],
  onNext: OnNext<DocumentSnapshot[] | undefined>
) {
  if (!(lookSubjectRef && categories.length)) {
    onNext([])
    return;
  }
  return onSnapshots<CollectionReference, DocumentSnapshot[]>(
    [
      lookSubjectRef.collection('looks'),
      ...(episodePaths.filter(({ season, episode }) => season && episode)
        .map(episodePath =>
          lookSubjectRef.collection('seasons').doc(episodePath.season!)
            .collection('episodes').doc(episodePath.episode!).collection('looks')))
    ],
    looksRef =>
      onNext => notDeleted(looksRef).where('category', 'in', categories)
        .orderBy('order', 'asc')
        .onSnapshot(({ docs }) => onNext(docs)),
    collections => onNext(collections?.flat())
  )
}

export function lookReference(
  project: DocumentReference | undefined,
  referenceData: ReferenceData
) {
  const {
    lookId, subjectKind, subjectId,
    season, episode
  } = referenceData;
  if (!(lookId && subjectId)) return;
  const subject = subjectCollection(project, subjectKind)?.doc(subjectId);
  if (!subject) return;
  const parentRef = episode ?
    perEpisodeRef(subject, { season, episode }) : subject;
  return parentRef?.collection('looks').doc(lookId);
}
